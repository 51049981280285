import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Modal,
	Form,
	Select,
	InputNumber,
	message
} from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { Option } = Select;

const TableCaixaBank = ({ mortgageId, showModalEmail, mortgage, type }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const idBank = location && location.state !== undefined ? location.state.bankId : undefined;
	const bankRequestId = location && location.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest = location && location.state !== undefined ? location.state.bankRequest : undefined;
	const ageT1 = moment().diff(mortgage['owner1'].birthdate, 'years');
	const ageT2 = mortgage['owner2'] !== undefined ? moment().diff(mortgage['owner2'].birthdate, 'years') : 0;
	const ageMax = ageT1 >= ageT2 ? ageT1 : ageT2;
	const t1Loan = _.get(mortgage, 'owner1.loanPayment', 0);
	const t1MortgagePay = _.get(mortgage, 'owner1.mortgagePayment', 0);
	const t1Pension = _.get(mortgage, 'owner1.pensionPayment', 0);
	const t2MortgagePay = _.get(mortgage, 'owner2.loanPayment', 0);
	const t2Loan = _.get(mortgage, 'owner2.mortgagePayment', 0);
	const t2Pension = _.get(mortgage, 'owner2.pensionPayment', 0);
	const totalPay = t1Loan + t1MortgagePay + t1Pension + t2MortgagePay + t2Loan + t2Pension;
	const [visibleCancel, setVisibleCancel] = useState(false);
	const [emptyFields, setEmptyFields] = useState([]);
	const states = {
		Andalucía: 'Andalucía',
		Aragón: 'Aragón',
		Asturias: 'Asturias',
		Baleares: 'Baleares',
		Canarias: 'Canarias',
		Cantabria: 'Cantabria',
		CastillaLaMancha: 'Castilla La Mancha',
		CastillaLeón: 'Castilla y León',
		Cataluña: 'Cataluña',
		Ceuta: 'Ceuta',
		Valenciana: 'Valenciana',
		Extremadura: 'Extremadura',
		Galicia: 'Galicia',
		Madrid: 'Madrid',
		Melilla: 'Melilla',
		Murcia: 'Murcia',
		Navarra: 'Navarra',
		PaísVasco: 'País Vasco',
		'La Rioja': 'La Rioja'
	};


	useEffect(() => {
		if (mortgage) {
			const values = form.getFieldsValue();
			const undefinedFields = Object.entries(values)
				.filter(([fieldName, value]) => value === undefined || value === "" || value === "Fecha inválida")
				.map(([fieldName]) => fieldName);
			setEmptyFields(undefinedFields);
		}
	}, [form, mortgage]);

	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const undefinedFields = Object.entries(values)
			.filter(([fieldName, value]) => value === undefined || value === "" || value === "Fecha inválida")
			.map(([fieldName]) => fieldName);
		setEmptyFields(undefinedFields);
	};

	const renderState = (province) => {
		const communities = {
			"Almería": "Andalucía",
			"Cádiz": "Andalucía",
			"Córdoba": "Andalucía",
			"Granada": "Andalucía",
			"Huelva": "Andalucía",
			"Jaén": "Andalucía",
			"Málaga": "Andalucía",
			"Sevilla": "Andalucía",
			"Huesca": "Aragón",
			"Teruel": "Aragón",
			"Zaragoza": "Aragón",
			"Asturias": "Asturias",
			"Baleares": "Baleares",
			"Las Palmas": "Canarias",
			"Santa Cruz de Tenerife": "Canarias",
			"Cantabria": "Cantabria",
			"Albacete": "CastillaLaMancha",
			"Ciudad Real": "CastillaLaMancha",
			"Cuenca": "CastillaLaMancha",
			"Guadalajara": "CastillaLaMancha",
			"Toledo": "CastillaLaMancha",
			"Ávila": "CastillaLeón",
			"Burgos": "CastillaLeón",
			"León": "CastillaLeón",
			"Palencia": "CastillaLeón",
			"Salamanca": "CastillaLeón",
			"Segovia": "CastillaLeón",
			"Soria": "CastillaLeón",
			"Valladolid": "CastillaLeón",
			"Zamora": "CastillaLeón",
			"Barcelona": "Cataluña",
			"Girona": "Cataluña",
			"Lleida": "Cataluña",
			"Tarragona": "Cataluña",
			"Ceuta": "Ceuta",
			"Alicante": "Valenciana",
			"Castellón": "Valenciana",
			"Valencia": "Valenciana",
			"Badajoz": "Extremadura",
			"Cáceres": "Extremadura",
			"A Coruña": "Galicia",
			"Lugo": "Galicia",
			"Ourense": "Galicia",
			"Pontevedra": "Galicia",
			"Madrid": "Madrid",
			"Melilla": "Melilla",
			"Murcia": "Murcia",
			"Navarra": "Navarra",
			"Álava": "PaísVasco",
			"Guipúzcoa": "PaísVasco",
			"Vizcaya": "PaísVasco",
			"La Rioja": "La Rioja"
		};
		return communities[province] || "Seleccionar";
	};

	const activityTypesToCaixaTypes = {
		EMPLOYEE_FIXED: 'ASALARIADO (Indefinido)',
		EMPLOYEE_TEMPORAL: 'ASALARIADO (Temporal)',
		FREELANCER: 'AUTÓNOMO',
		PENSIONER: 'OTROS',
		BUSINESS_OWNER: 'OTROS',
		FUNCTIONARY: 'FUNCIONARIO',
		FUNCTIONARY_INTERIM: 'FUNCIONARIO',
		RENTIER: 'OTROS'
	};

	const renderForm = () => {
		const arrayform = [];

		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			arrayform.push(
				<>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'activity' + index}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{`T${index} ocupación`}
								</Text>
							}
							className={emptyFields.includes(`activity${index}`) ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<Select showSearch placeholder="Seleccione" style={{ width: '100%' }} onChange={handleFormChange}>
								<Option value="ASALARIADO (Indefinido)">{'Asalariado (Indefinido)'}</Option>
								<Option value="ASALARIADO (Temporal)">{'Asalariado (Temporal)'}</Option>
								<Option value="AUTÓNOMO">{'Autónomo'}</Option>
								<Option value="AUTÓNOMO (NÓMINA)">{'Autónomo (Nómina)'}</Option>
								<Option value="DESEMPLEADO">{'Desempleado'}</Option>
								<Option value="FUNCIONARIO">{'Funcionario'}</Option>
								<Option value="OTROS">{'Otros'}</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'netIncomes' + index}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{`T${index} ingresos al año`}
								</Text>
							}
							className={emptyFields.includes(`netIncomes${index}`) ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<InputNumber
								formatter={(value) => numeral(value).format('0,0 $')}
								parser={(value) => numeral(value).value()}
								style={{ width: '100%', borderRadius: 4 }}
							/>
						</Form.Item>
					</Col>
				</>
			);
		}

		return arrayform;
	};

	const onFinish = (values) => {
		let valuesFormat = [];

		for (let index = 1; index <= parseInt(mortgage.mortgage.participants); index++) {
			valuesFormat.push({
				['owner' + index]: {
					work: values[`${'activity' + index}`],
					incomes: values[`${'netIncomes' + index}`]
				}
			});
		}

		axios
			.post(
				`${env.api.url}/v1/hadmin/banks/generate-excel-caixa`,
				{
					operationId: mortgageId,
					email: mortgage.owner1.email,
					phone: mortgage.owner1.phone,
					owner1: valuesFormat[0].owner1,
					owner2: valuesFormat.length > 1 ? valuesFormat[1].owner2 : undefined,
					expenses: values.debPayments,
					maxAge: values.age,
					state: values.comunity,
					propertyType: values.propertyType,
					houseType: values.houseType,
					housePrice: values.housePrice,
					maxBudget: values.maxBudget,
					term: values.term,
					operationType: values.operationType,
					initialMaxBudget: values.initialMaxBudget
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				message.success('Archivo csv creado correctamente');
				navigate(`/operations/mortgages/${mortgageId}/view-document-list`, {
					state: { bankId: idBank, bankRequestId: bankRequestId, bankRequest: bankRequest }
				});

				window.open(response.data.url);
			});
	};

	const cancelSend = () => {
		setVisibleCancel(!visibleCancel);
	};
	const handleOk = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/send-to-bank`, {
			state: { bankId: idBank, bankRequestId: bankRequestId, bankRequest: bankRequest }
		});
	};

	const handleOk2 = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};
	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};

	return (
		<>
			<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
				Datos de la operación
			</Text>
			<Form
				form={form}
				layout="vertical"
				name="sendMessageForm"
				style={{ width: '100%' }}
				initialValues={{
					activity1:
						mortgage['owner1'].activity !== undefined
							? activityTypesToCaixaTypes[mortgage['owner1'].activity]
							: '',
					activity2:
						mortgage['owner2'] !== undefined
							? activityTypesToCaixaTypes[mortgage['owner2'].activity]
							: '',
					netIncomes1:
						mortgage['owner1'].netIncomes !== undefined
							? mortgage['owner1'].netIncomes * mortgage['owner1'].numberOfPayments +
							mortgage['owner1'].bonus
							: '',
					netIncomes2:
						mortgage['owner2'] !== undefined
							? mortgage['owner2'].netIncomes * mortgage['owner2'].numberOfPayments +
							mortgage['owner2'].bonus
							: '',
					propertyType: mortgage.mortgage.propertyType === 'newConstruction' ? 'NUEVA' : 'USADA',
					housePrice:
						mortgage.mortgage.maxBudget !== undefined
							? mortgage.mortgage.maxBudget
							: mortgage.subrogation.maxBudget !== undefined
								? mortgage.subrogation.maxBudget
								: 0,
					maxBudget: bankRequest.amount,
					term: bankRequest.months,
					debPayments: totalPay,
					age: ageMax,
					comunity: mortgage.subrogation !== undefined 
						? renderState(mortgage.subrogation.province)
						: renderState(mortgage.mortgage.province),
					houseType: (mortgage.mortgage.houseType === 'main' ||  mortgage.mortgage.houseType === undefined)
							? 'HABITUAL' 
							:  mortgage.mortgage.houseType === 'investor'  
								? 'INVERSIÓN' 
									:  mortgage.mortgage.houseType === 'second'
										?'TEMPORADA'
										: 'OTROS',
					operationType: mortgage.subrogation !== undefined 
						? 'MEJORA'
						: mortgage.mortgage.selfPromoter ? 'AUTOPROMOTOR': 'COMPRAVENTA',
					initialMaxBudget: mortgage.subrogation !== undefined ? mortgage.subrogation.maxBudget : undefined
				}}
				onChange={handleFormChange}
				onFinish={onFinish}
			>
				<Row gutter={[32, 32]} style={{ marginTop: 24 }}>
					{renderForm()}
				</Row>

				<Row gutter={[32, 32]}>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'debPayments'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{`Obligaciones de pago/mes`}
								</Text>
							}
							className={emptyFields.includes('debPayments') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<InputNumber
								formatter={(value) => numeral(value).format('0,0 $')}
								parser={(value) => numeral(value).value()}
								style={{ width: '100%', borderRadius: 4 }}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'age'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{`Edad max`}
								</Text>
							}
							className={emptyFields.includes('age') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<InputNumber style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[32, 32]}>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'comunity'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{`Comunidad Autónoma`}
								</Text>
							}
							className={emptyFields.includes('comunity') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<Select showSearch placeholder="Seleccione" style={{ width: '100%' }} onChange={handleFormChange}>
								{Object.entries(states).map(([key, value]) => (
									<Option key={key} value={key}>
										{value}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'propertyType'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									Nueva / Usada
								</Text>
							}
							className={emptyFields.includes('propertyType') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<Select
								showSearch
								onChange={handleFormChange}
								placeholder="Seleccione"
								name="new/second"
								style={{ width: '100%' }}
							>
								<Option value="NUEVA">Obra nueva</Option>
								<Option value="USADA">Segunda mano</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'houseType'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>Residencia</Text>
							}
							className={emptyFields.includes('houseType') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<Select
								showSearch
								onChange={handleFormChange}
								placeholder="Seleccione"
								name="new/second"
								style={{ width: '100%' }}
							>
								<Option value="HABITUAL">Vivienda habitual</Option>
								<Option value="TEMPORADA">Vivienda de temporada</Option>
								<Option value="INVERSIÓN">Inversión</Option>
								<Option value="OTROS">Otros</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[32, 32]}>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'housePrice'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{`Valor vivienda`}
								</Text>
							}
							className={emptyFields.includes('housePrice') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<InputNumber
								formatter={(value) => numeral(value).format('0,0 $')}
								parser={(value) => numeral(value).value()}
								style={{ width: '100%', borderRadius: 4 }}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'maxBudget'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{`Importe del préstamo`}
								</Text>
							}
							className={emptyFields.includes('maxBudget') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<InputNumber
								formatter={(value) => numeral(value).format('0,0 $')}
								parser={(value) => numeral(value).value()}
								style={{ width: '100%', borderRadius: 4 }}
							/>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'term'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{`Plazo (meses)`}
								</Text>
							}
							className={emptyFields.includes('term') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<InputNumber className="inputCommon" style={{ borderRadius: 4, width: '100%' }} />
						</Form.Item>
					</Col>
					{type.subtype === 'subrogation' && (
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'initialMaxBudget'}
								className={emptyFields.includes('initialMaxBudget') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Valor de compra-venta inicial`}
									</Text>
								}
							>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					)}
				</Row>
				<Row gutter={[32, 32]}>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'operationType'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									Tipo operación
								</Text>
							}
							className={emptyFields.includes('operationType') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}
						>
							<Select showSearch placeholder="Seleccione" style={{ width: '100%' }} onChange={handleFormChange}>
								<Option value="COMPRAVENTA">Compra venta</Option>
								<Option value="SUBROGACIÓN">Subrogación</Option>
								<Option value="MEJORA">Mejora</Option>
								<Option value="AUTOPROMOTOR">Autopromotor</Option>
								<Option value="OTROS">Otros</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Row style={{ justifyContent: 'center', marginTop: 20 }}>
					<Button
						style={{ height: 32, width: 96, fontSize: 14 }}
						className="button-secundary-gibobs"
						onClick={() => cancelSend()}
						type="dashed"
					>
						Cancelar
					</Button>

					<Button
						style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
						type="primary"
						htmlType="submit"
						className="button-primari-gibobs"
					>
						Aceptar
					</Button>
				</Row>
			</Form>
			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: 96, marginTop: 16 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96, marginTop: 16 } }}
				visible={visibleCancel}
				title={
					<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
						{'Cancelar generación de excel'}
					</Text>
				}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				width={404}
				onCancel={handleCancel}
				onOk={handleOk2}
			>
				<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858' }}>
					{'¿Aceptas la cancelación de la generación del excel de La Caixa?'}
				</Text>
			</Modal>
		</>
	);
};
export default TableCaixaBank;
