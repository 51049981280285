import React, { useEffect } from 'react';
import {
	AndroidOutlined,
	BankOutlined,
	BarChartOutlined,
	FileAddOutlined,
	HistoryOutlined,
	HomeOutlined,
	LoginOutlined,
	MonitorOutlined,
	ProfileOutlined,
	ScheduleOutlined,
	ShopOutlined,
	UserAddOutlined,
	UserSwitchOutlined,
	TranslationOutlined,
	DeploymentUnitOutlined,
	FileOutlined
} from '@ant-design/icons';

import authClient from '../../Auth/Auth';

const SidebarItemsHadminMenu = (profileType) => {
	return (
		[
			{
				name: 'Inicio',
				route: '/',
				icon: <HomeOutlined />
			},
			{
				name: 'Gestor de tareas',
				route: '/management',
				icon: <ScheduleOutlined />
			},
			{
				name: 'Operaciones',
				route: profileType === 'php-risk' ? '/promoter-loan/list/active' : '/operations/mortgages/list/active',
				icon: <ScheduleOutlined />
			},
			{
				name: 'Preregistros',
				route: '/preregister',
				icon: <UserSwitchOutlined />
			},
			{
				name: 'Agencias B2B',
				route: '/realestates/realestates',
				icon: <ShopOutlined />
			},
			{
				name: 'Entidades bancarias',
				route: '/banks',
				icon: <ShopOutlined />
			},

			{
				name: 'Informes',
				route: '/dashboardDetails',
				icon: <BarChartOutlined />
			},
			{
				name: 'Herramientas',
				route: '/tools/blueprints',
				icon: <ShopOutlined />
			},
			{
				name: 'Área de gestión',
				route: '/management-area',
				icon: <FileOutlined />
			}
		]
	)

};

/*
if(auth.getProfile().type === "admin") {
	SidebarItemsHadminMenu.push({
		name: "Blueprints",
		route: '/tools/blueprints',
		icon: <DeploymentUnitOutlined />
	});
}

SidebarItemsHadminMenu.push({
	name: "Afterbanks",
	route: '/tools/afterbanks',
	icon: <BankOutlined />
});
*/

export const SidebarItemsHadmin = SidebarItemsHadminMenu;

export const SidebarItemsCustomers = [
	{
		name: 'Inicio',
		route: '/home',
		icon: <HomeOutlined />
	}
];

export const SidebarItemsLoginCustomer = [
	{
		name: 'Login',
		route: '/login',
		icon: <UserAddOutlined />
	},
	{
		name: 'signUp',
		route: '/signUp',
		icon: <LoginOutlined />
	},
	{
		name: 'Olvide Contraseña',
		route: '/reset-password',
		icon: <AndroidOutlined />
	},
	{
		name: 'Olvide mi usuario',
		route: '/reset-user'
	}
];

export const SidebarItemsLoginHadmmin = [
	{
		name: 'Login',
		route: '/login',
		icon: <UserAddOutlined />
	},
	{
		name: 'Verificar',
		route: '/verify',
		icon: <MonitorOutlined />
	},
	{
		name: 'Propiedades',
		route: '/properties',
		icon: <BankOutlined />
	},
	{
		name: 'Comercial',
		route: '/sales',
		icon: <ProfileOutlined />
	}
];
