import React, { useEffect, useState } from 'react';
import axios from 'axios';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import DataNewFormatMortgage from './DataNewFormatMortgage';
import DataNewFormatSubrogation from './DataNewFormatSubrogation';
import { useParams } from 'react-router-dom';

const DataNewFormat = ({ mortgage, subrogation, operationType, dataClient, banks, isSabadell }) => {
	const [dataBankBlock, setDataBankBlock] = useState(undefined);
	const params = useParams();

	useEffect(() => {
		getBankBloked();
	}, []);

	const getBankBloked = () => {
		axios
			.get(`${env.api.url}/banks/operation-blocked-bank/by-operation/${params.mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setDataBankBlock(response.data.data);
			});
	};

	return (
		<div>
			<div div style={{ width: '100%' }}>
				{operationType === 'subrogation' ? (
					<DataNewFormatSubrogation
						banks={banks && banks}
						subrogation={subrogation}
						dataClient={dataClient}
						dataBankBlock={dataBankBlock}
						isSabadell={isSabadell}
					/>
				) : (
					<DataNewFormatMortgage
						mortgage={mortgage}
						operationType={operationType}
						dataClient={dataClient}
						isSabadell={isSabadell}
					/>
				)}
			</div>
		</div>
	);
};

export default DataNewFormat;
