import { SETDATATASK, SETDATATASKPERWEEK, SETDAYS, UPDATENOTIFICATION } from './Constants';
import axios from 'axios';
import env from '../../environment';
import _ from 'lodash';
import { store } from '../index';
import moment from 'moment';
import { message } from 'antd';

const auth = store.getState().auth.token;
const days = store.getState().task.setDays;

export const fetchTaskList = (type, token) => async (dispatch) => {
	axios
		.get(`${env.api.url}/v1/hadmin/tasks?status=${type}`, {
			headers: { Authorization: `Bearer ${token !== undefined ? token : auth}` }
		})
		.then((response) => {
			let tasks = [];

			for (let index = 0; index < response.data.task.length; index++) {
				const element = response.data.task[index];
				element.attemptsPlus = [];
				tasks.push(element);
			}
			tasks = _.orderBy(
				tasks,
				[
					(task) => {
						return task.priority;
					}
				],
				['desc']
			);
			dispatch({
				type: SETDATATASK,
				payload: tasks
			});
		});
};

export const fetchTaskPerWeeks = (days, token) => async (dispatch) => {
	axios
		.get(
			`${env.api.url}/v1/hadmin/tasks?status=calendar&startDate=${moment(days[0]).format(
				'YYYY-MM-DD'
			)}&endDate=${moment(days[1]).add(5, 'days').format('YYYY-MM-DD')}`,
			{
				headers: { Authorization: `Bearer ${token !== undefined ? token : auth}` }
			}
		)
		.then((response) => {
			let tasks = [];

			for (let index = 0; index < response.data.task.length; index++) {
				const element = response.data.task[index];
				element.attemptsPlus = [];
				tasks.push(element);
			}
			tasks = _.orderBy(
				tasks,
				[
					(task) => {
						return task.priority;
					}
				],
				['desc']
			);
			dispatch({
				type: SETDATATASKPERWEEK,
				payload: tasks
			});
		});
};

export const updateNotificationBoolean = (updateTasks) => async (dispatch) => {
	await dispatch({
		type: UPDATENOTIFICATION,
		payload: updateTasks
	});
};

export const updateDays = (days) => async (dispatch) => {
	await dispatch({
		type: SETDAYS,
		payload: days
	});
};

export const actions =
	(type, taskId, note, date = undefined, hour) =>
	async (dispatch) => {
		axios
			.post(
				`${env.api.url}/v1/hadmin/tasks/action/${type}/${taskId}`,
				{
					note: note,
					dueDate:
						date !== undefined
							? moment(
									`${date.format('YYYY-MM-DD')} ${hour.format('HH:mm')}`,
									'YYYY-MM-DD HH:mm'
							  ).toDate()
							: undefined
				},
				{
					headers: { Authorization: `Bearer ${auth}` }
				}
			)
			.then((response) => {
				if (response.data.errorCode === 'FORBIDDEN') {
					message.error('Comprueba que tu eres el que ha creado la tarea a eliminar');
				} else {
					message.success('Se creó Exitosamente');
				}
				dispatch(fetchTaskList('current'));
				dispatch(updateNotificationBoolean(false));
			})
			.catch((error) => {
				message.error('Compruebe que la fecha es mayor que hoy');
			});
	};
