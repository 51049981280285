import {
	ERROR,
	LOADING,
	PROFILE,
	AUTHENTICATED,
	PROFILERESET,
	ROLES,
	LOGINFAILE,
	TOKEN,
	DATETABLE,
	DATACLIENT
} from './Constants';
import axios from 'axios';
import env from '../../environment';

export const loginRedux = (username, password) => async (dispatch) => {
	dispatch({
		type: LOADING,
		payload: true
	});

	localStorage.removeItem('access_token');
	localStorage.removeItem('expiresAt');
	localStorage.removeItem('profile');
	localStorage.removeItem('roles');

	let managementCode = username.includes('@gibobs.com')
		? 'gibobs'
		: username.includes('@bancsabadell.com')
		? 'sabadell-es'
		: 'error';

	if (username === 'lara.gonzalez@gibobs.com' || username === 'paloma.bellas@gibobs.com') {
		managementCode = 'sabadell-es';
	}

	let response = await axios
		.post(`${env.api.url}/v1/auth/login`, { username, password, managementCode })
		.then((respuesta) => {
			if (respuesta.data.success) {
				const profile = respuesta.data.data.profile;

				if (managementCode === 'error') {
				}

				if (profile.roles.indexOf('hadmin') === -1 || managementCode === 'error') {
					dispatch({
						type: ERROR,
						payload: 'no hadmin account'
					});
					dispatch({
						type: LOADING,
						payload: false
					});

					return;
				}

				let expiresAt = JSON.stringify(respuesta.data.data.expiresAt * 1000 + new Date().getTime());

				localStorage.setItem('access_token', respuesta.data.data.token);
				localStorage.setItem('expiresAt', respuesta.data.data.expiresAt);
				localStorage.setItem('roles', respuesta.data.data.profile.roles);
				localStorage.setItem('profile', JSON.stringify(profile));

				dispatch({
					type: PROFILE,
					payload: respuesta.data.data
				});
				dispatch({
					type: LOADING,
					payload: false
				});
				dispatch({
					type: AUTHENTICATED,
					payload: true
				});
				dispatch({
					type: ROLES,
					payload: respuesta.data.data.profile.roles
				});
				dispatch({
					type: TOKEN,
					payload: respuesta.data.data.token
				});

				return respuesta.data;
			} else {
				return respuesta.data;
			}
		})
		.catch((error) => {
			console.log(error);
			dispatch({
				type: ERROR,
				payload: error
			});
			dispatch({
				type: LOADING,
				payload: false
			});
		});
	return response;
};

export const getProfile =
	({ token }) =>
	async (dispatch) => {
		dispatch({
			type: LOADING,
			payload: true
		});

		axios
			.get(`${env.api.url}/v1/auth/get-profile`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((response) => {
				if (response.data.success) {
					const profile = response.data.data;
					const expiresAt = response.data.data.expiresAt;
					const allRoles = response.data.data.roles;
					let roles = response.data.data.roles[0];

					localStorage.setItem('access_token', token);
					localStorage.setItem('roles', roles);
					localStorage.setItem('allRoles', allRoles);
					localStorage.setItem('expiresAt', expiresAt * 1000);
					localStorage.setItem('profile', JSON.stringify(response.data.data));

					dispatch({
						type: PROFILE,
						payload: profile
					});

					dispatch({
						type: LOADING,
						payload: false
					});

					dispatch({
						type: AUTHENTICATED,
						payload: true
					});

					dispatch({
						type: ROLES,
						payload: roles
					});

					dispatch({
						type: TOKEN,
						payload: token
					});

					/* dispatch({
						type: EXPIRES_AT,
						payload: expiresAt
					}); */
				} else {
					dispatch({
						type: LOADING,
						payload: false
					});
				}
			})
			.catch((error) => {
				dispatch({
					type: ERROR,
					payload: error
				});
				dispatch({
					type: LOADING,
					payload: false
				});
			});

		return 'LOGIN';
	};

export const resetPasswordRedux = (token, email, password) => async (dispatch) => {
	dispatch({
		type: LOADING,
		payload: true
	});
	let response = await axios
		.post(`${env.api.url}/v1/auth/reset-password`, { token, email, password })
		.then((respuesta) => {
			dispatch({
				type: LOADING,
				payload: false
			});
			return respuesta.data;
		})
		.catch((error) => {
			console.log(error);
			dispatch({
				type: ERROR,
				payload: error.message
			});
			dispatch({
				type: LOADING,
				payload: false
			});
		});
	if (!response) {
		return false;
	} else {
		return true;
	}
};

export const restorePasswordRedux = (email) => async (dispatch) => {
	dispatch({
		type: LOADING,
		payload: true
	});

	let response = await axios
		.post(`${env.api.url}/v1/auth/restore-password`, email)
		.then((respuesta) => {
			dispatch({
				type: LOADING,
				payload: false
			});
			return respuesta.data;
		})
		.catch((error) => {
			console.log(error);
			dispatch({
				type: ERROR,
				payload: error.message
			});
			dispatch({
				type: LOADING,
				payload: false
			});
		});

	if (!response) {
		return false;
	} else {
		return true;
	}
};

export const resetProfileRedux = () => async (dispatch) => {
	localStorage.removeItem('access_token');
	localStorage.removeItem('expiresAt');
	localStorage.removeItem('profile');
	localStorage.removeItem('roles');

	dispatch({
		type: PROFILE,
		payload: []
	});
	dispatch({
		type: AUTHENTICATED,
		payload: false
	});
	dispatch({
		type: ROLES,
		payload: []
	});
};

export const LoginReduxError = () => async (dispatch) => {
	dispatch({
		type: LOGINFAILE,
		payload: true
	});
};

export const LoginReduxErrorReset = () => async (dispatch) => {
	dispatch({
		type: LOGINFAILE,
		payload: false
	});
};
export const dateTable = (change) => {
	return {
		type: DATETABLE,
		payload: change
	};
};

export const dataClient = (change) => {
	return {
		type: DATACLIENT,
		payload: change
	};
};
