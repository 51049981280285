import {
	SETDATATASK,
	UPDATETASKS,
	UPDATENOTIFICATION,
	SETDAYS,
	SETDATATASKPERWEEK
} from './Constants';

export const INITIAL_STATE = {
	setDataTask: [],
	setDataTaskPerWeek: [],
	setDataNotification: false,
	setDays: []
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SETDATATASK:
			return { ...state, setDataTask: action.payload };
		case SETDATATASKPERWEEK:
			return { ...state, setDataTaskPerWeek: action.payload };
		case UPDATETASKS:
			return { ...state, setDataTask: action.payload };
		case UPDATENOTIFICATION:
			return { ...state, setDataNotification: action.payload };
		case SETDAYS:
			return { ...state, setDays: action.payload };
		default:
			return state;
	}
};
