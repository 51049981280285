import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Input,
	Modal,
	Form,
	Select,
	InputNumber,
	Spin,
	notification,
	Card
} from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputDate } from 'afrodita';
import moment from 'moment';
import _ from 'lodash';
import { BulbOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { Option } = Select;

const TableSantander = ({ mortgageId, mortgage, type }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [visibleCancel, setVisibleCancel] = useState(false);
	const [communities, setCommunities] = useState(undefined);
	const [otherDatas, setOtherDatas] = useState(undefined);
	const [otherDatasAll, setOtherDatasAll] = useState(undefined);
	const [data, setData] = useState([]);
	const [value, setValue] = useState(undefined);
	const [loading, setLoading] = useState(false);
	let timeout;
	let currentValue;
	const location = useLocation();
	const bankRequestId =
		location && location.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest =
		location && location.state !== undefined ? location.state.bankRequest : undefined;
	const [emptyFields, setEmptyFields] = useState([]);

	let payRollProrated1 =
		mortgage &&
		parseInt((Number(mortgage['owner1'].numberOfPayments) * mortgage['owner1'].netIncomes) / 12);

	let payRollProrated2 =
		mortgage &&
		mortgage['owner2'] &&
		parseInt((Number(mortgage['owner2'].numberOfPayments) * mortgage['owner2'].netIncomes) / 12);

	const RF = bankRequest.amount / mortgage.mortgage.maxBudget;
	const [valueAppraisal, setValueAppraisal] = useState(0);
	const [operationType, setOperationType] = useState(undefined);

	const validatorSantander = [
		{ finality: '01', subfinality: '01', typeOperation: '01', residence: 'ES', interest: 'F' },
		{ finality: '01', subfinality: '01', typeOperation: '01', residence: 'ES', interest: 'V' },
		{ finality: '01', subfinality: '01', typeOperation: '01', residence: 'ES', interest: 'M' },
		{ finality: '01', subfinality: '01', typeOperation: '01', residence: '99', interest: 'F' },
		{ finality: '01', subfinality: '01', typeOperation: '01', residence: '99', interest: 'V' },

		{ finality: '01', subfinality: '01', typeOperation: '02', residence: 'ES', interest: 'F' },
		{ finality: '01', subfinality: '01', typeOperation: '02', residence: 'ES', interest: 'V' },

		{ finality: '02', subfinality: '01', typeOperation: '03', residence: '99', interest: 'V' },
		{ finality: '02', subfinality: '01', typeOperation: '03', residence: 'ES', interest: 'F' },
		{ finality: '02', subfinality: '01', typeOperation: '03', residence: 'ES', interest: 'V' },

		{ finality: '99', subfinality: '99', typeOperation: '01', residence: 'ES', interest: 'F' },
		{ finality: '99', subfinality: '99', typeOperation: '01', residence: 'ES', interest: 'V' },
		{ finality: '99', subfinality: '99', typeOperation: '01', residence: '99', interest: 'V' },

		{ finality: '99', subfinality: '99', typeOperation: '02', residence: 'ES', interest: 'V' }
	];

	useEffect(() => {
		getOtherData();
		getCommunities();

		if (mortgage && bankRequest) {
			if (RF > 0.8 && type.subtype === 'mortgage') {
				setValueAppraisal(mortgage?.mortgage?.maxBudget * 1.2);
			} else if (RF > 0.8 && type.subtype === 'subrogation') {
				setValueAppraisal(mortgage?.subrogation?.amount * 1.2);
			} else if (RF <= 0.8 && type?.subtype === 'mortgage') {
				setValueAppraisal(mortgage?.mortgage?.maxBudget);
			} else {
				setValueAppraisal(mortgage?.subrogation?.amount);
			}
		}
	}, []);

	useEffect(() => {
		if (mortgage && otherDatas && communities && otherDatasAll) {
			const values = form.getFieldsValue();
			const undefinedFields = Object.entries(values)
				.filter(
					([fieldName, value]) => value === undefined || value === '' || value === 'Fecha inválida'
				)
				.map(([fieldName]) => fieldName);
			setEmptyFields(undefinedFields);
		}
	}, [form, mortgage, otherDatas, communities, otherDatasAll]);

	const options = data.map((d) => <Option value={d.code}>{d.name}</Option>);

	const getOtherData = () => {
		axios.get(`${env.api.url}/banks/santander/data/jsonOther`).then((response) => {
			setOtherDatas(response.data.data.dataOther);
			setOtherDatasAll(response.data.data);
		});
	};

	const getCommunities = () => {
		axios.get(`${env.api.url}/banks/santander/data/jsonCommunities`).then((response) => {
			setCommunities(response.data.data.dataCommunities);
		});
	};

	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const undefinedFields = Object.entries(values)
			.filter(
				([fieldName, value]) => value === undefined || value === '' || value === 'Fecha inválida'
			)
			.map(([fieldName]) => fieldName);
		setEmptyFields(undefinedFields);
	};

	const search = (value) => {
		if (timeout) {
			clearTimeout(timeout);
			timeout = null;
		}
		currentValue = value;

		const searchData = () => {
			axios
				.get(`${env.api.url}/banks/santander/data/municipes?search=${value}`)
				.then((response) => {
					if (currentValue === value) {
						setData(response.data.data.municipes);
					}
				});
		};
		timeout = setTimeout(searchData, 300);
	};

	const handleSearch = (value) => {
		if (value) {
			search(value);
		} else {
			setData([]);
		}
	};

	const renderForm = () => {
		const arrayform = [];

		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			arrayform.push(
				<>
					<Row>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#2F4858',
								marginTop: index === 1 ? 32 : 24
							}}>
							{'Titular ' + index}
						</Text>
					</Row>
					<Row gutter={[32, 32]} style={{ marginTop: 24 }}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'nifType' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de documento'}
									</Text>
								}
								className={emptyFields.includes(`nifType${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.typeHolder.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'nif' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Número de documento'}
									</Text>
								}
								className={emptyFields.includes(`nif${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'mail' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'Mail'}</Text>
								}
								className={emptyFields.includes(`mail${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>

						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'mobilePhone' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Teléfono'}
									</Text>
								}
								className={emptyFields.includes(`mobilePhone${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[32, 0]} style={{ marginTop: -10 }}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'name' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nombre'}
									</Text>
								}
								className={emptyFields.includes(`name${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'firstSurname' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Apellido 1'}
									</Text>
								}
								className={emptyFields.includes(`firstSurname${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'secondSurname' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Apellido 2'}
									</Text>
								}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'birthdate' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Fecha de nacimiento'}
									</Text>
								}
								className={emptyFields.includes(`birthdate${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputDate
									style={{ width: '100%', borderRadius: 4 }}
									type="date"
									onChange={handleFormChange}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'profession' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de profesión'}
									</Text>
								}
								className={emptyFields.includes(`profession${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.typeProfession.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'activity' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de trabajo'}
									</Text>
								}
								className={emptyFields.includes(`activity${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.typeWork.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'professionalCategory' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Categoría Profesional'}
									</Text>
								}
								className={emptyFields.includes(`professionalCategory${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll.dataProfessionalCategory.map((data) => (
										<Option key={data.value} value={data.value}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'education' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nivel de estudios'}
									</Text>
								}
								className={emptyFields.includes(`education${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.educationLevel.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>

						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'residence' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'País de residencia'}
									</Text>
								}
								className={emptyFields.includes(`residence${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.countryResidence.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'payRollIncome' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Ingresos nómina mensual (prorrateado)'}
									</Text>
								}
								className={emptyFields.includes(`payRollIncome${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'netIncomes' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Ingresos mensuales totales (€ al mes)'}
									</Text>
								}
								className={emptyFields.includes(`netIncomes${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'otherIncomes' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Otros ingresos (€ anuales)'}
									</Text>
								}
								className={emptyFields.includes(`otherIncomes${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'irpfIncomes' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Ingresos IRPF (€ anuales)'}
									</Text>
								}
								className={emptyFields.includes(`irpfIncomes${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'annualBilling' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Facturación anual autónomo'}
									</Text>
								}
								className={
									mortgage['owner' + index]?.activity === 'FREELANCER' &&
									emptyFields.includes(`annualBilling${index}`)
										? 'shadowed'
										: ''
								}
								rules={[
									{
										required: mortgage['owner' + index]?.activity === 'FREELANCER' ? true : false,
										message: 'Por favor, rellene este campo'
									}
								]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={`loanPayment${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Obligaciones de pago mensuales totales (€ al mes)'}
									</Text>
								}
								className={emptyFields.includes(`loanPayment${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'paymentPension' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Pensiones componsatorias (€ al mes)'}
									</Text>
								}
								className={emptyFields.includes(`paymentPension${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'incomesRegion' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Comunidad autónoma donde recibe los ingresos`}
									</Text>
								}
								className={emptyFields.includes(`incomesRegion${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{communities.map((community) => (
										<Option key={community.code} value={community.code.toString()}>
											{community.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'housingSituation' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Situacion de la vivienda actual'}
									</Text>
								}
								className={emptyFields.includes(`housingSituation${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.housingSituation.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
				</>
			);
		}

		return arrayform;
	};

	const onFinish = (values) => {
		let valuesFormat = [];
		let valuesFormatOperation = [];
		setLoading(true);

		valuesFormat.push({
			['dataFirstHolder']: {
				typeHolder: values[`${'nifType' + 1}`],
				idHolder: values[`${'nif' + 1}`].trim(),
				mail: values[`${'mail' + 1}`],
				mobilePhone: values[`${'mobilePhone' + 1}`].toString(),
				name: values[`${'name' + 1}`],
				firstSurname: values[`${'firstSurname' + 1}`],
				secondSurname: values[`${'secondSurname' + 1}`],
				typeWork: values[`${'activity' + 1}`],
				birthDate: moment(values[`${'birthdate' + 1}`], 'DD-MM-YYYY').format('YYYY-MM-DD'),
				countryResidence: values[`${'residence' + 1}`],
				typeProfession: values[`${'profession' + 1}`],
				educationLevel: values[`${'education' + 1}`],
				housingSituation: values[`${'housingSituation' + 1}`],
				payRollIncome: values[`${'payRollIncome' + 1}`],
				irpfIncome: values[`${'irpfIncomes' + 1}`],
				incomeMonth: parseFloat(values[`${'netIncomes' + 1}`]),
				otherIncomes: values[`${'otherIncomes' + 1}`],
				outcomeMonth: values[`${'loanPayment' + 1}`],
				paymentPension: values[`${'paymentPension' + 1}`],
				incomesRegion: values[`${'incomesRegion' + 1}`],
				annualBilling: values[`${'annualBilling' + 1}`]
					? values[`${'annualBilling' + 1}`]
					: undefined,
				professionalCategory: values[`${'professionalCategory' + 1}`]
			}
		});

		if (parseInt(mortgage.mortgage.participants) === 2) {
			valuesFormat.push({
				['dataOtherHolder']: [
					{
						typeHolder: values[`${'nifType' + 2}`],
						idHolder: values[`${'nif' + 2}`].trim(),
						name: values[`${'name' + 2}`],
						firstSurname: values[`${'firstSurname' + 2}`],
						secondSurname: values[`${'secondSurname' + 2}`],
						birthDate: moment(values[`${'birthdate' + 2}`], 'DD-MM-YYYY').format('YYYY-MM-DD'),
						countryResidence: values[`${'residence' + 2}`],
						typeWork: values[`${'activity' + 2}`],
						typeProfession: values[`${'profession' + 2}`],
						educationLevel: values[`${'education' + 2}`],
						housingSituation: values[`${'housingSituation' + 2}`],
						payRollIncome: values[`${'payRollIncome' + 2}`],
						irpfIncome: values[`${'irpfIncomes' + 2}`],
						incomeMonth: values[`${'netIncomes' + 2}`],
						otherIncomes: values[`${'otherIncomes' + 2}`],
						outcomeMonth: values[`${'loanPayment' + 2}`],
						paymentPension: values[`${'paymentPension' + 2}`],
						incomesRegion: values[`${'incomesRegion' + 2}`],
						annualBilling: values[`${'annualBilling' + 2}`]
							? values[`${'annualBilling' + 2}`]
							: undefined,
						mail: values[`${'mail' + 2}`],
						mobilePhone: values[`${'mobilePhone' + 2}`].toString(),
						professionalCategory: values[`${'professionalCategory' + 2}`]
					}
				]
			});
		} else {
			valuesFormat.push({
				['dataOtherHolder']: []
			});
		}

		valuesFormatOperation.push({
			['productData']: {
				finality: values[`${'finality'}`],
				subfinality: values[`${'subfinality'}`],
				interestRate: values[`${'interestRate'}`],
				operationType: values[`${'operationType'}`],
				purposeHousing: values[`${'purposeHousing'}`],
				typeHousing: values[`${'typeHousing'}`],
				ccv: values[`${'ccv'}`],
				amount: Number(values[`${'amount'}`]),
				term: values[`${'term'}`],
				location: values[`${'location'}`],
				tasationValue: values[`${'tasationValue'}`]
			}
		});

		let santanderValidatorFlag = false;

		for (let i = 0; i < validatorSantander.length; i++) {
			const validator = validatorSantander[i];

			if (
				values.finality === validator.finality &&
				values.subfinality === validator.subfinality &&
				values.operationType === validator.typeOperation &&
				values.residence1 === validator.residence &&
				values.interestRate === validator.interest
			) {
				santanderValidatorFlag = true;
			}
		}

		if (!santanderValidatorFlag) {
			setLoading(false);

			notification.error({
				className: 'error-notification-gibobs',
				message: 'Producto no disponible en Santander',
				description:
					'Los datos introducidos no son validos segun la matriz, por favor consulta la matriz si tienes dudas'
			});

			return;
		}

		axios
			.post(
				`${env.api.url}/banks/santander/assesment/execute-process`,
				{
					dataFirstHolder: valuesFormat[0].dataFirstHolder,
					dataOtherHolder: valuesFormat[1].dataOtherHolder,
					productData: valuesFormatOperation[0].productData,
					bankRequestId: bankRequestId
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (
					response.data.code === 400 &&
					response.data.errorCode === 'SANTANDER_CREATE_ASSESMENT_FAILED'
				) {
					notification.error({
						message: 'Se ha producido un error al enviar a Santander',
						description: response.data.data.moreInformation,
						duration: 0
					});

					setLoading(false);
				} else if (response.data.code === 400 && response.data.errorCode === undefined) {
					notification.error({
						message: 'Se ha producido un error al enviar a Santander',
						description:
							'Error al introducir los datos, por favor compruebe los campos' +
							JSON.stringify(response.data.data),
						duration: 0
					});
					setLoading(false);
				} else if (response.data.data.error === true) {
					notification.error({
						message: 'Envio fallido',
						description: response.data.data.errorReasons,
						duration: 0
					});
					setLoading(false);
				} else if (response.data.code === 500) {
					notification.error({
						message: 'Envio fallido',
						description: `Algo se ha roto en el API de Banco Santander (Hablar con Fernando)`,
						duration: 0
					});
					setLoading(false);
				} else {
					notification.success({
						message: 'Se ha generado correctamente la oferta.',
						description: 'La oferta se genero correctamente'
					});
					setLoading(false);
					navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
				}
			});
	};

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}

	const cancelSend = () => {
		setVisibleCancel(!visibleCancel);
	};
	const handleOk = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/view-document-list`);
	};
	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};

	const renderTypeWork = (id) => {
		if (otherDatasAll && otherDatasAll.dataTypeworks) {
			return otherDatasAll.dataTypeworks[id];
		} else {
			return undefined;
		}
	};

	const handleOk2 = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};

	const renderImages = () => {
		if (
			operationType === '03' &&
			(mortgage.owner1?.cityzenship === 'ES' || mortgage?.owner2?.cityzenship === 'ES')
		) {
			return (
				<img
					src="/images/santander/AUTOCONTRUCCION_RESIDENTE.png"
					alt="AUTOCONTRUCCION_RESIDENTE"
					width={'100%'}
					height={200}></img>
			);
		} else if (
			operationType === '03' &&
			mortgage.owner1?.cityzenship !== 'ES' &&
			mortgage?.owner2?.cityzenship !== 'ES'
		) {
			return (
				<img
					src="/images/santander/AUTOCONTRUCCION_NORESIDENTE.png"
					alt="AUTOCONTRUCCION_NORESIDENTE"
					width={'100%'}></img>
			);
		} else {
			if (
				type.subtype === 'subrogation' &&
				mortgage.owner1?.cityzenship !== 'ES' &&
				mortgage?.owner2?.cityzenship !== 'ES'
			) {
				return (
					<img
						src="/images/santander/SUBROGACION_NORESIDENTE.png"
						alt="SUBROGACION_NORESIDENTE"
						width={'100%'}></img>
				);
			} else if (
				type.subtype === 'subrogation' &&
				(mortgage.owner1?.cityzenship === 'ES' || mortgage?.owner2?.cityzenship === 'ES')
			) {
				return (
					<img
						src="/images/santander/SUBROGACION_RESIDENTE.png"
						alt="SUBROGACION_RESIDENTE"
						width={'100%'}></img>
				);
			} else if (
				type.subtype === 'mortgage' &&
				mortgage.owner1?.cityzenship !== 'ES' &&
				mortgage?.owner2?.cityzenship !== 'ES'
			) {
				return (
					<img
						src="/images/santander/HIPOTECA_NORESIDENTE.png"
						alt="HIPOTECA_NORESIDENTE"
						width={'100%'}></img>
				);
			} else if (type.subtype === 'mortgage') {
				if (
					mortgage.mortgage.participants > 2 &&
					mortgage.owner1?.cityzenship === 'ES' &&
					mortgage?.owner2?.cityzenship === 'ES' &&
					mortgage?.owner3?.cityzenship === 'ES'
				) {
					return (
						<img
							src="/images/santander/HIPOTECA_RESIDENTE_MUCHOSTITULARES.png"
							alt="HIPOTECA_RESIDENTE_MUCHOSTITULARES"
							width={'100%'}></img>
					);
				} else if (
					(mortgage.owner1 || mortgage.owner2 !== undefined) &&
					(mortgage.owner1?.age < 36 || mortgage?.owner2?.age < 36) &&
					mortgage.owner1?.cityzenship === 'ES' &&
					mortgage?.owner2?.cityzenship === 'ES'
				) {
					return (
						<img
							src="/images/santander/HIPOTECA_RESIDENTE_JOVEN.png"
							alt="HIPOTECA_RESIDENTE_JOVEN"
							width={'80%'}
							height={'400px'}></img>
					);
				} else {
					if (mortgage.owner1?.cityzenship === 'ES') {
						return (
							<img
								src="/images/santander/HIPOTECA_RESIDENTE_GENERAL.png"
								alt="HIPOTECA_RESIDENTE_GENERAL"
								width={'100%'}></img>
						);
					}
					return undefined;
				}
			}
		}
	};

	const renderProfessionalCategory = (owner) => {
		const activity = mortgage[owner].activity;
		if (activity === 'FUNCTIONARY') {
			return '02';
		} else if (activity === 'FREELANCER') {
			return '05';
		} else {
			return undefined;
		}
	};

	const renderNetIconmes = (owner) => {
		const box0017 = mortgage[owner]?.incomesWealth?.IRPF_0017
			? mortgage[owner]?.incomesWealth?.IRPF_0017
			: 0;
		const box0235 = mortgage[owner]?.incomesWealth?.sumOfNetReturnReducedEconomyActivity0235
			? mortgage[owner]?.incomesWealth?.sumOfNetReturnReducedEconomyActivity0235
			: 0;
		const netIncomes = (box0017 + box0235) / 14;

		return Math.round(netIncomes);
	};

	const renderState = (province) => {
		const communities = {
			Almería: '01',
			Cádiz: '01',
			Córdoba: '01',
			Granada: '01',
			Huelva: '01',
			Jaén: '01',
			Málaga: '01',
			Sevilla: '01',
			Huesca: '02',
			Teruel: '02',
			Zaragoza: '02',
			Asturias: '03',
			Baleares: '04',
			'Las Palmas': '05',
			'Santa Cruz de Tenerife': '05',
			Cantabria: '06',
			Albacete: '07',
			'Ciudad Real': '07',
			Cuenca: '07',
			Guadalajara: '07',
			Toledo: '07',
			Ávila: '08',
			Burgos: '08',
			León: '08',
			Palencia: '08',
			Salamanca: '08',
			Segovia: '08',
			Soria: '08',
			Valladolid: '08',
			Zamora: '08',
			Barcelona: '09',
			Girona: '09',
			Lleida: '09',
			Tarragona: '09',
			Ceuta: '18',
			Alicante: '10',
			Castellón: '10',
			Valencia: '10',
			Badajoz: '11',
			Cáceres: '11',
			'A Coruña': '12',
			Lugo: '12',
			Ourense: '12',
			Pontevedra: '12',
			Madrid: '13',
			Melilla: '19',
			Murcia: '14',
			Navarra: '15',
			Álava: '16',
			Guipúzcoa: '16',
			Vizcaya: '16',
			'La Rioja': '17'
		};
		return communities[province] || 'Seleccionar';
	};

	if (otherDatas && communities && otherDatasAll) {
		return (
			<>
				<Card style={{ background: '#F1F7F8' }}>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<BulbOutlined style={{ color: '#037FB8', fontSize: 20 }} />
						<Text style={{ color: '#037FB8', fontSize: 14, fontWeight: 600, marginLeft: 4 }}>
							¡Importante!
						</Text>
						<Text style={{ color: '#2F4858', fontSize: 14, fontWeight: 400, marginLeft: 4 }}>
							Estas son los productos que tienes disponibles para esta operación.
						</Text>
					</div>
					<Card style={{ marginTop: 24, textAlign: '-webkit-center' }}>{renderImages()}</Card>
				</Card>
				<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
					Datos de la oferta solicitada
				</Text>

				<Form
					form={form}
					layout="vertical"
					name="sendMessageForm"
					style={{ width: '100%' }}
					initialValues={{
						activity1:
							mortgage['owner1'].activity !== undefined
								? renderTypeWork(mortgage['owner1'].activity)
								: '',
						activity2:
							mortgage['owner2'] !== undefined ? renderTypeWork(mortgage['owner2'].activity) : '',
						netIncomes1: renderNetIconmes('owner1'),
						netIncomes2: renderNetIconmes('owner2'),
						mail1: mortgage['owner1'].email !== undefined ? mortgage['owner1'].email : '',
						mobilePhone1: mortgage['owner1'].phone !== undefined ? mortgage['owner1'].phone : '',
						mail2: mortgage['owner2'] !== undefined ? mortgage['owner2'].email : '',
						mobilePhone2: mortgage['owner2'] !== undefined ? mortgage['owner2'].phone : '',
						name1: mortgage['owner1'].name !== undefined ? mortgage['owner1'].name : '',
						name2: mortgage['owner2'] !== undefined ? mortgage['owner2'].name : '',
						birthdate1:
							mortgage['owner1'].birthdate !== undefined
								? moment(mortgage['owner1'].birthdate).format('DD-MM-YYYY')
								: '',
						birthdate2:
							mortgage['owner2'] !== undefined && mortgage['owner2'].birthdate !== undefined
								? moment(mortgage['owner2'].birthdate).format('DD-MM-YYYY')
								: '',
						nif1: mortgage['owner1'].nif !== undefined ? mortgage['owner1'].nif : '',
						nif2: mortgage['owner2'] !== undefined ? mortgage['owner2'].nif : '',
						tasationValue: valueAppraisal,
						payRollIncome1: payRollProrated1,
						payRollIncome2: payRollProrated2,
						amount:
							type.subtype === 'mortgage' ? bankRequest?.amount : mortgage?.subrogation?.amount,
						ccv:
							mortgage.subrogation !== undefined
								? mortgage.subrogation.maxBudget
								: mortgage.mortgage.maxBudget,
						term: bankRequest.months / 12,
						purposeHousing: mortgage.mortgage.houseType === 'second' ? 'SR' : 'VH',
						typeHousing: mortgage.mortgage.propertyType === 'newConstruction' ? 'VN' : 'VU',
						otherIncomes1: mortgage['owner1'].bonus,
						otherIncomes2: mortgage['owner2'] !== undefined ? mortgage['owner2'].bonus : 0,
						loanPayment1:
							(mortgage['owner1'].loanPayment ?? 0) + (mortgage['owner1'].mortgagePayment ?? 0),
						loanPayment2:
							mortgage['owner2'] !== undefined
								? (mortgage['owner2'].loanPayment ?? 0) + (mortgage['owner2'].mortgagePayment ?? 0)
								: 0,
						paymentPension1:
							mortgage['owner1'].pensionPayment !== undefined
								? mortgage['owner1'].pensionPayment
								: 0,
						paymentPension2:
							mortgage['owner2'] !== undefined ? mortgage['owner2'].pensionPayment : 0,
						subfinality: '01',
						residence1: mortgage['owner1'].cityzenship === 'ES' ? 'ES' : '99',
						residence2:
							mortgage['owner2'] !== undefined && mortgage['owner2'].cityzenship === 'ES'
								? 'ES'
								: '99',
						interestRate:
							bankRequest.rateType === 'FIXED'
								? 'F'
								: bankRequest.rateType === 'VARIABLE'
								? 'V'
								: bankRequest.rateType === 'MIXED'
								? 'M'
								: undefined,
						operationType: type.subtype === 'mortgage' ? '01' : '02',
						housingSituation1: '03',
						housingSituation2: '03',
						irpfIncomes1: mortgage['owner1']?.incomesWealth?.IRPF_0017,
						irpfIncomes2:
							mortgage['owner2'] !== undefined
								? mortgage['owner2']?.incomesWealth?.IRPF_0017
								: undefined,
						finality: '01',
						nifType1: 'N',
						nifType2: 'N',
						education1: '01',
						education2: '01',
						profession1: mortgage?.owner1?.activity === 'PENSIONER' ? '03' : '01',
						profession2:
							mortgage?.owner2 && mortgage?.owner2?.activity === 'PENSIONER' ? '03' : '01',
						firstSurname1:
							mortgage['owner1'].firstSurname !== undefined ? mortgage['owner1'].firstSurname : '',
						firstSurname2:
							mortgage['owner2'] !== undefined && mortgage['owner2'].firstSurname !== undefined
								? mortgage['owner2'].firstSurname
								: '',
						secondSurname1:
							mortgage['owner1'].lastSurname !== undefined ? mortgage['owner1'].lastSurname : '',
						secondSurname2:
							mortgage['owner2'] !== undefined && mortgage['owner2'].lastSurname !== undefined
								? mortgage['owner2'].lastSurname
								: '',
						professionalCategory1:
							mortgage['owner1'] !== undefined ? renderProfessionalCategory('owner1') : undefined,
						professionalCategory2:
							mortgage['owner2'] !== undefined ? renderProfessionalCategory('owner2') : undefined,
						annualBilling1: mortgage['owner1']?.incomesWealth
							?.sumOfNetReturnReducedEconomyActivity0235
							? mortgage['owner1']?.incomesWealth?.sumOfNetReturnReducedEconomyActivity0235
							: undefined,
						annualBilling2:
							mortgage['owner2'] !== undefined &&
							mortgage['owner2']?.incomesWealth?.sumOfNetReturnReducedEconomyActivity0235
								? mortgage['owner2']?.incomesWealth?.sumOfNetReturnReducedEconomyActivity0235
								: undefined,
						incomesRegion1:
							mortgage['owner1']?.province !== undefined
								? renderState(mortgage['owner1']?.province)
								: undefined,
						incomesRegion2:
							mortgage['owner2'] !== undefined && mortgage['owner2']?.province !== undefined
								? renderState(mortgage['owner2']?.province)
								: undefined
					}}
					onChange={handleFormChange}
					onFinish={onFinish}>
					<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'finality'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Finalidad'}
									</Text>
								}
								className={emptyFields.includes('finality') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.finality.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'subfinality'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Subfinalidad'}
									</Text>
								}
								className={emptyFields.includes('subfinality') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									showSearch
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.subfinality.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'interestRate'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de interés deseado'}
									</Text>
								}
								className={emptyFields.includes('interestRate') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.interestRate.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'operationType'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de operación'}
									</Text>
								}
								className={emptyFields.includes('operationType') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									onChange={(e) => {
										setOperationType(e);
										handleFormChange();
									}}
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.operationType.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'purposeHousing'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de residencia'}
									</Text>
								}
								className={emptyFields.includes('purposeHousing') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									name="new/second"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.purposeHousing.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'typeHousing'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de vivienda'}
									</Text>
								}
								className={emptyFields.includes('typeHousing') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatas.typeHousing.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'ccv'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Importe de la compra-venta'}
									</Text>
								}
								className={emptyFields.includes('ccv') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber min={1} style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'amount'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Importe solicitado
									</Text>
								}
								className={emptyFields.includes('amount') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber min={1} style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'term'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Plazo de la hipoteca (en años)'}
									</Text>
								}
								className={emptyFields.includes('term') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'location'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Municipio de la vivienda (${
											mortgage.subrogation !== undefined
												? mortgage.subrogation.population
												: mortgage.mortgage.population
										})`}
									</Text>
								}
								className={emptyFields.includes('location') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									onChange={handleFormChange}
									autoClearSearchValue={true}
									showSearch
									value={value !== '' ? value : ''}
									placeholder="Escribe para buscar..."
									defaultActiveFirstOption={false}
									showArrow={false}
									filterOption={false}
									onSearch={(value) => {
										handleSearch(value);
									}}
									notFoundContent={null}>
									{options}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'tasationValue'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Valor de tasación'}
									</Text>
								}
								className={emptyFields.includes('tasationValue') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					</Row>

					{renderForm()}

					<Row style={{ justifyContent: 'center', marginTop: 20 }}>
						<Button
							style={{ height: 32, width: 96, fontSize: 14 }}
							className="button-secundary-gibobs"
							onClick={() => cancelSend()}
							type="dashed">
							Atras
						</Button>

						<Button
							style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
							type="primary"
							htmlType="submit"
							className="button-primari-gibobs">
							Aceptar
						</Button>
					</Row>
				</Form>
				<Modal
					className="gb-modal-style-common"
					cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: 96 } }}
					okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96 } }}
					visible={visibleCancel}
					title={
						<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
							{'Cancelar envío'}
						</Text>
					}
					okText={'Aceptar'}
					cancelText={'Cancelar'}
					width={404}
					onCancel={handleCancel}
					onOk={handleOk2}>
					<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858' }}>
						{'¿Aceptas la cancelación de del envio a Santander?'}
					</Text>
				</Modal>
			</>
		);
	} else {
		return <></>;
	}
};
export default TableSantander;
