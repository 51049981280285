import React, { useEffect, useState } from 'react';
import { Col, Row, Typography, Card } from 'antd';
import numeral from 'numeral';
import axios from 'axios';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import { useParams } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

const { Text } = Typography;

const DataNewFormatMortgage = ({ mortgage, operationType, dataClient, isSabadell }) => {
	const [mortgageStatus, setMortgageStatus] = useState([]);
	const [dataBankBlock, setDataBankBlock] = useState([]);
	const params = useParams();
	const [bankCode, setBankCode] = useState(undefined);

	const typePropertyType = (a) => {
		if (a === 'newConstruction' || a === 'Obra Nueva') {
			return 'Obra nueva';
		} else if (a === 'secondHand' || a === 'Segunda Mano') {
			return 'Segunda mano';
		}
	};

	useEffect(() => {
		axios
			.get(`${env.api.url}/api/hadmin/mortgages/${params.mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setMortgageStatus(response.data);
			});
		getBankBloked();

		if (mortgage?.haveABank) {
			axios
				.get(`${env.api.url}/banks/banks/${mortgage?.haveABank}`, {
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				})
				.then((response) => {
					setBankCode(response.data.data);
				});
		}
	}, []);

	const typeHouseType = (a) => {
		if (a === 'main') {
			return 'Vivienda principal';
		} else if (a === 'second') {
			return 'Segunda vivienda';
		} else if (a === 'investor') {
			return 'Inversión';
		}
	};

	const changeHaveAInterest = (a) => {
		if (a === 'fixed' || a === 'FIXED') {
			return 'Fijo:';
		} else if (a === 'variable' || a === 'VARIABLE') {
			return 'Variable:';
		} else if (a === 'mixed') {
			return 'Mixto:';
		}
	};

	const nameLinkages = {
		lifeInsurance: 'Seguro de vida',
		homeInsurance: 'Seguro del hogar',
		pensionPlan: 'Plan de pensiones',
		creditCards: 'Tarjetas de crédito/débito',
		carInsurance: 'Seguro del coche',
		unemploymentInsurance: 'Seguro de desempleo',
		receiptsDomiciliation: 'Domiciliación de recibos',
		payrollDomiciliation: 'Domiciliación de nómina'
	};

	const formatData = (title, data) => {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: title !== 'Proyecto que le interesa:' && 'center'
				}}>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#2F4858',
						marginRight: '4px'
					}}>
					{title}
				</Text>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#02C3CD'
					}}>
					{data}
				</Text>
			</div>
		);
	};
	const formatOffert = (data) => {
		return (
			<div>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#02C3CD'
					}}>
					{data}
				</Text>
			</div>
		);
	};

	const titleName = {
		lessThanSixMonths: 'En menos de 6 meses',
		withinSixOrTwelveMonths: 'Dentro de 6-12 meses',
		whitinOneOrTwoYears: 'Dentro de 1 o 2 años'
	};

	const selectImagebank = (bankCode) => {
		return (
			<div style={{ width: 120, height: 32 }}>
				<img
					src={
						'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/images/banks/' +
						`${bankCode}` +
						'.png'
					}
					alt={bankCode}
					style={{ height: '100%', width: '100%' }}
				/>
			</div>
		);
	};

	const getBankBloked = (bank) => {
		axios
			.get(`${env.api.url}/banks/operation-blocked-bank/by-operation/${params.mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setDataBankBlock(response.data.data);
			});
	};

	return (
		<>
			<Row gutter={24}>
				<Col span={12}>
					<Card
						style={{
							border: '1px solid #C0DAEE',
							borderRadius: '4px',
							backgroundColor: 'white',
							height: '100%'
						}}>
						<Row gutter={24}>
							<img
								src="/images/foto.png"
								height="100%"
								width="20%"
								style={{ position: 'absolute', left: 0, top: 0 }}
							/>
							<Col lg={4} xs={4}></Col>
							<Col lg={20} xs={20} style={{ paddingRight: 0 }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										marginRight: 16,
										marginLeft: 24
									}}>
									<div style={{ width: '100%' }}>
										<div
											style={{
												marginBottom: '16px',
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}>
											<Text
												style={{
													fontSize: '14px',
													fontWeight: '600',
													color: '#2F4858'
												}}>
												Datos de la propiedad
											</Text>
											{isSabadell && (
												<a href={`/operations/mortgages/${params.mortgageId}/recommender-data`}>
													<Text
														style={{
															color: '#006DFF',
															fontSize: 12,
															fontWeight: 500,
															display: 'flex',
															alignItems: 'center',
															cursor: 'pointer'
														}}>
														Datos para recomendador{' '}
														<EyeOutlined style={{ marginLeft: 4, fontSize: 14 }} />
													</Text>
												</a>
											)}
										</div>
										{formatData(
											'Ubicación:',
											mortgage && mortgage.province ? mortgage.province : '-'
										)}
										{formatData(
											'Tipo:',
											mortgage && mortgage.propertyType
												? typePropertyType(mortgage.propertyType)
												: '-'
										)}
										{formatData(
											'Uso previsto:',
											mortgage && mortgage.houseType ? typeHouseType(mortgage.houseType) : '-'
										)}
										<div>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858',
													marginRight: '4px'
												}}>
												{mortgage?.houseState === 'searching' ? 'Techo:' : 'Precio:'}
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD'
												}}>
												{mortgage?.houseState !== 'searching' &&
													mortgage?.maxBudget &&
													numeral(mortgage.maxBudget).format('0,0 $')}
												{mortgage &&
													!mortgage.viabilityData &&
													mortgage?.houseState === 'searching' &&
													'Precio no calculado'}
												{mortgage &&
													mortgage.houseState === 'searching' &&
													mortgage.viabilityData &&
													mortgage.viabilityData.operation &&
													mortgage.viabilityData.operation.property &&
													numeral(mortgage.viabilityData.operation.property).format('0,0 $')}
											</Text>
										</div>
										{formatData(
											'Ahorros disponibles:',
											mortgage && numeral(mortgage.savings).format('0,0 $')
										)}
										{formatData(
											'Previsión de compra:',
											mortgage && mortgage.purchaseForecast && titleName[mortgage.purchaseForecast]
										)}
										{mortgage?.operationType === 'green-mortgage' ? (
											formatData(
												'Presupuesto verde:',
												mortgage && numeral(mortgage.ecoBudget).format('0,0 $')
											)
										) : (
											<div>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '500',
														color: '#2F4858',
														marginRight: '4px'
													}}></Text>
												<Text
													style={{
														fontSize: '12px',
														fontWeight: '500',
														color: '#02C3CD'
													}}></Text>
											</div>
										)}
									</div>
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col
					span={12}
					style={{
						marginTop: operationType === 'green-mortgage' && 24
					}}>
					<Card
						style={{
							border: '1px solid #C0DAEE',
							borderRadius: '4px',
							backgroundColor: 'white',
							height: '100%'
						}}>
						<Row gutter={24}>
							<Col lg={21}>
								<div style={{ marginBottom: '16px' }}>
									<Text
										style={{
											fontSize: '14px',
											fontWeight: '600',
											color: '#2F4858'
										}}>
										Oferta hipotecaria
									</Text>
								</div>
							</Col>
						</Row>
						{dataClient && mortgage && bankCode && (
							<Row gutter={24} style={{ marginBottom: 16 }}>
								<Col lg={6}>{selectImagebank(bankCode.bankCode)}</Col>
								{formatOffert(
									mortgage?.haveAInterest ? changeHaveAInterest(mortgage?.haveAInterest) : '-'
								)}

								{formatOffert(
									mortgage?.haveATin !== undefined
										? `${parseFloat(mortgage?.haveATin).toFixed(2)}%`
										: '-'
								)}
							</Row>
						)}

						{dataClient === false &&
							dataBankBlock?.map((data) => {
								return (
									<Row key={data.id} gutter={24} style={{ marginBottom: 16 }}>
										<Col lg={6}>
											{data?.bank?.bankCode ? (
												selectImagebank(data?.bank?.bankCode)
											) : (
												<Text
													style={{
														fontSize: 14,
														fontWeight: 500,
														color: '#2F4858'
													}}>
													{data?.bank?.name}
												</Text>
											)}
										</Col>
										{data?.rateType === 'fixed' && (
											<Col lg={18}>
												<div style={{ display: 'flex', columnGap: 4 }}>
													{formatOffert(data?.rateType ? changeHaveAInterest(data?.rateType) : '-')}
													{formatOffert(
														data?.rate !== undefined ? `${parseFloat(data?.rate).toFixed(2)}%` : '-'
													)}
												</div>
												{data?.linkages ? (
													<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 4 }}>
														{Object.keys(data?.linkages)?.map(
															(linkages) =>
																data.linkages[linkages] && formatOffert(nameLinkages[linkages])
														)}
													</div>
												) : (
													'-'
												)}
											</Col>
										)}
										{data?.rateType !== 'fixed' && (
											<Col lg={18}>
												<div style={{ display: 'flex', columnGap: 4 }}>
													{formatOffert(data?.rateType ? changeHaveAInterest(data?.rateType) : '-')}
													{formatOffert(
														data?.stages?.[0]?.from ? `${data?.stages?.[0]?.from / 12} años` : '-'
													)}
													{formatOffert(
														data?.stages?.[0]?.tin
															? `${parseFloat(data?.stages?.[0]?.tin).toFixed(2)}%`
															: '-'
													)}{' '}
													{formatOffert('/ Resto: Eur +')}
													{formatOffert(
														data?.stages?.[1]?.tin
															? `${parseFloat(data?.stages?.[1]?.tin).toFixed(2)}%`
															: '-'
													)}
												</div>
												{data?.linkages ? (
													<div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 4 }}>
														{Object.keys(data?.linkages)?.map(
															(linkages) =>
																data.linkages[linkages] && formatOffert(nameLinkages[linkages])
														)}
													</div>
												) : (
													'-'
												)}
											</Col>
										)}
									</Row>
								);
							})}
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default DataNewFormatMortgage;
