import React, { useState, useEffect, useRef } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Input,
	Form,
	Select,
	InputNumber,
	Tooltip,
	Spin,
	Modal,
	Radio
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import CriteriaBanks from './CriteriaBanks';

const { Text } = Typography;
const { Option } = Select;

const FormPageSendBank = ({
	showModalEmail,
	mortgage,
	type,
	setBankRequestNow,
	setBankRequestData
}) => {
	const [form] = Form.useForm();
	const [dataBanksOnGoing, setDataBanksOnGoing] = useState([]);
	const [bankName, setBankName] = useState(undefined);
	const navigate = useNavigate();
	const param = useParams();
	const [santander, setSantander] = useState(undefined);
	const [quote, setQuote] = useState(1);
	const [savings, setSavings] = useState(0);
	const [years, setYears] = useState(30);
	const [mortgageAmount, setMortgageAmount] = useState(
		mortgage?.propertyWishPurchase !== undefined
			? mortgage?.propertyWishPurchase?.mortgageAmount
			: mortgage.mortgage.maxBudget +
					2000 +
					0.1 * mortgage.mortgage.maxBudget -
					mortgage.mortgage.savings
	);
	const [ltv, setLtv] = useState(0);
	const [deb, setDeb] = useState(0);
	const [tin, setTin] = useState(0);
	const [blockedBanks, setBlockedBanks] = useState([]);
	const [taxes, setTaxes] = useState(undefined);
	const timer = useRef(null);
	const [userModificatedTaxes, setUserModificatedTaxes] = useState(false);
	const [mortgageData, setMortgageData] = useState(undefined);
	const [textDescription, setTextDescription] = useState([]);
	const [bankId, setBankId] = useState(undefined);
	const [desiredInterest, setDesiredInterest] = useState(undefined);
	const [fieldsChanged, setFieldsChanged] = useState(false); //cuando cambian ahorros, años o impuestos
	const [modalOneBank, setModalOneBank] = useState(false);
	const [reponseExecute, setReponseExecute] = useState(undefined);
	const [valueRadio, setValueRadio] = useState(undefined);

	useEffect(() => {
		getDataAllMortgage();
		getDataMortgage();
		getBlockedBanks();
	}, []);

	useEffect(() => {
		if (mortgageData) {
			getBanksOnGoing();
			if (mortgageData.managementCode === 'sabadell-es') {
				handleChangeBank('a021t000009OgpJAAS');
			}
		}
	}, [mortgageData]);

	useEffect(() => {
		if (timer.current) {
			clearTimeout(timer.current);
		}

		if (type !== 'subrogation' && type !== 'green-subrogation') {
			timer.current = setTimeout(getFinacialCalculateRates, 500);
		}
	}, [savings, years, taxes]);

	const getDataAllMortgage = () => {
		axios
			.get(`${env.api.url}/api/hadmin/mortgages/${param.mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setMortgageData(response.data);
			});
	};

	const changeTaxex = (value) => {
		sendDataTaxes(value);
		setUserModificatedTaxes(true);
	};

	const getBlockedBanks = (bank) => {
		axios
			.get(`${env.api.url}/banks/operation-blocked-bank/by-operation/${param.mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				if (!bank) {
					setBlockedBanks(response.data.data);
				}
			});
	};

	const getDataMortgage = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${param.mortgageId}`, {
				headers: {
					Authorization: `Bearer ${authClient.getToken()}`
				}
			})
			.then((response) => {
				const age = _.get(response.data.data, 'owner1.age', 30);
				const years = 75 - age < 30 ? 75 - age : 30;
				setSavings(_.get(response.data.data, 'mortgage.savings', 0));
				if (response.data.data?.desiredOffer?.term) {
					setYears(response.data.data.desiredOffer.term / 12);
				} else {
					setYears(years);
				}
				if (response.data.data?.desiredOffer?.interest) {
					setDesiredInterest(response.data.data.desiredOffer.interest);
				}
				form.resetFields();
			});
	};

	const getBanksOnGoing = async () => {
		const response = await axios.get(
			`${env.api.url}/v1/banks/get-banks?status=ongoing&managementCode=${mortgageData.managementCode}`,
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);

		await setDataBanksOnGoing(response.data.data);
	};

	const handleChangeBank = (value) => {
		setBankName(value);
		setSantander(value);
		setBankId(value);

		//evo ibercaja
		if (value !== 'a021t00000KGFCuAAP' && value !== 'a021t000009Oj00AAC') {
			setTextDescription([]);
		}
	};

	const handleOk = () => {
		const key = 'typesend';
		const participant = 'process';
		const value = valueRadio;
		axios
			.post(
				`${env.api.url}/banks/bank-request/update-custom-request/${reponseExecute.id}`,
				{
					sendingType: valueRadio
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				axios
					.post(
						`${env.api.url}/v1/mortgages/data/update/${param.mortgageId}`,
						{ key, participant, value },
						{
							headers: { Authorization: `Bearer ${authClient.getToken()}` }
						}
					)
					.then(() => {
						setModalOneBank(false);

						navigate(`/operations/mortgages/${param.mortgageId}/view-document-list`, {
							state: {
								bankId: bankId,
								bankRequestId: reponseExecute.id,
								bankRequest: reponseExecute
							}
						});
					});
			})
			.catch(() => {
				setModalOneBank(false);
			});
	};

	const handleChangeRadio = (value) => {
		setValueRadio(value.target.value);
	};

	const onFinish = (values) => {
		if (values.bank === 'a021t000009Oj00AAC' && type === 'subrogation') {
			//ibercaja
			axios
				.post(
					`${env.api.url}/v1/mortgages/data/update/${param.mortgageId}`,
					{ key: 'termYears', participant: 'subrogation', value: years },
					{
						headers: { Authorization: `Bearer ${authClient.getToken()}` }
					}
				)
				.then((response) => {});
		}

		axios
			.post(
				`${env.api.url}/banks/bank-request/create`,
				{
					bankId: values.bank,
					operationId: param.mortgageId,
					amount: type === 'mortgage' || type === 'green-mortgage' ? undefined : values.amount,
					months: type === 'mortgage' || type === 'green-mortgage' ? undefined : values.years * 12
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setBankRequestNow(response.data.data.id);
				setBankRequestData(response.data.data);
				if (response.data.data) {
					axios
						.post(
							`${env.api.url}/banks/bank-request/update/${response.data.data.id}`,
							{
								savings: parseInt(values.savings),
								months: values.years * 12,
								hasGuarantor: values.guarantor,
								loanToValue: Number(values.ltv),
								indebtednessRate: Number(values.deb),
								amount: values.amount,
								quote: values.quote,
								differential: tin,
								rateType: values.rateType,
								termYears: 1
							},
							{
								headers: { Authorization: `Bearer ${authClient.getToken()}` }
							}
						)
						.then((response) => {
							if (
								response.data.data &&
								(values.bank === 'a026N000000whFmQAI' ||
									values.bank === '62f3c54000ad9f2a000b81e030') //62f3c3ea019d9001c015cee495
							) {
								navigate(`/operations/mortgages/${param.mortgageId}/table-caixaBank`, {
									state: {
										bankId: bankId,
										bankRequestId: response.data.data.id,
										bankRequest: response.data.data
									}
								});
							} else if (response.data.data && values.bank === 'a026N000000whLuQAI') {
								navigate(`/operations/mortgages/${param.mortgageId}/table-liberBank`, {
									state: {
										bankId: bankId,
										bankRequestId: response.data.data.id,
										bankRequest: response.data.data
									}
								});
							} else if (response.data.data && values.bank === 'a021t00000KGFCuAAP') {
								navigate(`/operations/mortgages/${param.mortgageId}/view-document-list`, {
									state: {
										bankId: bankId,
										bankRequestId: response.data.data.id,
										bankRequest: response.data.data,
										mortgageAmount: mortgageAmount
									}
								});
							} else {
								if (
									values.bank === 'a021t000009OgpJAAS' &&
									mortgageData.managementCode === 'sabadell-es'
								) {
									setModalOneBank(true);
									setReponseExecute(response.data.data);
								} else {
									navigate(`/operations/mortgages/${param.mortgageId}/view-document-list`, {
										state: {
											bankId: bankId,
											bankRequestId: response.data.data.id,
											bankRequest: response.data.data
										}
									});
								}
							}
						});
				}
			});
	};

	const sendDataSavings = (value) => {
		setSavings(value.target.value);
		setFieldsChanged(true);
	};

	const sendDataYears = (term) => {
		setYears(term.target.value);
		setFieldsChanged(true);
	};

	const sendDataTaxes = (value) => {
		if (value === null) {
			value = 0;
		}
		setTaxes(parseFloat(value).toFixed(2));
		setFieldsChanged(true);
	};

	const getFinacialCalculateRates = () => {
		axios
			.post(
				`${env.api.url}/mortgages/calculate-ltv-dti`,
				{
					taxes,
					savings: parseFloat(savings),
					term: years * 12,
					operationId: param.mortgageId
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				const { ltv, indebtedness, mortgageAmount, quota, spread, taxes } = response.data.data;
				if (fieldsChanged) {
					setMortgageAmount(mortgageAmount);
				}
				setLtv(parseFloat(ltv * 100).toFixed(2));
				setDeb(parseFloat(indebtedness * 100).toFixed(2));
				setQuote(quota);
				setTin(spread * 100);
				if (!userModificatedTaxes) {
					setTaxes(taxes);
					setUserModificatedTaxes(true);
				}
				form.resetFields(['amount', 'ltv', 'quote', 'deb', 'tin', 'taxes']);
			});
	};

	const banksToList = dataBanksOnGoing?.filter(
		(bankOG) =>
			blockedBanks.find((bBank) => bBank.bank.id === bankOG.id) === undefined &&
			bankOG.bankCode !== 'targobank'
	);

	const renderYears = () => {
		const end = moment(mortgage.subrogation.dateEndMortgage);
		const now = moment();
		const years = end.diff(now, 'years');

		return years;
	};

	const calculateSavingsNeeded = (percent) => {
		const minSavings = taxes + 2300 + (1 - percent) * mortgageData.maxBudget;
		if (minSavings && !isNaN(minSavings) && minSavings > 0) {
			return <span>{numeral(minSavings).format('0,0 $')}</span>;
		}
	};

	return (
		<>
			<Row>
				<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginBottom: 24 }}>
					Enviar nueva operación a banco
				</Text>
			</Row>
			{mortgageData ? (
				<Form
					form={form}
					layout="vertical"
					name="sendMessageForm"
					style={{ width: '100%', marginTop: -10 }}
					initialValues={{
						amount:
							type === 'mortgage' || type === 'green-mortgage'
								? mortgageAmount
								: mortgage.subrogation.amount,
						quote: quote,
						ltv: ltv,
						deb: deb,
						savings: savings,
						taxes: taxes,
						years: type === 'subrogation' ? renderYears(mortgage) : years,
						guarantor: mortgage.mortgage.guarantors != 0 ? true : false,
						maxBudget: mortgageData.maxBudget,
						yearsSub:
							type === 'subrogation' || type === 'green-subrogation'
								? mortgage.subrogation.subrogationSigned
								: undefined,
						bank: mortgageData.managementCode === 'sabadell-es' && 'a021t000009OgpJAAS'
					}}
					onFinish={onFinish}>
					<Row gutter={[32, 24]}>
						<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
							<Form.Item
								name="bank"
								rules={[{ required: true, message: 'Please add a bank' }]}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'Banco'}</Text>
								}>
								<Select
									showSearch
									filterOption={(input, option) =>
										option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.title.toLowerCase().localeCompare(optionB.title.toLowerCase())
									}
									style={{ height: 32 }}
									placeholder="Seleccione"
									onChange={(value) => handleChangeBank(value)}>
									{banksToList &&
										banksToList.map((banks) => (
											<Option key={banks.id} value={banks.id} title={banks.largeName}>
												{banks.largeName}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					{type && (type === 'mortgage' || type === 'green-mortgage') && (
						<>
							<Row style={{ marginTop: 0 }}>
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<div>
										<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
											{'Ahorros disponibles: '}
										</Text>
										<Text style={{ color: '#02C3CD', fontSize: 12, fontWeight: 500 }}>
											{numeral(mortgage.mortgage.savings).format('0,0 $')}
										</Text>
									</div>
								</Col>
							</Row>

							<Row gutter={[32, 24]} style={{ marginTop: 5 }}>
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="savings"
										label={
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
													Ahorros aportados
												</Text>
												<Tooltip
													placement="top"
													title={
														<div style={{ textAlign: 'center' }}>
															Ahorro necesario para... <br />
															Al 70% - {calculateSavingsNeeded(0.7)} <br />
															Al 80% - {calculateSavingsNeeded(0.8)} <br />
															Al 85% - {calculateSavingsNeeded(0.85)}
														</div>
													}>
													<InfoCircleOutlined
														style={{ fontSize: 16, marginLeft: 4, cursor: 'pointer' }}
													/>
												</Tooltip>
											</div>
										}>
										<Input
											style={{ width: '100%' }}
											onChange={(value) => sendDataSavings(value)}></Input>
									</Form.Item>
								</Col>
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="years"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Plazo en años'}
											</Text>
										}>
										<Input
											style={{ width: '100%' }}
											onChange={(value) => sendDataYears(value)}></Input>
									</Form.Item>
								</Col>

								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="rateType"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Tipo de interés que desea el cliente'}
											</Text>
										}>
										<Select defaultValue={desiredInterest || 'FIXED_OR_VARIABLE'}>
											<Option value={'FIXED'}>Fijo</Option>
											<Option value={'VARIABLE'}>Variable</Option>
											<Option value={'MIXED'}>Mixto</Option>
											<Option value={'FIXED_OR_VARIABLE'}>No lo sabe</Option>
										</Select>
									</Form.Item>
								</Col>
								{mortgage.mortgage.guarantors !== 0 &&
									mortgage.mortgage.guarantors !== undefined && (
										<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6} style={{ marginTop: -32 }}>
											<div>
												<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
													{' Número de avalistas: '}
												</Text>
												<Text style={{ color: '#02C3CD', fontSize: 12, fontWeight: 500 }}>
													{mortgage.mortgage.guarantors}
												</Text>
											</div>

											<Form.Item
												name="guarantor"
												style={{ marginTop: 10 }}
												label={
													<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
														{'Incluir avalista'}
													</Text>
												}>
												<Select>
													<Option value={true}>Si</Option>
													<Option value={false}>No</Option>
												</Select>
											</Form.Item>
										</Col>
									)}
							</Row>

							<Row gutter={[32, 24]} style={{ marginTop: -8 }}>
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="amount"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Importe solicitado'}
											</Text>
										}>
										<InputNumber
											formatter={(value) => numeral(Number(value)).format('0,0 $')}
											parser={(value) => numeral(value).value()}
											style={{ width: '100%' }}
											disabled={true}></InputNumber>
									</Form.Item>
								</Col>
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="taxes"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Impuestos a pagar'}
											</Text>
										}>
										<InputNumber
											formatter={(value) => {
												return numeral(Number(value)).format('0,0 $');
											}}
											parser={(value) => numeral(value).value()}
											onChange={(value) => changeTaxex(value)}
											style={{ width: '100%' }}></InputNumber>
									</Form.Item>
								</Col>
								{quote && (
									<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
										<Form.Item
											name="quote"
											label={
												<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
													{`Cuota estimada gibobs (TIN: ${tin} %)`}
												</Text>
											}>
											<InputNumber
												formatter={(value) => numeral(value).format('0,0 $')}
												parser={(value) => numeral(value).value()}
												style={{ width: '100%' }}
												disabled={true}
												value={numeral(parseFloat(quote)).format('0,0.00 $')}
												defaultValue={numeral(parseFloat(quote)).format('0,0.00 $')}></InputNumber>
										</Form.Item>
									</Col>
								)}
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="maxBudget"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Precio de la propiedad'}
											</Text>
										}>
										<InputNumber
											formatter={(value) => numeral(value).format('0,0 $')}
											parser={(value) => numeral(value).value()}
											style={{ width: '100%' }}
											disabled={true}
										/>
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={[32, 24]} style={{ marginTop: -8 }}>
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="ltv"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Ratio de financiación'}
											</Text>
										}>
										<InputNumber
											formatter={(value) => `${value}%`}
											style={{ width: '100%' }}
											disabled={true}></InputNumber>
									</Form.Item>
								</Col>
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="deb"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Ratio de endeudamiento'}
											</Text>
										}>
										<InputNumber
											formatter={(value) => `${value}%`}
											style={{ width: '100%' }}
											disabled={true}></InputNumber>
									</Form.Item>
								</Col>
							</Row>
						</>
					)}

					{type && (type === 'subrogation' || type === 'green-subrogation') && (
						<>
							<Row gutter={[32, 24]} style={{ marginTop: -8 }}>
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="amount"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Importe pendiente'}
											</Text>
										}>
										<InputNumber
											style={{ width: '100%' }}
											formatter={(value) => numeral(value).format('0,0 $')}
											parser={(value) => numeral(value).value()}></InputNumber>
									</Form.Item>
								</Col>
								<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6}>
									<Form.Item
										name="years"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Plazo en años pendiente'}
											</Text>
										}>
										<Input
											style={{ width: '100%' }}
											onChange={(value) => sendDataYears(value)}></Input>
									</Form.Item>
								</Col>
								{mortgage.mortgage.guarantors !== 0 &&
									mortgage.mortgage.guarantors !== undefined && (
										<Col xs={24} md={6} sm={10} lg={6} xl={6} xxl={6} style={{ marginTop: -32 }}>
											<div>
												<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 500 }}>
													{' Número de avalistas: '}
												</Text>
												<Text style={{ color: '#02C3CD', fontSize: 12, fontWeight: 500 }}>
													{mortgage.mortgage.guarantors}
												</Text>
											</div>

											<Form.Item
												name="guarantor"
												style={{ marginTop: 10 }}
												label={
													<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
														{'Incluir avalista'}
													</Text>
												}>
												<Select>
													<Option value={true}>Si</Option>
													<Option value={false}>No</Option>
												</Select>
											</Form.Item>
										</Col>
									)}
							</Row>
						</>
					)}

					<Row style={{ justifyContent: 'center', marginTop: 20 }}>
						<Button
							style={{ height: 32, width: 96, fontSize: 14 }}
							className="button-secundary-gibobs"
							onClick={() => navigate(`/operations/mortgages/${param.mortgageId}/?tab=5`)}
							type="dashed">
							Cancelar
						</Button>

						<Button
							style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
							type="primary"
							htmlType="submit"
							className="button-primari-gibobs">
							Siguiente
						</Button>
					</Row>
				</Form>
			) : (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Spin />
				</div>
			)}

			{bankName && (
				<CriteriaBanks
					mortgage={mortgage}
					ltv={ltv}
					deb={deb}
					type={type}
					value={bankName}
					mortgageAmount={mortgageAmount}
					years={type === 'subrogation' ? renderYears(mortgage) : years}
					setBankName={setBankName}
					setTextDescription={setTextDescription}></CriteriaBanks>
			)}

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: '49%', height: 40 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { width: '49%', height: 40 } }}
				open={modalOneBank}
				title={
					<Text style={{ fontSize: '16px', fontWeight: '600px', color: '#2F4858' }}>
						{'Tipo de envío'}
					</Text>
				}
				okText={'Siguiente'}
				cancelText={'Cancelar'}
				width={520}
				onCancel={() => setModalOneBank(false)}
				onOk={handleOk}>
				<div style={{ marginBottom: 32, marginTop: -10 }}>
					<Text style={{ fontSize: '14px', fontWeight: 400, color: '#748EA0' }}>
						{'Antes de avanzar, indica por qué medio quieres realizar el envío.'}
					</Text>
					<Radio.Group
						onChange={(e) => handleChangeRadio(e)}
						style={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: 16 }}>
						<Radio value={'recommender'}>
							<Text
								style={{
									color: valueRadio === 'recommender' ? '#2F4858' : '#748EA0',
									fontSize: 14,
									fontWeight: 400
								}}>
								Prescoring (envío intrno para subir pre-oferta)
							</Text>
						</Radio>
						<Radio value={'reactive'} style={{ marginTop: 16 }}>
							<Text
								style={{
									color: valueRadio === 'reactive' ? '#2F4858' : '#748EA0',
									fontSize: 14,
									fontWeight: 400
								}}>
								Scoring reactivo (envío al equipo de VDs)
							</Text>
						</Radio>
					</Radio.Group>
				</div>
			</Modal>
		</>
	);
};
export default FormPageSendBank;
