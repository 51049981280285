import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Modal,
	Form,
	Select,
	InputNumber,
	message,
	Input,
	Spin,
	notification
} from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import { InputDate } from 'afrodita';

const { Text } = Typography;
const { Option } = Select;

const TableCaixaBankApi = ({ mortgageId, showModalEmail, mortgage, type }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const idBank = location && location.state !== undefined ? location.state.bankId : undefined;
	const bankRequestId =
		location && location.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest =
		location && location.state !== undefined ? location.state.bankRequest : undefined;
	const ageT1 = moment().diff(mortgage['owner1'].birthdate, 'years');
	const ageT2 =
		mortgage['owner2'] !== undefined ? moment().diff(mortgage['owner2'].birthdate, 'years') : 0;
	const ageMax = ageT1 >= ageT2 ? ageT1 : ageT2;
	const t1Loan = _.get(mortgage, 'owner1.loanPayment', 0);
	const t1MortgagePay = _.get(mortgage, 'owner1.mortgagePayment', 0);
	const t1Pension = _.get(mortgage, 'owner1.pensionPayment', 0);
	const t2MortgagePay = _.get(mortgage, 'owner2.loanPayment', 0);
	const t2Loan = _.get(mortgage, 'owner2.mortgagePayment', 0);
	const t2Pension = _.get(mortgage, 'owner2.pensionPayment', 0);
	const totalPay = t1Loan + t1MortgagePay + t1Pension + t2MortgagePay + t2Loan + t2Pension;
	const [visibleCancel, setVisibleCancel] = useState(false);
	const [dataCaixa, setDataCaixa] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const states = {
		Andalucía: 'Andalucía',
		Aragón: 'Aragón',
		Asturias: 'Asturias',
		Baleares: 'Baleares',
		Canarias: 'Canarias',
		Cantabria: 'Cantabria',
		CastillaLaMancha: 'Castilla La Mancha',
		CastillaLeón: 'Castilla y León',
		Cataluña: 'Cataluña',
		Ceuta: 'Ceuta',
		Valenciana: 'Valenciana',
		Extremadura: 'Extremadura',
		Galicia: 'Galicia',
		Madrid: 'Madrid',
		Melilla: 'Melilla',
		Murcia: 'Murcia',
		Navarra: 'Navarra',
		PaísVasco: 'País Vasco',
		'La Rioja': 'La Rioja'
	};

	const activityTypesToCaixaTypes = [
		{ code: '01', name: 'EMPLOYEE_FIXED', label: 'Empleado indefinido' },
		{ code: '02', name: 'EMPLOYEE_TEMPORAL', label: 'Empleado temporal' },
		{ code: '03', name: 'FREELANCER', label: 'Autónomo' },
		{ code: '05', name: 'UNEMPLOYED', label: 'Desempleado' },
		{ code: '06', name: 'FUNCTIONARY', label: 'Funcionario' },
		{ code: '07', name: 'UNKNOW', label: 'Desconocido' },
		{ code: '07', name: 'PENSIONER', label: 'Jubilado' },
		{ code: '07', name: 'BUSINESS_OWNER', label: 'Empresario' },
		{ code: '07', name: 'RENTIER', label: 'Rentista' },
		{ code: '06', name: 'FUNCTIONARY_INTERIM', label: 'Funcionario interino' }
	];

	useEffect(() => {
		axios
			.get(`${env.api.url}/banks/caixa/data`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setDataCaixa(response.data.data);
			});
	}, []);

	const renderForm = () => {
		const arrayform = [];

		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			arrayform.push(
				<>
					<Text>{'Titular ' + index}</Text>
					<Row gutter={[24, 24]}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`name${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>Nombre</Text>
								}
								rules={[
									{
										required: true,
										validator: (e, value, callback) => {
											return new Promise((resolve, reject) => {
												if (value.length > 20) {
													reject('debe ser menor o igual a 20 caracteres');
												} else {
													resolve();
												}
											});
										}
									}
								]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>

						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`lastName${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>Apellido</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`birthdate${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Fecha de nacimiento
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<InputDate style={{ width: '100%', borderRadius: 4 }} type="date" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`type${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Tipo de documento
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa.documentIdType.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`customerId${index}`}
								label={<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>DNI</Text>}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`issuingCountry${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>País</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa.countries.map((data) => (
										<Option key={data.isoCode3} value={data.isoCode3}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`prefix${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Prefijo tlf
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>

						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`number${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Número tlf
									</Text>
								}
								rules={[
									{ required: true, message: 'Please add a subject' },
									({ getFieldValue }) => ({
										validator(_, value) {
											if (value && value.toString().length > 9) {
												return Promise.reject(new Error('The phone number cannot exceed 9 digits'));
											}
											return Promise.resolve();
										}
									})
								]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`address${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>Email</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`annualIncomes${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Ingresos anuales
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`contractType${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Tipo de contrato
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa?.employmentContract.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`currentBalance${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Deuda total
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`installment${index}`}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Deuda mensual
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					</Row>
				</>
			);
		}

		return arrayform;
	};

	const onFinish = (values) => {
		setLoading(true);
		const owners = [];
		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			owners.push({
				name: values[`${'name' + index}`],
				lastName: values[`${'lastName' + index}`],
				birthdate: moment(values[`${'birthdate' + index}`], 'DD-MM-YYYY').format('YYYY-MM-DD'),
				documentId: {
					type: values[`${'type' + index}`],
					customerId: values[`${'customerId' + index}`],
					issuingCountry: values[`${'issuingCountry' + index}`]
				},
				contact: {
					phone: [
						{
							prefix: values[`${'prefix' + index}`],
							number: parseInt(values[`${'number' + index}`])
						}
					],
					email: [
						{
							address: values[`${'address' + index}`]
						}
					]
				},
				incomes: {
					annualIncomes: {
						ccy: 'EUR',
						amount: values[`${'annualIncomes' + index}`]
					},
					contractType: values[`${'contractType' + index}`]
				},
				debts: [
					{
						currentBalance: {
							ccy: 'EUR',
							amount: values[`${'currentBalance' + index}`]
						},
						installment: {
							ccy: 'EUR',
							amount: values[`${'installment' + index}`]
						}
					}
				],
				residenceCountryCode: 'ESP',
				requester: index === 1 ? true : false
			});
		}

		const dataMortgage = {
			mortgage: {
				operationType: values.operationType,
				economicData: {
					buyingPrice: {
						ccy: 'EUR',
						amount: values.buyingPrice
					},
					loanAmount: {
						ccy: 'EUR',
						amount: values.loanAmount
					},
					savingsAmount: {
						ccy: 'EUR',
						amount: values.savingsAmount
					}
				},
				property: {
					usage: values.usage,
					type: values.type,
					address: {
						//countryCode: 'ESP', //poner como select
						postCode: values.postCode.toString(),
						//municipalityCode: values.municipalityCode.toString(),
						provinceCode: values.provinceCode,
						autonomousCommunityCode: values.autonomousCommunityCode,
						streetType: values.streetType,
						streetName: values.streetName,
						buildingNumber: values.buildingNumber,
						stairNumber: values.stairNumber,
						floorNumber: values.floorNumber,
						apartmentNumber: values.apartmentNumber
					}
				},
				loanTerm: values.loanTerm,
				mortgagors: owners
			}
		};

		axios
			.post(
				`${env.api.url}/banks/caixa/execute-process`,
				{
					bankRequestId: bankRequestId,
					mortgage: dataMortgage.mortgage
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setLoading(false);
				notification.success({
					message: 'Se ha generado correctamente el bank Resquest.',
					description: 'La bank Resquest se genero correctamente'
				});
				navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
			})
			.catch((error) => {
				setLoading(false);
				notification.error({
					message: 'Error',
					description: 'Algo fue mal'
				});
			});
	};

	const cancelSend = () => {
		setVisibleCancel(!visibleCancel);
	};
	const handleOk = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/send-to-bank`, {
			state: { bankId: idBank, bankRequestId: bankRequestId, bankRequest: bankRequest }
		});
	};

	const handleOk2 = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};
	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}

	return (
		<>
			<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
				Datos de la operación
			</Text>

			{dataCaixa && mortgage && (
				<Form
					form={form}
					layout="vertical"
					name="sendMessageForm"
					style={{ width: '100%' }}
					initialValues={{
						name1: mortgage?.owner1?.name,
						name2: mortgage?.owner2?.name,
						lastName1: mortgage?.owner1?.firstSurname,
						lastName2: mortgage?.owner2?.firstSurname,
						birthdate1: moment(mortgage?.owner1?.birthdate).format('DD-MM-YYYY'),
						birthdate2: moment(mortgage?.owner2?.birthdate).format('DD-MM-YYYY'),
						customerId1: mortgage?.owner1?.nif,
						customerId2: mortgage?.owner2?.nif,
						number1: mortgage?.owner1?.phone,
						number2: mortgage?.owner2?.phone,
						address1: mortgage?.owner1?.email,
						address2: mortgage?.owner2?.email,
						annualIncomes1:
							mortgage?.owner1?.netIncomes * mortgage?.owner1?.numberOfPayments +
							mortgage?.owner1?.bonus,
						contractType1: activityTypesToCaixaTypes.find(
							(data) => data.name === mortgage?.owner1.employment.currentEmployments[0].activity
						).code,
						operationType: type.subtype === 'mortgage' ? '01' : '03',
						buyingPrice: mortgage.propertyWishPurchase?.maxBudget,
						loanAmount: mortgage.propertyWishPurchase?.mortgageAmount,
						savingsAmount: mortgage.propertyWishPurchase?.savings,
						usage:
							type.subtype === 'mortgage'
								? dataCaixa.propertyUsage.find(
										(data) => data.name === mortgage?.mortgage?.houseType
								  ).code
								: undefined,
						/*type: dataCaixa?.propertyUsage.find(
							(data) => data.name === mortgage?.mortgage?.propertyType
						).code,*/
						postCode: mortgage?.propertyWishPurchase?.postalCode,

						provinceCode:
							type.subtype === 'mortgage'
								? dataCaixa.provinces.find(
										(data) => data.name === mortgage?.propertyWishPurchase?.province
								  ).code
								: undefined,
						type1:
							mortgage?.owner1?.idDocumentType !== undefined
								? dataCaixa.documentIdType.find(
										(data) => data.name === mortgage?.owner1?.idDocumentType
								  ).code
								: undefined,
						type2:
							mortgage?.owner2 !== undefined && mortgage?.owner2?.idDocumentType
								? dataCaixa.documentIdType.find(
										(data) => data.name === mortgage?.owner2?.idDocumentType
								  ).code
								: undefined,
						issuingCountry1: mortgage?.owner1?.nationality === 'ES' ? 'ES' : undefined,
						issuingCountry2: mortgage?.owner2?.nationality === 'ES' ? 'ES' : undefined,
						loanTerm: bankRequest?.months
					}}
					onFinish={onFinish}>
					{renderForm()}

					<Row gutter={[24, 24]}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'operationType'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Tipo de operacion`}
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa?.operationTypes.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'buyingPrice'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Precio de compra`}
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'loanAmount'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Cantidad a financiar`}
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'savingsAmount'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Ahorros'}
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'usage'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Tipo de uso
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Select
									showSearch
									placeholder="Seleccione"
									name="new/second"
									style={{ width: '100%' }}>
									{dataCaixa?.propertyUsage.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'type'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Tipo vivienda
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Select
									showSearch
									placeholder="Seleccione"
									name="new/second"
									style={{ width: '100%' }}>
									{dataCaixa?.propertyTypes.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						{/*<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'countryCode'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{`Pais`}</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<InputNumber
									parser={(value) => numeral(value).value()}
									style={{ width: '100%', borderRadius: 4 }}
								/>
							</Form.Item>
							</Col>*/}
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'postCode'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Código postal`}
									</Text>
								}
								rules={[
									{
										required: true,
										validator: (e, value, callback) => {
											return new Promise((resolve, reject) => {
												if (value.length > 5) {
													reject('debe ser menor o igual a 5 caracteres');
												} else {
													resolve();
												}
											});
										}
									}
								]}>
								<InputNumber
									parser={(value) => numeral(value).value()}
									style={{ width: '100%', borderRadius: 4 }}
								/>
							</Form.Item>
						</Col>
						{/*<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'municipalityCode'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Municipio CP'}
									</Text>
								}
								rules={[
									{
										required: true,
										validator: (e, value, callback) => {
											return new Promise((resolve, reject) => {
												if (value.length > 6) {
													reject('debe ser menor o igual a 6 caracteres');
												} else {
													resolve();
												}
											});
										}
									}
								]}>
								<InputNumber className="inputCommon" style={{ borderRadius: 4, width: '100%' }} />
							</Form.Item>
							</Col>*/}
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'provinceCode'}
								rules={[{ required: true, message: 'Please add a subject' }]}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{`Provincia`}
									</Text>
								}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa?.provinces.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'autonomousCommunityCode'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										CP autónomo
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa?.autonomousCommunities.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'streetType'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Tipo de calle
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'streetName'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Nombre de la calle
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>{' '}
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'buildingNumber'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Número de edificio
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>{' '}
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'stairNumber'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Número de escalera
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>{' '}
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'floorNumber'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Número de piso
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'apartmentNumber'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Número de apartamento
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[24, 24]}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'loanTerm'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Plazo de prestamo(meses)
									</Text>
								}
								rules={[{ required: true, message: 'Please add a subject' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					</Row>

					<Row style={{ justifyContent: 'center', marginTop: 20 }}>
						<Button
							style={{ height: 32, width: 96, fontSize: 14 }}
							className="button-secundary-gibobs"
							onClick={() => cancelSend()}
							type="dashed">
							Cancelar
						</Button>

						<Button
							style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
							type="primary"
							htmlType="submit"
							className="button-primari-gibobs">
							Aceptar
						</Button>
					</Row>
				</Form>
			)}
			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: 96, marginTop: 16 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96, marginTop: 16 } }}
				visible={visibleCancel}
				title={
					<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
						{'Cancelar envio Caixa'}
					</Text>
				}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				width={404}
				onCancel={handleCancel}
				onOk={handleOk2}>
				<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858' }}>
					{'¿Quieres salir del envio a Caixa?'}
				</Text>
			</Modal>
		</>
	);
};
export default TableCaixaBankApi;
