import React, { useEffect, useState } from 'react';
import { Content } from 'antd/es/layout/layout';
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Input,
	InputNumber,
	message,
	Row,
	Image,
	Upload,
	notification
} from 'antd';
import authClient from '../../../../../Auth/Auth';
import axios from 'axios';
import env from '../../../../../environment';
import moment from 'moment';
import { AlertOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { changeStatusJob } from '../../../../../store/FormalizationTasacion/actions';
import Loading from '../../../../../Components/ModalsAndAlerts/Loading';
import { UploadOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import dayjs from 'dayjs';
import _ from 'lodash';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

export default (props) => {
	const { mortgageId } = props;
	const [data, setData] = useState();
	const [mortgage, setMortgage] = useState();
	const [offer, setOffer] = useState(undefined);
	const [fileName, setFileName] = useState('');
	const [signDate, setSignDate] = useState();
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const dispatch = useDispatch();
	const [isSabadell, setIsSabadell] = useState(undefined);

	useEffect(() => {
		getMortgageData();
		getMortgageDetail();
		getBanksOnGoing();
		getManagementId();
	}, [mortgageId]);
	useEffect(() => {
		if (data && data.mortgage && data.mortgage.signDate) {
			setSignDate(data.mortgage.signDate);
		}
	}, [data]);

	const getManagementId = () => {
		axios
			.post(
				`${env.api.url}/management/management/by-operation`,
				{ operationId: mortgageId },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if (response.data.data.code === 'sabadell-es') {
					setIsSabadell(true);
				} else {
					setIsSabadell(false);
				}
			});
	};

	const getBanksOnGoing = async () => {
		const response = await axios.get(`${env.api.url}/v1/banks/get-banks?status=ongoing`, {
			headers: { Authorization: `Bearer ${authClient.getToken()}` }
		});
	};

	const getMortgageData = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/data/get/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setData(response.data.data);
			});
	};

	const getMortgageDetail = () => {
		axios
			.get(`${env.api.url}/api/hadmin/mortgages/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setMortgage(response.data);
				getOffers(response.data.selectedOffer);
			});
	};

	const getOffers = async (selectedOffer) => {
		const response = await axios.get(
			`${env.api.url}/v1/banks/get-operation-bank-offers/${mortgageId}`,
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);

		const offer = response.data.data.find((bo) => bo.id === selectedOffer);

		setOffer(offer);
	};

	if (!data || !mortgage) {
		return <Loading />;
	}

	const saveData = async (participant, key, value) => {
		if (key === 'digitalContractLink' && !value.startsWith('https://')) {
			value = 'https://' + value;
		}

		await axios
			.post(
				`${env.api.url}/v1/mortgages/data/update/${mortgageId}`,
				{ key, participant, value },
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				message.success('Se actualizo con exito');
				getMortgageData();
			});
	};

	const saveDataZoom = async (owner, key, value, owner1, key1, value1) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: owner,
							subkey: key,
							value: value
						},
						{
							key: owner1,
							subkey: key1,
							value: value1
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				getMortgageData();
			});
	};

	const saveFileData = (file, name) => {
		axios
			.put(
				`${env.api.url}/api/mortgages/attachment`,
				{
					mortgageId,
					file,
					fileName: name,
					isOnAgent: true,
					type: 'fein'
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => {
				message.success('Se actualizo con exito');
			});
	};

	const closeMortgage = async () => {
		await axios.post(
			`${env.api.url}/api/hadmin/mortgage-close`,
			{
				mortgageId,
				applicant: 'h1',
				closeReason: 'h1sign',
				subCloseReason: null,
				body: 'Operación firmada con Hipoteca Primero'
			},
			{
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			}
		);
	};

	const uploadProps = {
		beforeUpload: (file) => {
			setFileName(file.name);
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => {
					const base64File = reader.result.split(',')[1];
					saveFileData(base64File, file.name);
				};
			});
		}
	};

	const generateUserCode = (type) => {
		const name = `${type.indexOf('owner') != -1 ? 'Titular' : 'Avalista'} ${type
			.replace('owner', '')
			.replace('guarantor', '')}`;

		return (
			<Row style={{ marginLeft: 0, marginTop: 10, marginBottom: 15 }} key={`user-digital-${type}`}>
				<Col span={4}>
					<label htmlFor={type}>
						<strong>Usuario {name}:</strong>
					</label>
				</Col>
				<Col span={20}>
					<Input
						disabled={statusJob === 'finish' ? true : false}
						name={type}
						id={type}
						style={{ width: '100%' }}
						defaultValue={
							data[type] && data[type].userBankCode !== undefined ? data[type].userBankCode : ''
						}
						onBlur={(e) => saveData(type, 'userBankCode', e.target.value)}
						placeholder="Código de usuario banca digital"
					/>
				</Col>
			</Row>
		);
	};

	const closeMortgageBell = () => {
		if (isSabadell && data?.mortgage?.numberLoan) {
			if (signDate) {
				saveData('mortgage', 'ringTheBell', true);
				dispatch(changeStatusJob('finish'));
			} else {
				notification.error({
					message: 'Error',
					description: 'Para cerrar la operacion introduce la fecha de firma, por favor'
				});
			}
		} else if (isSabadell && data?.mortgage?.numberLoan === undefined) {
			notification.error({
				message: 'Error',
				description:
					'Es necesario completar el nº de préstamo de la operación. Lo puedes encontrar en Sinfonía'
			});
		} else {
			if (signDate) {
				saveData('mortgage', 'ringTheBell', true);
				dispatch(changeStatusJob('finish'));
			} else {
				notification.error({
					message: 'Error',
					description: 'Para cerrar la operacion introduce la fecha de firma, por favor'
				});
			}
		}
	};

	const saveDataOnClick = (participant, key, value) => {
		let checkValue = {
			key: 'operation',
			subkey: key === 'signedDocumentation' ? 'firstClickToSignDocs' : 'firstClickToIssueFein',
			value: moment().format('YYYY-MM-DD')
		};

		if (key === 'signedDocumentation' && data.operation.firstClickToSignDocs !== undefined) {
			checkValue = {
				key: 'operation',
				subkey: 'ultimateClickToSignDocs',
				value: moment().format('YYYY-MM-DD')
			};
		}

		/*if (key === 'docFeinIssued' && data.operation.firstClickToIssueFein !== undefined) {
			checkValue = {
				key: 'operation',
				subkey: 'ultimateClickToIssueFein',
				value: moment().format('YYYY-MM-DD')
			};
		}*/
		if (key === 'signed' && value === true) {
			checkValue = {
				key: 'mortgage',
				subkey: 'signedDocumentation',
				value: true
			};
		}

		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: participant,
							subkey: key,
							value: value
						},
						{
							key: checkValue.key,
							subkey: checkValue.subkey,
							value: checkValue.value
						}
					]
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then(() => getMortgageData());
	};

	const userDigitals = [];

	for (let i = 1; i <= data.mortgage.participants; i++) {
		userDigitals.push(generateUserCode(`owner${i}`));
	}

	for (let i = 0; i < data.mortgage.guarantors; i++) {
		userDigitals.push(generateUserCode(`guarantor${i + 1}`));
	}

	return (
		<Content style={{ paddingLeft: 10 }}>
			<label>
				<strong>DOCUMENTOS PARA LA FORMALIZACIÓN</strong>
			</label>
			<Row style={{ marginLeft: 0, marginTop: 40, marginBottom: 15 }}>
				<Col span={4}>
					<label htmlFor="linkContrato">
						<strong>Link contrato digital: {statusJob}</strong>
					</label>
				</Col>
				<Col span={20}>
					<Input
						disabled={statusJob === 'finish' ? true : false}
						style={{ width: '100%', maxWidth: 500 }}
						defaultValue={data.mortgage.digitalContractLink}
						onBlur={(e) => saveData('mortgage', 'digitalContractLink', e.target.value)}
						placeholder="Link contrato digital para que firme el cliente"
					/>
				</Col>
			</Row>
			{userDigitals}
			<Row style={{ marginLeft: 0, marginTop: 50, marginBottom: 15 }}>
				<Col span={4}>
					<label>
						{' '}
						<strong>Doc. en papel:</strong>
					</label>
				</Col>
				<Col span={20}>
					<Upload {...uploadProps}>
						<Button
							disabled={statusJob === 'finish' ? true : false}
							type="primary"
							icon={<UploadOutlined />}>
							SUBIR FEIN - FIAE
						</Button>
						<small>{fileName}</small>
					</Upload>
				</Col>
			</Row>

			<Row gutter={24} style={{ marginTop: 40, marginBottom: 15 }}>
				{!isSabadell && (
					<Col span={7}>
						<Checkbox
							disabled={statusJob === 'finish' ? true : false}
							name="docFeinIssued"
							checked={data.mortgage.docFeinIssued}
							onChange={(e) => saveDataOnClick('mortgage', 'docFeinIssued', e.target.checked)}
							style={{ marginRight: 15 }}>
							<strong>Documentación/FEIN emitida</strong>
						</Checkbox>
					</Col>
				)}

				{isSabadell && (
					<Col span={7} style={{ display: 'flex' }}>
						<Checkbox
							disabled={statusJob === 'finish' ? true : false}
							name="symphony"
							checked={data.mortgage.symphony}
							onChange={(e) => saveDataOnClick('mortgage', 'symphony', e.target.checked)}
							style={{ marginRight: 15, width: 300, marginTop: 12 }}>
							<div style={{ marginTop: 6 }}>
								<strong>Ya en sinfonía (gestoría de formalización)</strong>
							</div>
						</Checkbox>

						<DatePicker
							disabled={statusJob === 'finish' ? true : false}
							style={{ marginTop: 15, width: '100%' }}
							onChange={(date, dateString) =>
								saveData('mortgage', 'symphonyDate', dayjs(date).format('YYYY-MM-DD'))
							}
							format={'DD-MM-YYYY'}
							defaultValue={
								data.mortgage.symphonyDate ? dayjs(data.mortgage.symphonyDate) : undefined
							}
							placeholder={'Fecha'}
						/>
					</Col>
				)}
			</Row>

			{isSabadell && (
				<Row gutter={24} style={{ marginTop: 40, marginBottom: 15 }}>
					<Col span={7} style={{ display: 'flex' }}>
						<Checkbox
							disabled={statusJob === 'finish' ? true : false}
							name="docFeinIssued"
							checked={data.mortgage.docFeinIssued}
							onChange={(e) => saveDataOnClick('mortgage', 'docFeinIssued', e.target.checked)}
							style={{ marginRight: 15, marginTop: 12, width: 300 }}>
							<div style={{ marginTop: 6 }}>
								<strong>FEIN emitida</strong>
							</div>
						</Checkbox>

						<DatePicker
							disabled={statusJob === 'finish' ? true : false}
							style={{ marginTop: 15, width: '100%' }}
							onChange={(date, dateString) =>
								saveData('mortgage', 'docFeinIssuedDate', dayjs(date).format('YYYY-MM-DD'))
							}
							format={'DD-MM-YYYY'}
							defaultValue={
								data.mortgage.docFeinIssuedDate ? dayjs(data.mortgage.docFeinIssuedDate) : undefined
							}
							placeholder={'Fecha'}
						/>
					</Col>
				</Row>
			)}
			<Row gutter={24} style={{ marginTop: 40, marginBottom: 15 }}>
				<Col span={7}>
					<Checkbox
						disabled={statusJob === 'finish' ? true : false}
						name="fileSign"
						checked={data.mortgage.signedDocumentation}
						onChange={(e) => saveDataOnClick('mortgage', 'signedDocumentation', e.target.checked)}
						style={{ marginRight: 15 }}>
						<strong> {isSabadell ? 'FEIN Firmada' : 'Documentación firmada'}</strong>
					</Checkbox>
				</Col>
			</Row>
			<Row gutter={24} style={{ marginTop: 30, marginBottom: 40 }}>
				<Col span={5}>
					<label>
						<strong>Fecha Estimada de Firma:</strong>
					</label>
					<br />
					<DatePicker
						disabled={statusJob === 'finish' ? true : false}
						style={{ marginTop: 15, width: '100%' }}
						onChange={(date, dateString) =>
							saveData('mortgage', 'estimatedSignDate', dayjs(date).format('YYYY-MM-DD'))
						}
						format={'DD-MM-YYYY'}
						defaultValue={
							data && data?.mortgage?.estimatedSignDate !== 'Invalid Date'
								? dayjs(data.mortgage.estimatedSignDate)
								: undefined
						}
						placeholder={'Fecha'}
					/>
				</Col>
			</Row>

			<Row style={{ marginTop: 10, marginBottom: 15 }}>
				<Col span={6}>
					<Checkbox
						disabled={statusJob === 'finish' ? true : false}
						name="hpSign"
						checked={data.mortgage.signed}
						onChange={(e) => saveDataOnClick('mortgage', 'signed', e.target.checked)}
						style={{ marginRight: 15 }}>
						<strong>Hipoteca Firmada</strong>
					</Checkbox>
				</Col>
				<Col span={6}>
					<Checkbox
						disabled={statusJob === 'finish' ? true : false}
						name="notaryOferted"
						checked={data?.zoom?.notaryOferted}
						onChange={(e) =>
							saveDataZoom(
								'zoom',
								'notaryOferted',

								e.target.checked,
								'zoom',
								'notaryOfertedDate',
								moment().format('YYYY-MM-DD')
							)
						}
						style={{ marginRight: 15 }}>
						<strong>Ofrecido cupón de notaria</strong>
					</Checkbox>
				</Col>
				<Col span={6}>
					<Checkbox
						disabled={statusJob === 'finish' ? true : false}
						name="notaryUsed"
						checked={data?.zoom?.notaryUsed}
						onChange={(e) =>
							saveDataZoom(
								'zoom',
								'notaryUsed',
								e.target.checked,
								'zoom',
								'notaryUsedDate',
								moment().format('YYYY-MM-DD')
							)
						}
						style={{ marginRight: 15 }}>
						<strong> Cupón de notaría utilizado</strong>
					</Checkbox>
				</Col>
			</Row>

			{data.mortgage.signed === true && (
				<div>
					<Row gutter={24} style={{ marginTop: 30, marginBottom: 15 }}>
						<Col span={5}>
							<label>
								<strong>Fecha de firma</strong>
							</label>
							<br />
							<div>
								<DatePicker
									disabled={statusJob === 'finish' ? true : false}
									style={{ marginTop: 15, width: '100%' }}
									onChange={(date, dateString) => {
										if (!_.isNil(date)) {
											saveData('mortgage', 'signDate', dayjs(date).format('YYYY-MM-DD'));
										}
									}}
									format={'DD/MM/YYYY'}
									value={
										signDate === undefined || signDate === 'Invalid date'
											? undefined
											: dayjs(signDate)
									}
									placeholder={'Fecha'}
								/>
							</div>
						</Col>
						<Col span={7}>
							<label htmlFor="bank">
								<strong>Banco</strong>
							</label>
							<br />
							<Input
								disabled={statusJob === 'finish' ? true : false}
								style={{ width: '100%', marginTop: 15 }}
								readOnly={true}
								value={mortgage.bank}
							/>
							<label style={{ marginTop: 15 }}>{}</label>
						</Col>
						<Col span={5}>
							<label>
								<strong>Importe</strong>
							</label>
							<br />
							{offer !== undefined && (
								<InputNumber
									disabled={statusJob === 'finish' ? true : false}
									style={{ width: '100%', marginTop: 15 }}
									formatter={(value) => numeral(value).format('0,0 $')}
									parser={(value) => numeral(value).value()}
									defaultValue={
										offer?.amount === 0 || offer?.amount === undefined
											? data?.mortgage?.amount
											: offer.amount
									}
									onBlur={(e) => {
										saveData('mortgage', 'amount', numeral(e.target.value).value());
									}}
								/>
							)}
						</Col>
						<Col span={7}>
							<label>
								<strong>Nº de préstamo/Nº de acuerdo</strong>
							</label>
							<br />
							<Input
								disabled={statusJob === 'finish' ? true : false}
								style={{ width: '100%', marginTop: 15 }}
								defaultValue={data.mortgage.numberLoan}
								onBlur={(e) => {
									saveData('mortgage', 'numberLoan', e.target.value);
								}}
							/>
						</Col>
					</Row>

					{data.mortgage.signed && signDate && (
						<Row style={{ paddingTop: 40 }} type="flex" align="middle">
							<Col style={{ textAlign: 'center' }} span={24}>
								{!data.mortgage.ringTheBell ? (
									<Button
										disabled={statusJob === 'finish' ? true : false}
										type="primary"
										icon={<AlertOutlined style={{ fontSize: '15px', paddingTop: 3 }} />}
										onClick={async () => {
											closeMortgageBell();
										}}>
										TOCAR LA CAMPANA
									</Button>
								) : (
									<Image preview={false} src="/images/bell.gif" />
								)}
							</Col>
						</Row>
					)}
				</div>
			)}
		</Content>
	);
};
