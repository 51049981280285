import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Input,
	Modal,
	Form,
	Select,
	Spin,
	Tooltip,
	notification
} from 'antd';
import { InputNumber } from 'afrodita';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { InputDate } from 'afrodita';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;
const { Option, OptGroup } = Select;

const TableEvo = ({ mortgageId, showModalEmail, mortgage }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const [citizenshipCountry, setCitizenshipCountry] = useState(undefined);
	const [currentHouseStatus, setCurrentHouseStatus] = useState(undefined);
	const [workSituation, setWorkSituation] = useState(undefined);
	const idBank = location && location.state !== undefined ? location.state.bankId : undefined;
	const bankRequestId =
		location && location.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest =
		location && location.state !== undefined ? location.state.bankRequest : undefined;
	const type =
		location && location.state !== undefined ? location.state.typeBankrequest : undefined;
	const [visibleCancel, setVisibleCancel] = useState(false);
	const [otherDatasAll, setOtherDatasAll] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const [civilStatus, setCivilStatus] = useState(undefined);
	const [emptyFields, setEmptyFields] = useState([]);

	useEffect(() => {
		getOtherData();

		if (mortgage['owner1']?.nationality === 'ES') {
			const copyRows = { ...citizenshipCountry };
			copyRows[1] = {
				value: '011',
				index: 1
			};
			copyRows[2] = {
				value: '011',
				index: 2
			};
			setCitizenshipCountry(copyRows);
		}
		const copyRows2 = { ...workSituation };
		copyRows2[1] = {
			value: renderWork(mortgage['owner1']),
			index: 1
		};
		copyRows2[2] = {
			value: renderWork(mortgage['owner2']),
			index: 2
		};
		setWorkSituation(copyRows2);
	}, []);

	useEffect(() => {
		if (mortgage, workSituation) {
			const values = form.getFieldsValue();
			const undefinedFields = Object.entries(values)
				.filter(([fieldName, value]) => value === undefined || value === "" || value === "Fecha inválida")
				.map(([fieldName]) => fieldName);
			setEmptyFields(undefinedFields);
		}
	}, [form, mortgage, workSituation]);

	const getOtherData = () => {
		axios.get(`${env.api.url}/banks/evo/data/all`).then((response) => {
			setOtherDatasAll(response.data.data);
		});
	};

	const searchInput = (input, option) => {
		if (option.children === undefined) {
			for (let index = 0; index < option.options.length; index++) {
				return option.options[index].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			}
		}
	};

	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const undefinedFields = Object.entries(values)
			.filter(([fieldName, value]) => value === undefined || value === "" || value === "Fecha inválida")
			.map(([fieldName]) => fieldName);
		setEmptyFields(undefinedFields);
	};

	const onChangeCivilStatus = (value, index) => {
		const copyRows = { ...civilStatus };

		copyRows[index] = {
			value: value,
			index: index
		};
		setCivilStatus(copyRows);
		handleFormChange();
	};

	const onChnageCityZen = (value, index) => {
		const copyRows = { ...citizenshipCountry };

		copyRows[index] = {
			value: value,
			index: index
		};
		setCitizenshipCountry(copyRows);
		handleFormChange();
	};

	const onChangeWork = (value, index) => {
		const copyRows = { ...workSituation };

		copyRows[index] = {
			value: value,
			index: index
		};
		setWorkSituation(copyRows);
		handleFormChange();
	};

	const onchangeCurrentHouseStatus = (value, index) => {
		const copyRows = { ...currentHouseStatus };

		copyRows[index] = {
			value: value,
			index: index
		};
		setCurrentHouseStatus(copyRows);
		handleFormChange();
	};

	const renderForm = () => {
		const arrayform = [];

		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			arrayform.push(
				<>
					<Row>
						<Text
							style={{
								fontSize: 14,
								fontWeight: 600,
								color: '#2F4858',
								marginTop: index === 1 ? 32 : 24
							}}>
							{'Titular ' + index}
						</Text>
					</Row>

					<div style={{ marginTop: 24 }}>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
							Datos personales
						</Text>
					</div>

					<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'name' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nombre'}
									</Text>
								}
								className={emptyFields.includes(`name${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'firstLastName' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Primer apellido'}
									</Text>
								}
								className={emptyFields.includes(`firstLastName${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'secondLastName' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Segundo apellido'}
									</Text>
								}
								className={emptyFields.includes(`secondLastName${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'birthDate' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Fecha de nacimiento'}
									</Text>
								}
								className={emptyFields.includes(`birthDate${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputDate type="date" style={{ width: '100%', borderRadius: 4 }} onChange={handleFormChange} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'phone' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Teléfono móvil del cliente'}
									</Text>
								}
								className={emptyFields.includes(`phone${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>

						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'email' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'email'}</Text>
								}
								className={emptyFields.includes(`email${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'externalId' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'NIF/NIE del cliente'}
									</Text>
								}
								className={emptyFields.includes(`externalId${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'civilStatus' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Estado Civil'}
									</Text>
								}
								className={emptyFields.includes(`civilStatus${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select placeholder="Seleccione" onChange={(e) => onChangeCivilStatus(e, index)}>
									{otherDatasAll &&
										otherDatasAll.civilStatus.map((item) => (
											<Option key={item.code} value={item.code}>
												{item.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>
						{((civilStatus !== undefined && civilStatus[index]?.value === '05') ||
							mortgage['owner' + index].maritalStatus === 'married_property' ||
							mortgage['owner' + index].maritalStatus === 'married_separation') && (
								<Col span={6}>
									<Form.Item
										style={{ width: '100%' }}
										name={'economicRegime' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Régimen economico'}
											</Text>
										}
										className={(civilStatus !== undefined &&
											civilStatus[index]?.value === '05' &&
											civilStatus[index]?.index === index) &&
											emptyFields.includes(`economicRegime${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													civilStatus !== undefined &&
														civilStatus[index]?.value === '05' &&
														civilStatus[index]?.index === index
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<Select placeholder="Seleccione" onChange={handleFormChange}>
											{otherDatasAll &&
												otherDatasAll.economicRegime.map((item) => (
													<Option key={item.code} value={item.code}>
														{item.name}
													</Option>
												))}
										</Select>
									</Form.Item>
								</Col>
							)}
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'numberSons' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Número de hijos'}
									</Text>
								}
								className={emptyFields.includes(`numberSons${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<InputNumber gbType='number' style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={[32, 0]} style={{ marginTop: -10 }}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'documentExpiryDate' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Fecha de expiración del documento del cliente'}
									</Text>
								}
								className={(citizenshipCountry &&
									citizenshipCountry[index] &&
									citizenshipCountry[index].value !== '011') &&
									emptyFields.includes(`documentExpiryDate${index}`) ? 'shadowed' : ''}
								rules={[
									{
										required:
											citizenshipCountry &&
												citizenshipCountry[index] &&
												citizenshipCountry[index].value !== '011'
												? true
												: false,
										message: 'Por favor, rellene este campo'
									}
								]}>
								<InputDate type="date" style={{ width: '100%', borderRadius: 4 }} onChange={handleFormChange} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'citizenshipCountry' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Nacionalidad'}
									</Text>
								}
								className={emptyFields.includes(`citizenshipCountry${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									onChange={(e) => onChnageCityZen(e, index)}
									showSearch
									placeholder="Seleccione"
									filterOption={(input, option) => {
										return (
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
											option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
										);
									}}
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll &&
										otherDatasAll.countries.map((item) => (
											<Option key={item.code} value={item.code}>
												{item.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>

						{citizenshipCountry &&
							citizenshipCountry[index] &&
							citizenshipCountry[index].value !== '011' && (
								<>
									<Col span={6}>
										<Form.Item
											style={{ width: '100%' }}
											name={'yearsCountry' + index}
											label={
												<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
													{'Nº años en España'}
												</Text>
											}
											className={citizenshipCountry &&
												citizenshipCountry[index].value !== '011' &&
												emptyFields.includes(`yearsCountry${index}`) ? 'shadowed' : ''}
											rules={[
												{
													required:
														citizenshipCountry && citizenshipCountry[index].value !== '011'
															? true
															: false,
													message: 'Por favor, rellene este campo'
												}
											]}>
											<InputNumber style={{ width: '100%', borderRadius: 4 }} type="text" />
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item
											style={{ width: '100%' }}
											name={'birthProvince' + index}
											label={
												<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
													{'Provincia de nacimiento'}
												</Text>
											}
											className={citizenshipCountry &&
												citizenshipCountry[index] &&
												citizenshipCountry[index].value === '011' &&
												emptyFields.includes(`birthProvince${index}`) ? 'shadowed' : ''}
											rules={[
												{
													required:
														citizenshipCountry &&
															citizenshipCountry[index] &&
															citizenshipCountry[index].value === '011'
															? true
															: false,
													message: 'Por favor, rellene este campo'
												}
											]}>
											<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
										</Form.Item>
									</Col>
									<Col span={6} style={{ marginTop: -10 }}>
										<Form.Item
											style={{ width: '100%' }}
											name={'birthLocality' + index}
											label={
												<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
													{'Localidad de nacimiento'}
												</Text>
											}
											className={citizenshipCountry &&
												citizenshipCountry[index] &&
												citizenshipCountry[index].value !== '011' &&
												emptyFields.includes(`birthLocality${index}`) ? 'shadowed' : ''}
											rules={[
												{
													required:
														citizenshipCountry &&
															citizenshipCountry[index] &&
															citizenshipCountry[index].value !== '011'
															? true
															: false,
													message: 'Por favor, rellene este campo'
												}
											]}>
											<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
										</Form.Item>
									</Col>
								</>
							)}

						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'countryResidence' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Pais de residencia'}
									</Text>
								}
								className={emptyFields.includes(`countryResidence${index}`) ? 'shadowed' : ''}
								rules={[
									{
										//required: citizenshipCountry !== '011' ? true : false,
										required: true,
										message: 'Por favor, rellene este campo'
									}
								]}>
								<Select
									showSearch
									onChange={handleFormChange}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll &&
										otherDatasAll.countries.map((item) => (
											<Option key={item.code} value={item.code}>
												{item.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>
					</Row>

					<div>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
							Datos de contacto
						</Text>
					</div>

					<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'streetType' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de calle'}
									</Text>
								}
								className={emptyFields.includes(`streetType${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll &&
										otherDatasAll.streetTypes.map((item) => (
											<Option key={item.code} value={item.code}>
												{item.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>

						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'address' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>{'Calle'}</Text>
								}
								className={emptyFields.includes(`address${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'postalCode' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Código Postal'}
									</Text>
								}
								className={emptyFields.includes(`postalCode${index}`) ? 'shadowed' : ''}
								rules={[
									{ required: true, message: 'Por favor, rellene este campo' },
									{ pattern: /^\d{5}$/, message: 'El código postal debe tener 5 dígitos' }
								]}>
								<Input style={{ width: '100%', borderRadius: 4 }} type="text" />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'currentHouseStatus' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Situación de la vivienda en la que reside'}
									</Text>
								}
								className={emptyFields.includes(`currentHouseStatus${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={(e) => onchangeCurrentHouseStatus(e, index)}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll &&
										otherDatasAll.currentHouseStatus.map((item) => (
											<Option key={item.code} value={item.code}>
												{item.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'houseAdquisitionYear' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Año de adquisición'}
									</Text>
								}
								className={(currentHouseStatus && currentHouseStatus[index] &&
									(currentHouseStatus[index].value === '6' ||
										currentHouseStatus[index].value === '1' ||
										currentHouseStatus[index].value === '4')) &&
									emptyFields.includes(`houseAdquisitionYear${index}`) ? 'shadowed' : ''}
								rules={[
									{
										required:
											currentHouseStatus &&
												currentHouseStatus[index] &&
												(currentHouseStatus[index].value === '6' ||
													currentHouseStatus[index].value === '1' ||
													currentHouseStatus[index].value === '4')
												? true
												: false,
										message: 'Por favor, rellene este campo'
									}
								]}>
								<InputDate type="date" style={{ width: '100%', borderRadius: 4 }} onChange={handleFormChange} />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'currentHouseRentStartDate' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Fecha de inicio de alquiler'}
									</Text>
								}
								className={currentHouseStatus &&
									currentHouseStatus[index] &&
									currentHouseStatus[index].value === '2' &&
									emptyFields.includes(`currentHouseRentStartDate${index}`) ? 'shadowed' : ''}
								rules={[
									{
										required:
											currentHouseStatus &&
												currentHouseStatus[index] &&
												currentHouseStatus[index].value === '2'
												? true
												: false,
										message: 'Por favor, rellene este campo'
									}
								]}>
								<InputDate type="date" style={{ width: '100%', borderRadius: 4 }} onChange={handleFormChange} />
							</Form.Item>
						</Col>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'monthlyRentExpenses' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Gastos mensuales en el alquiler'}
									</Text>
								}
								className={currentHouseStatus &&
									currentHouseStatus[index] &&
									currentHouseStatus[index].value === '2' &&
									emptyFields.includes(`monthlyRentExpenses${index}`) ? 'shadowed' : ''}
								rules={[
									{
										required:
											currentHouseStatus &&
												currentHouseStatus[index] &&
												currentHouseStatus[index].value === '2'
												? true
												: false,
										message: 'Por favor, rellene este campo'
									}
								]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					</Row>
					<div style={{ marginTop: 24 }}>
						<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>Datos de empleo</Text>
					</div>

					<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'workSituation' + index}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Situación laboral'}
									</Text>
								}
								className={emptyFields.includes(`workSituation${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									placeholder="Seleccione"
									onChange={(e) => onChangeWork(e, index)}
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll &&
										otherDatasAll.workSituations.map((item) => (
											<Option key={item.code} value={item.code}>
												{item.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '09') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'contractType' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Tipo de contrato'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' ||
												workSituation[index].value === '12') &&
											emptyFields.includes(`contractType${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '12')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<Select
											showSearch
											onChange={handleFormChange}
											placeholder="Seleccione"
											style={{ width: '100%', borderRadius: 4 }}>
											{otherDatasAll &&
												otherDatasAll.contractTypes.map((item) => (
													<Option key={item.code} value={item.code}>
														{item.name}
													</Option>
												))}
										</Select>
									</Form.Item>
								</Col>
							)}
						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '09' ||
								workSituation[index].value === '11' ||
								workSituation[index].value === '05') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'paymentsNumber' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Número de pagas'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' ||
												workSituation[index].value === '12' ||
												workSituation[index].value === '09' ||
												workSituation[index].value === '05' ||
												workSituation[index].value === '11') &&
											emptyFields.includes(`paymentsNumber${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '12' ||
															workSituation[index].value === '09' ||
															workSituation[index].value === '05' ||
															workSituation[index].value === '11')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<Select
											showSearch
											onChange={handleFormChange}
											placeholder="Seleccione"
											style={{ width: '100%', borderRadius: 4 }}>
											<Option value="12">12</Option>
											<Option value="13">13</Option>
											<Option value="14">14</Option>
											<Option value="15">15</Option>
											<Option value="16">16</Option>
											<Option value="17">17</Option>
											<Option value="18">18</Option>
										</Select>
									</Form.Item>
								</Col>
							)}
						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '11' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '09' ||
								workSituation[index].value === '02' ||
								workSituation[index].value === '05') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'monthlyIncome' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Ingresos mensuales'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' ||
												workSituation[index].value === '11' ||
												workSituation[index].value === '12' ||
												workSituation[index].value === '09' ||
												workSituation[index].value === '02' ||
												workSituation[index].value === '05' ||
												workSituation[index].value === '11') &&
											emptyFields.includes(`monthlyIncome${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '11' ||
															workSituation[index].value === '12' ||
															workSituation[index].value === '09' ||
															workSituation[index].value === '02' ||
															workSituation[index].value === '05' ||
															workSituation[index].value === '11')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<InputNumber style={{ width: '100%', borderRadius: 4 }} />
									</Form.Item>
								</Col>
							)}
						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '11' ||
								workSituation[index].value === '09' ||
								workSituation[index].value === '02' ||
								workSituation[index].value === '05') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'monthlyExpenses' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Deudas mensuales'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' ||
												workSituation[index].value === '12' ||
												workSituation[index].value === '02' ||
												workSituation[index].value === '05' ||
												workSituation[index].value === '09' ||
												workSituation[index].value === '11') &&
											emptyFields.includes(`monthlyExpenses${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '12' ||
															workSituation[index].value === '02' ||
															workSituation[index].value === '05' ||
															workSituation[index].value === '09' ||
															workSituation[index].value === '11')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<InputNumber style={{ width: '100%', borderRadius: 4 }} />
									</Form.Item>
								</Col>
							)}
						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '11' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '09' ||
								workSituation[index].value === '02' ||
								workSituation[index].value === '05') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'variableAnnualIncome' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Ingresos anuales variables (bonus)'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' ||
												workSituation[index].value === '09' ||
												workSituation[index].value === '12' ||
												workSituation[index].value === '02' ||
												workSituation[index].value === '05' ||
												workSituation[index].value === '11') &&
											emptyFields.includes(`variableAnnualIncome${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '09' ||
															workSituation[index].value === '12' ||
															workSituation[index].value === '02' ||
															workSituation[index].value === '05' ||
															workSituation[index].value === '11')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<InputNumber style={{ width: '100%', borderRadius: 4 }} />
									</Form.Item>
								</Col>
							)}
						{workSituation &&
							(workSituation[index].value === '12' || workSituation[index].value === '01') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'companyName' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Empresa'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' ||
												workSituation[index].value === '12') &&
											emptyFields.includes(`companyName${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '12')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<Input style={{ width: '100%', borderRadius: 4 }} />
									</Form.Item>
								</Col>
							)}
						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '09') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'currentPosition' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Posición actual'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' ||
												workSituation[index].value === '12') &&
											emptyFields.includes(`currentPosition${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '12')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<Input style={{ width: '100%', borderRadius: 4 }} />
									</Form.Item>
								</Col>
							)}
						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '09' ||
								workSituation[index].value === '02' ||
								workSituation[index].value === '04' ||
								workSituation[index].value === '03') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'yearsWorked' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Número de años trabajados en total'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' ||
												workSituation[index].value === '12' ||
												workSituation[index].value === '02' ||
												workSituation[index].value === '09' ||
												workSituation[index].value === '04' ||
												workSituation[index].value === '03') &&
											emptyFields.includes(`yearsWorked${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '12' ||
															workSituation[index].value === '02' ||
															workSituation[index].value === '09' ||
															workSituation[index].value === '04' ||
															workSituation[index].value === '03')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<InputNumber
											gbType='number'
											className="gb-input-new-years"
											style={{ width: '100%', borderRadius: 4 }} />
									</Form.Item>
								</Col>
							)}
						{workSituation && workSituation[index].value === '02' && (
							<Col span={6} style={{ marginTop: -10 }}>
								<Form.Item
									style={{ width: '100%' }}
									name={'cnae' + index}
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
											{'CNAE'}
										</Text>
									}
									className={workSituation &&
										workSituation[index].value === '02' &&
										emptyFields.includes(`cnae${index}`) ? 'shadowed' : ''}
									rules={[
										{
											required: workSituation && workSituation[index].value === '02' ? true : false,
											message: 'Por favor, rellene este campo'
										}
									]}>
									<Select
										showSearch
										onChange={handleFormChange}
										filterOption={(input, option) =>
											option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										filterSort={(optionA, optionB) =>
											optionA?.children
												?.toLowerCase()
												.localeCompare(optionB?.children.toLowerCase())
										}
										placeholder="Seleccione"
										style={{ width: '100%', borderRadius: 4 }}>
										{otherDatasAll &&
											otherDatasAll.cnaes.map((cnae) => {
												return (
													<OptGroup label={cnae.name}>
														{cnae.cnaes.map((item) => (
															<Option value={item.code}>{item.name}</Option>
														))}
													</OptGroup>
												);
											})}
									</Select>
								</Form.Item>
							</Col>
						)}

						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '09') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'workStartDate' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Fecha antiguedad laboral'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' || workSituation[index].value === '12') &&
											emptyFields.includes(`workStartDate${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '12')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<InputDate type="date" style={{ width: '100%', borderRadius: 4 }} onChange={handleFormChange} />
									</Form.Item>
								</Col>
							)}
						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '09') && (
								<Col span={6} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'companyStartDate' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Fecha de antiguedad empresa actual'}
											</Text>
										}
										className={workSituation &&
											(workSituation[index].value === '01' || workSituation[index].value === '12') &&
											emptyFields.includes(`companyStartDate${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '12')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<InputDate type="date" style={{ width: '100%', borderRadius: 4 }} onChange={handleFormChange} />
									</Form.Item>
								</Col>
							)}

						{workSituation &&
							(workSituation[index].value === '12' ||
								workSituation[index].value === '01' ||
								workSituation[index].value === '09' ||
								workSituation[index].value === '11' ||
								workSituation[index].value === '02') && (
								<Col span={12} style={{ marginTop: -10 }}>
									<Form.Item
										style={{ width: '100%' }}
										name={'professionalActivity' + index}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'CNO'}
											</Text>
										} className={workSituation &&
											(workSituation[index].value === '01' ||
												workSituation[index].value === '12' ||
												workSituation[index].value === '02' ||
												workSituation[index].value === '05' ||
												workSituation[index].value === '09' ||
												workSituation[index].value === '11') &&
											emptyFields.includes(`professionalActivity${index}`) ? 'shadowed' : ''}
										rules={[
											{
												required:
													workSituation &&
														(workSituation[index].value === '01' ||
															workSituation[index].value === '12' ||
															workSituation[index].value === '02' ||
															workSituation[index].value === '05' ||
															workSituation[index].value === '09' ||
															workSituation[index].value === '11')
														? true
														: false,
												message: 'Por favor, rellene este campo'
											}
										]}>
										<Select
											showSearch
											onChange={handleFormChange}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
											placeholder="Seleccione"
											style={{ width: '100%', borderRadius: 4 }}>
											{otherDatasAll &&
												otherDatasAll.cnos.map((item) => (
													<Option key={item.code} value={item.code}>
														{item.name}
													</Option>
												))}
										</Select>
									</Form.Item>
								</Col>
							)}
						{workSituation && workSituation[index].value === '02' && (
							<Col span={6} style={{ marginTop: -10 }}>
								<Form.Item
									style={{ width: '100%' }}
									name={'freelanceStartDate' + index}
									label={
										<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
											{'Fecha alta autónomo'}
										</Text>
									}
									className={workSituation &&
										workSituation[index].value === '02' &&
										emptyFields.includes(`freelanceStartDate${index}`) ? 'shadowed' : ''}
									rules={[
										{
											required: workSituation && workSituation[index].value === '02' ? true : false,
											message: 'Por favor, rellene este campo'
										}
									]}>
									<InputDate type="date" style={{ width: '100%', borderRadius: 4 }} onChange={handleFormChange} />
								</Form.Item>
							</Col>
						)}
					</Row>
				</>
			);
		}

		return arrayform;
	};

	const renderNameByCode = (dataKey, code) => {
		if (code !== undefined) {
			const data = _.get(otherDatasAll, dataKey);

			if (_.isNil(data)) {
				console.log('no existe del datakey en los data de evo' + dataKey);
				return code;
			}
			const dataFound = _.find(data, { code });

			if (_.isNil(dataFound)) {
				console.log('no existe del datakey en los data de evo' + dataKey, code);
				return code;
			}

			if (type === 'API') {
				return dataFound.code;
			} else {
				return dataFound.name;
			}
		}
	};

	const renderText = (list) => {
		list = list.replace(/\n/g, '<br />');

		return list;
	};

	const onFinish = (values) => {
		setLoading(true);
		let valuesFormat = {
			holders: [],
			housingData: [],
			otherValues: {}
		};

		let duplicate = [];
		let duplicates = [];

		if (mortgage.mortgage.participants > 1) {
			for (let index = 1; index <= parseInt(mortgage.mortgage.participants); index++) {
				if (values[`${'phone' + index}`].toString()) {
					duplicate.push(values[`${'phone' + index}`].toString());
				}
				if (values[`${'email' + index}`].toString()) {
					duplicate.push(values[`${'email' + index}`].toString());
				}
			}
			const findDuplicates = (duplicate) =>
				duplicate.filter((item, index) => duplicate.indexOf(item) !== index);
			duplicates = findDuplicates(duplicate);
		}

		if (duplicates.length > 0) {
			notification.error({
				message: 'Error al enviar',
				description: 'Comprobar que el email y el telefono de cada titular es distinto'
			});
			setLoading(false);
		}

		if (duplicates.length === 0) {
			for (let index = 1; index <= parseInt(mortgage.mortgage.participants); index++) {
				valuesFormat.holders.push({
					personalData: {
						name: values[`${'name' + index}`].toString(),
						firstLastName: values[`${'firstLastName' + index}`].toString(),
						secondLastName: values[`${'secondLastName' + index}`].toString(),
						birthDate: moment(values[`${'birthDate' + index}`], 'DD-MM-YYYY').format('YYYY-MM-DD'),
						phone: values[`${'phone' + index}`].toString(),
						email: values[`${'email' + index}`].toString(),
						externalId: values[`${'externalId' + index}`].toString(),
						civilStatus: renderNameByCode('civilStatus', values[`${'civilStatus' + index}`]),
						citizenshipCountry: renderNameByCode(
							'countries',
							values[`${'citizenshipCountry' + index}`].toString()
						),
						yearsCountry: values[`${'yearsCountry' + index}`],
						birthProvince: values[`${'birthProvince' + index}`],
						birthLocality: values[`${'birthLocality' + index}`],
						countryResidence: renderNameByCode(
							'countries',
							values[`${'countryResidence' + index}`]
						),
						documentExpiryDate: !_.isEmpty(values[`${'documentExpiryDate' + index}`])
							? moment(values[`${'documentExpiryDate' + index}`], 'DD-MM-YYYY').format('YYYY-MM-DD')
							: undefined,
						numberSons: values[`${'numberSons' + index}`],
						economicRegime: renderNameByCode(
							'economicRegime',
							values[`${'economicRegime' + index}`]
						)
					},
					contactData: {
						streetType: renderNameByCode(
							'streetTypes',
							values[`${'streetType' + index}`].toString()
						),
						address: values[`${'address' + index}`].toString(),
						postalCode: values[`${'postalCode' + index}`].toString(),
						currentHouseStatus: renderNameByCode(
							'currentHouseStatus',
							values[`${'currentHouseStatus' + index}`].toString()
						),
						houseAdquisitionYear: !_.isEmpty(values[`${'houseAdquisitionYear' + index}`])
							? moment(values[`${'houseAdquisitionYear' + index}`], 'DD-MM-YYYY').format('YYYY')
							: undefined,
						currentHouseRentStartDate: !_.isEmpty(values[`${'currentHouseRentStartDate' + index}`])
							? moment(values[`${'currentHouseRentStartDate' + index}`], 'DD-MM-YYYY').format(
								'YYYY-MM-DD'
							)
							: undefined,
						monthlyRentExpenses:
							values[`${'monthlyRentExpenses' + index}`] !== undefined
								? parseInt(values[`${'monthlyRentExpenses' + index}`])
								: undefined
					},
					employmentData: {
						workSituation: renderNameByCode(
							'workSituations',
							values[`${'workSituation' + index}`].toString()
						),
						contractType: renderNameByCode('contractTypes', values[`${'contractType' + index}`]),
						paymentsNumber:
							values[`${'paymentsNumber' + index}`] !== undefined
								? parseInt(values[`${'paymentsNumber' + index}`])
								: values[`${'paymentsNumber' + index}`],
						monthlyIncome:
							values[`${'monthlyIncome' + index}`] !== undefined
								? parseInt(values[`${'monthlyIncome' + index}`])
								: undefined,
						monthlyExpenses:
							values[`${'monthlyExpenses' + index}`] !== undefined
								? parseInt(values[`${'monthlyExpenses' + index}`])
								: undefined,
						variableAnnualIncome:
							values[`${'variableAnnualIncome' + index}`] !== undefined
								? parseInt(values[`${'variableAnnualIncome' + index}`])
								: undefined,
						companyName: values[`${'companyName' + index}`],
						currentPosition: values[`${'currentPosition' + index}`],
						yearsWorked:
							values[`${'yearsWorked' + index}`] !== undefined
								? parseInt(values[`${'yearsWorked' + index}`])
								: undefined,
						workStartDate:
							values[`${'workStartDate' + index}`] !== undefined
								? moment(values[`${'workStartDate' + index}`], 'DD-MM-YYYY').format('YYYY-MM-DD')
								: undefined,
						companyStartDate:
							values[`${'companyStartDate' + index}`] !== undefined
								? moment(values[`${'companyStartDate' + index}`], 'DD-MM-YYYY').format('YYYY-MM-DD')
								: undefined,
						professionalActivity:
							values[`${'professionalActivity' + index}`] !== undefined
								? renderNameByCode('cnos', values[`${'professionalActivity' + index}`])
								: undefined,
						freelanceStartDate:
							values[`${'freelanceStartDate' + index}`] !== undefined
								? moment(values[`${'freelanceStartDate' + index}`], 'DD-MM-YYYY').format(
									'YYYY-MM-DD'
								)
								: undefined,
						cnae:
							values[`${'cnae' + index}`] !== undefined
								? renderNameByCode('cnaes', values[`${'cnae' + index}`])
								: undefined
					}
				});
			}

			valuesFormat.housingData = {
				address: values[`${'address'}`].toString(),
				streetNumber: values[`${'streetNumber'}`].toString(),
				postalCode: values[`${'postalCode'}`].toString()
			};
			valuesFormat.otherValues = {
				buyingPrice: parseInt(values.buyingPrice),
				loanAmount: parseInt(values.loanAmount),
				mortgageYears: parseInt(values.mortgageYears),
				buyingType: renderNameByCode('buyingTypes', values.buyingType.toString()),
				houseSize: parseInt(values.houseSize),
				constructionType: renderNameByCode('constructionTypes', values.constructionType),
				propertyType: renderNameByCode('propertyTypes', values.propertyType),
				whenToBuy: renderNameByCode('whenToBuy', values.whenToBuy),
				mortgageType: renderNameByCode('mortgageTypes', values.mortgageType),
				mortgagePurpose: renderNameByCode('mortgagePurpose', values.mortgagePurpose),
				previousChargeAmount: parseInt(values.previousChargeAmount),
				firstHolderRelationship:
					values.firstHolderRelationship !== undefined
						? renderNameByCode('holderRelationship', values.firstHolderRelationship.toString())
						: undefined
			};

			/*if (type !== 'API') {
				axios
					.post(
						`${env.api.url}/banks/evo/update-custom-request/${bankRequestId}`,
						{
							holders: valuesFormat.holders,
							housingData: { ...valuesFormat.housingData },
							...valuesFormat.otherValues
						},
						{
							headers: { Authorization: `Bearer ${authClient.getToken()}` }
						}
					)
					.then((response) => {
						setLoading(false);

						let locationTmp = {
							pathname: `/operations/mortgages/${mortgageId}/email-send-evo`,
							state: {
								bankId: idBank,
								bankRequestId: bankRequestId,
								bankRequest: bankRequest,
								response: false
							}
						};
						navigate(locationTmp);
					})
					.catch((error) => {
						notification.error({
							message: 'Error al enviar, revisa los datos',
							description: error.data
						});
					});
			} else {*/
			axios
				.post(
					`${env.api.url}/banks/evo/prescoring/execute-process`,
					{
						bankRequestId: bankRequestId,
						evoRequest: {
							holders: valuesFormat.holders,
							housingData: { ...valuesFormat.housingData },
							...valuesFormat.otherValues
						}
					},
					{
						headers: { Authorization: `Bearer ${authClient.getToken()}` }
					}
				)
				.then((response) => {
					if (response.data.data.error === true) {
						notification.error({
							message: 'Envio fallido',
							description: (
								<div
									dangerouslySetInnerHTML={{
										__html: renderText(response.data.data.errorReasons)
									}}></div>
							),
							duration: 0
						});
						setLoading(false);
					} else {
						setLoading(false);
						navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
					}
				})
				.catch((error) => {
					notification.error({
						message: 'Error al enviar, revisa los datos',
						description: error.data
					});
				});
			//}
		}
	};

	const cancelSend = () => {
		setVisibleCancel(!visibleCancel);
	};
	const handleOk = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/send-to-bank`, {
			state: { bankId: idBank, bankRequestId: bankRequestId, bankRequest: bankRequest }
		});
	};

	const handleOk2 = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};
	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};

	const renderBuying = (type) => {
		const typesHome = [
			{ code: 'B08', name: 'main' },

			{ code: 'B10', name: 'second' },

			{ code: 'B11', name: 'investor' }
		];

		const option = _.find(typesHome, function (o) {
			return o.name === type;
		});

		return option?.code;
	};

	const renderProperty = (type) => {
		const typesHome = [
			{ code: '01', name: 'newConstruction' },

			{ code: '02', name: 'secondHand' }
		];

		const option = _.find(typesHome, function (o) {
			return o.name === type;
		});

		return option?.code;
	};

	const renderMarital = (type) => {
		if (type !== undefined) {
			const typesCivil = [
				{ code: '01', name: 'single' },
				{ code: '02', name: 'Separado' },
				{ code: '03', name: 'divorced' },
				{ code: '04', name: 'widowed' },
				{ code: '05', name: 'married_property' },
				{ code: '05', name: 'married_separation' },
				{ code: '06', name: 'Pareja de hecho' }
			];

			const option = _.find(typesCivil, function (o) {
				return o.name === type;
			});

			return option?.code;
		} else {
			return undefined;
		}
	};

	const renderWork = (owner) => {
		if (owner !== undefined) {
			const work = [
				{ code: '01', name: 'EMPLOYEE_FIXED' },
				{ code: '01', name: 'EMPLOYEE_TEMPORAL' },
				{ code: '12', name: 'FUNCTIONARY' },
				{ code: '12', name: 'FUNCTIONARY_INTERIM' },
				{ code: '02', name: 'FREELANCER' },
				{ code: '02', name: 'BUSINESS_OWNER' },
				{ code: '11', name: 'RENTIER' },
				{ code: '04', name: 'UNEMPLOYED' },
				{ code: '05', name: 'PENSIONER' }
			];

			const option = _.find(work, function (o) {
				return o.name === owner.activity;
			});

			return option?.code;
		} else {
			return '';
		}
	};

	const renderPrice = () => {
		if (mortgage.subrogation) {
			return mortgage.subrogation?.maxBudget;
		} else {
			return mortgage.mortgage?.maxBudget;
		}
	};

	const renderMonthlyExpenses = (owner) => {
		const ownerMortgage = mortgage[owner];
		const mortgagePayment = ownerMortgage && ownerMortgage.mortgagePayment ? ownerMortgage.mortgagePayment : 0;
		const loanPayment = ownerMortgage && ownerMortgage.loanPayment ? ownerMortgage.loanPayment : 0;
		const pensionPayment = ownerMortgage && ownerMortgage.pensionPayment ? ownerMortgage.pensionPayment : 0;
		const totalExpenses = mortgagePayment + loanPayment + pensionPayment;

		return totalExpenses;
	};
	const renderEconomicRegime = (owner) => {
		const ownerMortgage = mortgage[owner];
		if (ownerMortgage.maritalStatus === 'married_property') {
			return '01'
		} else if (ownerMortgage.maritalStatus === 'married_separation') {
			return '02'
		} else {
			return undefined
		}
	};

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}

	return (
		<>
			<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
				Datos de la operación
			</Text>
			<Form
				form={form}
				layout="vertical"
				name="sendMessageForm"
				style={{ width: '100%' }}
				initialValues={{
					email1: mortgage['owner1'].email !== undefined ? mortgage['owner1'].email : '',
					email2: mortgage['owner2'] !== undefined ? mortgage['owner2'].email : '',
					name1: mortgage['owner1'].name !== undefined ? mortgage['owner1'].name : '',
					name2: mortgage['owner2'] !== undefined ? mortgage['owner2'].name : '',
					birthDate1:
						mortgage['owner1'].birthdate !== undefined
							? moment(mortgage['owner1'].birthdate, 'YYYY-MM-DD').format('DD-MM-YYYY')
							: '',
					birthDate2:
						mortgage['owner2'] !== undefined
							? moment(mortgage['owner2'].birthdate, 'YYYY-MM-DD').format('DD-MM-YYYY')
							: '',
					externalId1: mortgage['owner1'].nif !== undefined ? mortgage['owner1'].nif : '',
					externalId2: mortgage['owner2'] !== undefined ? mortgage['owner2'].nif : '',
					civilStatus1: renderMarital(mortgage['owner1'].maritalStatus),
					civilStatus2:
						mortgage['owner2'] !== undefined
							? renderMarital(mortgage['owner2'].maritalStatus)
							: undefined,
					paymentsNumber1:
						mortgage['owner1'].numberOfPayments !== undefined
							? mortgage['owner1'].numberOfPayments
							: '',
					paymentsNumber2:
						mortgage['owner2'] !== undefined ? mortgage['owner2'].numberOfPayments : '',
					variableAnnualIncome1:
						mortgage['owner1'].bonus !== undefined ? mortgage['owner1'].bonus : '',
					variableAnnualIncome2: mortgage['owner2'] !== undefined ? mortgage['owner2'].bonus : '',

					buyingPrice: renderPrice(),
					mortgageYears: bankRequest !== undefined ? bankRequest?.months / 12 : undefined,
					phone1: mortgage['owner1'].phone !== undefined ? mortgage['owner1'].phone : '',
					phone2: mortgage['owner2'] !== undefined ? mortgage['owner2'].phone : '',
					buyingType:
						mortgage?.mortgage?.houseType !== undefined
							? renderBuying(mortgage.mortgage.houseType)
							: 'B08',
					propertyType:
						mortgage?.mortgage?.propertyType !== undefined
							? renderProperty(mortgage.mortgage.propertyType)
							: '02',
					monthlyIncome1: mortgage['owner1'].netIncomes !== undefined ? mortgage['owner1'].netIncomes : '',
					monthlyIncome2: mortgage['owner2'] !== undefined ? mortgage['owner2'].netIncomes : '',
					citizenshipCountry1: mortgage['owner1'].nationality === 'ES' ? '011' : undefined,
					citizenshipCountry2: mortgage['owner2'] !== undefined ? '011' : undefined,
					countryResidence1: mortgage['owner1'].cityzenship === 'ES' ? '011' : undefined,
					countryResidence2: mortgage['owner2'] !== undefined ? '011' : undefined,
					contractType1:
						mortgage['owner1'].activity === 'EMPLOYEE_FIXED' ||
							mortgage['owner1'].activity === 'FUNCTIONARY'
							? '01'
							: mortgage['owner1'].activity === 'EMPLOYEE_TEMPORAL' ||
								mortgage['owner1'].activity === 'FUNCTIONARY_INTERIM'
								? '02'
								: '09',
					contractType2:
						mortgage['owner2'] !== undefined &&
							(mortgage['owner2'].activity === 'EMPLOYEE_FIXED' ||
								mortgage['owner2'].activity === 'FUNCTIONARY')
							? '01'
							: mortgage['owner2'] !== undefined &&
								(mortgage['owner2'].activity === 'EMPLOYEE_TEMPORAL' ||
									mortgage['owner2'].activity === 'FUNCTIONARY_INTERIM')
								? '02'
								: '09',
					monthlyExpenses1: renderMonthlyExpenses('owner1'),
					monthlyExpenses2: renderMonthlyExpenses('owner2'),
					workSituation1: renderWork(mortgage['owner1']),
					workSituation2: mortgage['owner2'] ? renderWork(mortgage['owner2']) : undefined,
					address: mortgage.subrogation !== undefined
						? mortgage.subrogation.address
						: mortgage.mortgage.address,
					numberSons1: mortgage['owner1'].numberSons,
					numberSons2: mortgage['owner2'] !== undefined ? mortgage['owner2'].numberSons : undefined,
					companyName1: mortgage['owner1'].companyName,
					companyName2: mortgage['owner2'] !== undefined ? mortgage['owner2'].companyName : undefined,
					currentPosition1: mortgage['owner1'].currentProfession,
					currentPosition2: mortgage['owner2'] !== undefined ? mortgage['owner2'].currentProfession : undefined,
					yearsWorked1:
						mortgage['owner1']?.employment?.totalDaysContributed !== undefined
							? parseInt(mortgage['owner1']?.employment?.totalDaysContributed / 365)
							: undefined,
					yearsWorked2:
						mortgage['owner2'] !== undefined &&
							parseInt(mortgage['owner2']?.employment?.totalDaysContributed) !== undefined
							? parseInt(mortgage['owner2']?.employment?.totalDaysContributed / 365)
							: undefined,
					loanAmount: bankRequest !== undefined ? bankRequest?.amount : undefined,
					whenToBuy: '01',
					documentExpiryDate1:
						mortgage['owner1'].validityDate !== undefined
							? moment(mortgage['owner1'].validityDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
							: undefined,
					documentExpiryDate2:
						mortgage['owner2'] !== undefined && mortgage['owner2'].validityDate !== undefined
							? moment(mortgage['owner2'].validityDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
							: undefined,
					address1: mortgage['owner1'].address,
					address2: mortgage['owner2'] !== undefined ? mortgage['owner2'].address : undefined,
					currentHouseStatus1: mortgage['owner1'].currentlyLiving === 'RENTAL' ? '2' : undefined,
					currentHouseStatus2:
						mortgage['owner2'] !== undefined
							? mortgage['owner2'].currentlyLiving === 'RENTAL'
								? '2'
								: undefined
							: undefined,
					workStartDate1: mortgage['owner1']?.employment?.dateSeniority !== undefined
						? moment(mortgage['owner1']?.employment?.dateSeniority, 'YYYY-MM-DD').format('DD-MM-YYYY')
						: undefined,
					workStartDate2: mortgage['owner2'] !== undefined && mortgage['owner2']?.employment?.dateSeniority !== undefined
						? moment(mortgage['owner2']?.employment?.dateSeniority, 'YYYY-MM-DD').format('DD-MM-YYYY')
						: undefined,
					companyStartDate1: mortgage['owner1']?.employment?.dateSeniorityCompany !== undefined
						? moment(mortgage['owner1']?.employment?.dateSeniorityCompany, 'YYYY-MM-DD').format('DD-MM-YYYY')
						: undefined,
					companyStartDate2: mortgage['owner2'] !== undefined && mortgage['owner2']?.employment?.dateSeniorityCompany !== undefined
						? moment(mortgage['owner2']?.employment?.dateSeniorityCompany, 'YYYY-MM-DD').format('DD-MM-YYYY')
						: undefined,
					postalCode: mortgage.subrogation !== undefined
						? mortgage?.subrogation?.postalCode
						: mortgage?.propertyWishPurchase?.postalCode,
					postalCode1: mortgage['owner1'].postalCode,
					postalCode2: mortgage['owner2'] !== undefined ? mortgage['owner2']?.postalCode : undefined,
					constructionType: 'B3',
					houseSize: 100,
					mortgageType: '01',
					mortgagePurpose: mortgage.subrogation !== undefined ? undefined : 'B08',
					previousChargeAmount: 0,
					firstLastName1: mortgage['owner1']?.firstSurname,
					firstLastName2: mortgage['owner2'] !== undefined ? mortgage['owner2']?.firstSurname : undefined,
					secondLastName1: mortgage['owner1']?.lastSurname,
					secondLastName2: mortgage['owner2'] !== undefined ? mortgage['owner2']?.lastSurname : undefined,
					monthlyRentExpenses1: mortgage['owner1']?.rentalAmount ? mortgage['owner1'].rentalAmount : undefined,
					monthlyRentExpenses2: mortgage['owner2']?.rentalAmount ? mortgage['owner2'].rentalAmount : undefined,
					economicRegime1: mortgage['owner1']?.maritalStatus ? renderEconomicRegime('owner1') : undefined,
					economicRegime2: mortgage['owner2']?.maritalStatus ? renderEconomicRegime('owner2') : undefined,
				}}
				onChange={handleFormChange}
				onFinish={onFinish}>
				<div style={{ marginTop: 24 }}>
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>Sección general</Text>
				</div>
				<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'buyingPrice'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Importe de vivienda (€)'}
								</Text>
							}
							className={emptyFields.includes('buyingPrice') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber
								gbDecimalType="integer"
								gbType="currency"
								style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'loanAmount'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Importe de hipoteca'}
								</Text>
							}
							className={emptyFields.includes('loanAmount') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber
								gbDecimalType='integer'
								gbType='currency'
								style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'mortgageYears'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Plazo de financiación (años)'}
								</Text>
							}
							className={emptyFields.includes('mortgageYears') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber
								gbType='number'
								className="gb-input-new-years"
								style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item
							style={{ width: '100%' }}
							name={'buyingType'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									Tipo de compra
								</Text>
							}
							className={emptyFields.includes('buyingType') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<Select 
								showSearch style={{ width: '100%', borderRadius: 4 }} 
								onChange={handleFormChange}
								placeholder="Seleccione">
								{otherDatasAll &&
									otherDatasAll.buyingTypes.map((item) => (
										<Option key={item.code} value={item.code}>
											{item.name}
										</Option>
									))}
							</Select>
						</Form.Item>
					</Col>

					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'houseSize'}
							label={
								<div style={{ display: 'flex' }}>
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tamaño de la propiedad'}
									</Text>
									<Tooltip title={'Tamaño de la propiedad'}>
										<img
											src={'/images/tooltip.png'}
											style={{ height: 12, width: 12, marginLeft: 4 }}></img>
									</Tooltip>
								</div>
							}
							className={emptyFields.includes('houseSize') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber
								gbType='number'
								style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'constructionType'}
							label={
								<div style={{ display: 'flex' }}>
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Tipo de construcción'}
									</Text>
									<Tooltip title={'Tipo de construcción de la propiedad'}>
										<img
											src={'/images/tooltip.png'}
											style={{ height: 12, width: 12, marginLeft: 4 }}></img>
									</Tooltip>
								</div>
							}
							className={emptyFields.includes('constructionType') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<Select
								showSearch
								onChange={handleFormChange}
								placeholder="Seleccione"
								style={{ width: '100%', borderRadius: 4 }}>
								{otherDatasAll &&
									otherDatasAll.constructionTypes.map((item) => (
										<Option key={item.code} value={item.code}>
											{item.name}
										</Option>
									))}
							</Select>
						</Form.Item>
					</Col>

					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'propertyType'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									Tipo de vivienda
								</Text>
							}
							className={emptyFields.includes('propertyType') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<Select
								showSearch
								onChange={handleFormChange}
								placeholder="Seleccione"
								style={{ width: '100%', borderRadius: 4 }}>
								{otherDatasAll &&
									otherDatasAll.propertyTypes.map((item) => (
										<Option key={item.code} value={item.code}>
											{item.name}
										</Option>
									))}
							</Select>
						</Form.Item>
					</Col>

					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'whenToBuy'}
							label={
								<div style={{ display: 'flex' }}>
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										{'Cuándo compra …'}
									</Text>
									<Tooltip
										title={
											'Información de la operación y que es lo que se desea por parte del cliente'
										}>
										<img
											src={'/images/tooltip.png'}
											style={{ height: 12, width: 12, marginLeft: 4 }}></img>
									</Tooltip>
								</div>
							}
							className={emptyFields.includes('whenToBuy') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<Select
								showSearch
								onChange={handleFormChange}
								placeholder="Seleccione"
								style={{ width: '100%', borderRadius: 4 }}>
								{otherDatasAll &&
									otherDatasAll.whenToBuy.map((item) => (
										<Option key={item.code} value={item.code}>
											{item.name}
										</Option>
									))}
							</Select>
						</Form.Item>
					</Col>

					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'mortgageType'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									Tipo de Hipoteca
								</Text>
							}
							className={emptyFields.includes('mortgageType') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<Select
								showSearch
								onChange={handleFormChange}
								placeholder="Seleccione"
								style={{ width: '100%', borderRadius: 4 }}>
								{otherDatasAll &&
									otherDatasAll.mortgageTypes.map((item) => (
										<Option key={item.code} value={item.code}>
											{item.name}
										</Option>
									))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'mortgagePurpose'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									Finalidad de la Hipoteca
								</Text>
							}
							className={emptyFields.includes('mortgagePurpose') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<Select
								showSearch
								onChange={handleFormChange}
								placeholder="Seleccione"
								style={{ width: '100%', borderRadius: 4 }}>
								{otherDatasAll &&
									otherDatasAll.mortgagePurpose.map((item) => (
										<Option key={item.code} value={item.code}>
											{item.name}
										</Option>
									))}
							</Select>
						</Form.Item>
					</Col>

					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'previousChargeAmount'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Cargos precios sobre la propiedad (€)'}
								</Text>
							}
							className={emptyFields.includes('previousChargeAmount') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber
								gbType='currency'
								gbDecimalType='integer'
								style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>

					{parseInt(mortgage.mortgage.participants) > 1 && (
						<Col span={6} style={{ marginTop: -10 }}>
							<Form.Item
								style={{ width: '100%' }}
								name={'firstHolderRelationship'}
								label={
									<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
										Relacion entre los titulares
									</Text>
								}
								className={emptyFields.includes('firstHolderRelationship') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
								<Select
									showSearch
									onChange={handleFormChange}
									placeholder="Seleccione"
									style={{ width: '100%', borderRadius: 4 }}>
									{otherDatasAll &&
										otherDatasAll.holderRelationship.map((item) => (
											<Option key={item.code} value={item.code}>
												{item.name}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>
					)}
				</Row>

				<div>
					<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
						Información de la vivienda
					</Text>
				</div>
				<Row gutter={[32, 0]} style={{ marginTop: 24 }}>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'address'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Nombre de la Calle'}
								</Text>
							}
							className={emptyFields.includes('address') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<Input style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'streetNumber'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Número del domicilio'}
								</Text>
							}
							className={emptyFields.includes('streetNumber') ? 'shadowed' : ''}
							rules={[{ required: true, message: 'Por favor, rellene este campo' }]}>
							<InputNumber
								gbType='number'
								style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
					<Col span={6} style={{ marginTop: -10 }}>
						<Form.Item
							style={{ width: '100%' }}
							name={'postalCode'}
							label={
								<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
									{'Código Postal del domicilio'}
								</Text>
							}
							className={emptyFields.includes('postalCode') ? 'shadowed' : ''}
							rules={[
								{ required: true, message: 'Por favor, rellene este campo' },
								{ pattern: /^\d{5}$/, message: 'El código postal debe tener 5 dígitos' }
							]}>
							<Input style={{ width: '100%', borderRadius: 4 }} />
						</Form.Item>
					</Col>
				</Row>

				{renderForm()}

				<Row style={{ justifyContent: 'center', marginTop: 20 }}>
					<Button
						style={{ height: 32, width: 96, fontSize: 14 }}
						className="button-secundary-gibobs"
						onClick={() => cancelSend()}
						type="dashed">
						Cancelar
					</Button>

					<Button
						style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
						type="primary"
						htmlType="submit"
						className="button-primari-gibobs">
						Aceptar
					</Button>
				</Row>
			</Form>
			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{ className: 'button-secundary-gibobs', style: { width: 96 } }}
				okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96 } }}
				open={visibleCancel}
				title={
					<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
						{'Cancelar envío'}
					</Text>
				}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				width={404}
				onCancel={handleCancel}
				onOk={handleOk2}>
				<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858' }}>
					{'¿Aceptas la cancelación del envio a EVO bank?'}
				</Text>
			</Modal>
		</>
	);
};
export default TableEvo;
