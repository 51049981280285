import React, { useState, useEffect } from 'react';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import {
	Row,
	Spin,
	Col,
	Table,
	Input,
	Typography,
	Card,
	Button,
	Popconfirm,
	Collapse,
	Radio,
	notification,
	Modal
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { CheckOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { getOperationDocuments } from '../../../../../store/documents/actions';
import LinkContact from './LinkContact';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

const { Search } = Input;
const { Text } = Typography;
const { Panel } = Collapse;

export default (props) => {
	const [dataParticipants, setDataParticipants] = useState();
	const [dataGuarantors, setDataGuarantors] = useState();
	const {
		mortgageId,
		owner,
		index,
		mortgageDataAll,
		contactPreferences,
		mortgageData,
		mortgage,
		nameMortgage,
		documentUploads,
		infoProfile,
		loadDocumentsData
	} = props;
	const token = authClient.getToken();
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const [modalDocViewer, setModalDocViewer] = useState();
	const [confirmLoading, setConfirmLoading] = useState(true);
	const [profile, setProfile] = useState({});
	const [value, setValue] = useState(undefined);
	const [showFiles, setShowFiles] = useState(false);
	const [selected, setSelected] = useState(undefined);
	const [counterDocuments, setCounterDocuments] = useState(0);
	const [dataTraceClient, setDataTraceClient] = useState([]);
	const [other, setOthers] = useState(0);
	const documentsNeed = useSelector((state) => state.documentation.needed);
	const documentsCompleted = useSelector((state) => state.documentation.completed);
	const documents = useSelector((state) => state.documentation.documents);
	const groups = useSelector((state) => state.documentation.groups);
	const loading = useSelector((state) => state.documentation.loading);
	const dispatch = useDispatch();

	useEffect(() => {
		setProfile(authClient.getProfile());
		getTraces();
		if (mortgageData) {
			setDataParticipants(parseFloat(mortgageData.mortgage.participants));
			setDataGuarantors(parseFloat(mortgageData.mortgage.guarantors));
		}
	}, [mortgageId, showFiles]);

	useEffect(() => {
		loadDocumentsData();
	}, [documentsNeed, documentsCompleted, showFiles]);

	const resetFile = (file) => {
		let body = {
			operationId: mortgageId,
			documentId: file.id
		};

		axios
			.post(`${env.api.url}/v1/documents/reset-document`, body, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then((response) => {
				loadDocumentsData();
			});
	};

	const handleClickShow = () => {
		setShowFiles(!showFiles);
	};

	const getTraces = () => {
		axios
			.post(
				`${env.api.url}/v1/hadmin/trays/get-trays`,
				{ operationId: mortgageId },
				{
					headers: { Authorization: `Bearer ${token}` }
				}
			)
			.then((response) => {
				setDataTraceClient(response.data.trays);
			});
	};

	const removeFile = async (documentId) => {
		const response = await axios.get(`${env.api.url}/v1/documents/delete-document/${documentId}`, {
			headers: { Authorization: `Bearer ${token}` }
		});

		loadDocumentsData();
	};

	const loadSignedDocument = async (documentId) => {
		const response = await axios.get(`${env.api.url}/v1/documents/get-document-url/${documentId}`, {
			headers: { Authorization: `Bearer ${token}` }
		});

		setModalDocViewer(response.data.data.url);
		setConfirmLoading(false);

		const responseFile = await axios.get(response.data.data.url, {
			responseType: 'blob'
		});

		return {
			...response.data.data,
			fileData: responseFile.data
		};
	};

	const updateDocumentType = (document, value, participant) => {
		let { participants } = document;

		participants[participant] = value;
		const jsonString = JSON.stringify(participants);

		axios
			.post(
				`${env.api.url}/v1/document-types/add`,
				{
					description: jsonString,
					operationType: document.operationType,
					name: document.name,
					mandatoryCondition: `const jsonString = ${jsonString};
      mandatoryType = jsonString[participantCode] || 'forbidden'`,
					operationId: mortgageId
				},
				{
					headers: { Authorization: `Bearer ${token}` }
				}
			)
			.then((response) => {
				loadDocumentsData();
				props.loadDocumentsData();
			});
	};

	const addDocumentType = (groupKey, value) => {
		axios
			.post(
				`${env.api.url}/v1/document-types/create-operation-document`,
				{
					title: value,
					name: value,
					operationId: mortgageId,
					operationType: 'mortgage',
					group: groupKey
				},
				{
					headers: { Authorization: `Bearer ${token}` }
				}
			)
			.then((response) => {
				notification.success({
					message: 'Archivo añadido correctamente'
				});
				setSelected(undefined);
				setOthers(12);
				loadDocumentsData();
			});
	};

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}

	const generateColumns = (title) => {
		const columnsFiles = [
			{
				title,
				dataIndex: 'title',
				key: 'title',
				width: '20%'
			}
		];

		for (let i = 1; i <= dataParticipants; i++) {
			columnsFiles.push({
				title: `Titular ${i}`,
				dataIndex: `owner${i}`,
				key: `owner${i}`,
				width: '10%',
				align: 'center',
				render: (key, document) => renderDocumentation(key, document, `owner${i}`)
			});
		}

		for (let i = 0; i < dataGuarantors; i++) {
			columnsFiles.push({
				title: `Avalista ${i + 1}`,
				dataIndex: `guarantor${i + 1}`,
				key: `guarantor${i + 1}`,
				width: '10%',
				align: 'center',
				render: (key, document) => renderDocumentation(key, document, `guarantor${i + 1}`)
			});
		}

		return columnsFiles;
	};

	const renderDocumentation = (document, participant, value) => {
		const documentNeed = document.participants[participant] || 'forbidden';
		const completed = document.completed[participant];
		setValue(documentNeed);
		props.loadDocumentsData();

		if (!completed) {
			updateDocumentType(document, value, participant);
		}
	};

	const tables = [];
	const groupKeys = Object.keys(groups);

	groupKeys.forEach((groupKey) => {
		const group = groups[groupKey];
		const filteredDocuments = documents.filter((document) => document.group === groupKey);
		filteredDocuments.push({
			title: (
				<Search
					readOnly={statusJob === 'finish' ? true : false}
					disabled={statusJob === 'finish' ? true : false}
					placeholder="Nuevo documento"
					allowClear
					enterButton="Añadir"
					onSearch={(value) => {
						addDocumentType(groupKey, value);
					}}
				/>
			)
		});

		tables.push(
			<Table
				size="small"
				key={`table-${groupKey}`}
				bordered
				dataSource={filteredDocuments}
				columns={generateColumns(group.title)}
				pagination={false}
				rowClassName={() => {
					return ['small-font-size'];
				}}
			/>
		);
	});

	const nameOwners = {
		owner: 'Titular',
		guarantor: 'Avalista'
	};
	const activities = {
		EMPLOYEE_FIXED: 'Asalariado fijo',
		EMPLOYEE_TEMPORAL: 'Asalariado temporal',
		FREELANCER: 'Autonomo',
		UNEMPLOYED: 'Desempleado',
		BUSINESS_OWNER: 'Empresario',
		FUNCTIONARY: 'Funcionario',
		FUNCTIONARY_INTERIM: 'Funcionario Interino',
		PENSIONER: 'Pensionista',
		RENTIER: 'Rentista'
	};

	const documentRender = (document, owner) => {
		const valueRadio = document.participants[owner.ownerType + owner.ownerNumber];
		return (
			<>
				<Row style={{ marginTop: 10 }}>
					<Col lg={10} xs={24}>
						<Text style={{ color: '#2F4858', fontSize: 12, fontWeight: 400 }}>
							{document.title}
						</Text>
					</Col>
					<Col lg={14} xs={24} style={{ alignSelf: 'center' }}>
						<Radio.Group
							onChange={(e) => {
								renderDocumentation(document, owner.ownerType + owner.ownerNumber, e.target.value);
							}}
							value={valueRadio}
							style={{ float: 'right' }}>
							<Radio defaultChecked={valueRadio === 'required' ? true : false} value={'required'}>
								{' '}
								<Text
									style={{
										color: valueRadio === 'required' ? '#2F4858' : '#748EA0',
										fontSize: 12,
										fontWeight: 400
									}}>
									Prioritario
								</Text>
							</Radio>
							<Radio defaultChecked={valueRadio === 'optional' ? true : false} value={'optional'}>
								<Text
									style={{
										color: valueRadio === 'optional' ? '#2F4858' : '#748EA0',
										fontSize: 12,
										fontWeight: 400
									}}>
									Adicional
								</Text>
							</Radio>
							<Radio defaultChecked={valueRadio === 'forbidden' ? true : false} value={'forbidden'}>
								<Text
									style={{
										color: valueRadio === 'forbidden' ? '#2F4858' : '#748EA0',
										fontSize: 12,
										fontWeight: 400
									}}>
									N/A
								</Text>
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
			</>
		);
	};

	const renderViewFile = (name, owner) => {
		const url = `${env.api.viewer}/api/document/${document.key}/attachment?token=${token}`;

		let eyes = [];

		documentUploads.map((doc, index) => {
			if (name === doc.type && doc.owner === owner.ownerType + owner.ownerNumber) {
				if (eyes.length < 4) {
					eyes.push(
						<Button
							//disabled={statusJob === 'finish' ? true : false}
							type="link"
							onClick={async () => {
								const { url, name, mime, fileData } = await loadSignedDocument(doc.id);

								const modal = Modal.confirm({
									title: 'Visualizar fichero',
									content: (
										<DocViewer
											pluginRenderers={DocViewerRenderers}
											documents={[{ uri: url, fileType: mime, fileData: fileData }]}
										/>
									),
									closable: true,
									okCancel: true,
									width: '100%',
									onOk: () => {
										document.location = url;
									},
									okText: 'Descargar',
									cancelText: 'Cerrar',
									onCancel: () => {
										modal.destroy();
									}
								});
							}}
							style={{ marginLeft: index !== 0 ? -5 : 5, color: '#02C3CD', width: 16, height: 16 }}>
							<img
								src={'/images/eye.png'}
								alt="eye"
								style={{
									width: 16,
									height: 16
								}}></img>
						</Button>
					);
				}
			}
		});
		if (eyes.length === 4) {
			eyes.push(
				<img
					src={'/images/more.png'}
					alt="more"
					style={{
						width: 16,
						height: 16,
						marginLeft: 8,
						transform: 'rotate(90deg)'
					}}></img>
			);
		}

		return eyes;
	};

	return (
		<>
			<Col lg={12} xs={24} style={{ marginTop: 24 }}>
				<Card className="card-gibobs-blue">
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 600 }}>
								{nameOwners[owner.ownerType]} {owner.ownerNumber}{' '}
							</Text>
							<div style={{ lineHeight: '24px', marginTop: 12, marginBottom: 8 }}>
								<Text
									style={{
										color: '#2F4858',
										fontSize: 16,
										fontWeight: 600
									}}>
									{owner.name === undefined ? 'Sin definir' : owner.name}
								</Text>
							</div>
							<div style={{ lineHeight: '20px' }}>
								<Text
									style={{
										color: '#02C3CD',
										fontSize: 14,
										fontWeight: 400
									}}>
									{activities[owner.activity] === undefined
										? 'Sin definir'
										: activities[owner.activity]}
								</Text>
								&nbsp;
								<Text style={{ color: '##2F4858', fontSize: 14, fontWeight: 400 }}>
									{owner.seniority === undefined ? 'Sin definir' : owner.seniority + ' años'}
								</Text>
							</div>
						</div>
						{owner.ownerType === 'owner' && owner.ownerNumber === 1 && contactPreferences && (
							<LinkContact
								mortgageData={mortgageData}
								contactPreferences={contactPreferences}
								owner={owner}
								name={`${nameOwners[owner.ownerType]}  ${owner.ownerNumber}`}
								mortgageId={mortgageId}
								shopper={
									(mortgage && mortgage.accountEmail) ===
									(mortgageData && mortgageData.owner1 && mortgageData.owner1.email)
								}
								nameMortgage={nameMortgage}
								dataTraceClient={dataTraceClient}
								getTraces={() => getTraces()}
								infoProfile={infoProfile}
							/>
						)}
					</div>

					<Collapse
						expandIconPosition={'right'}
						defaultActiveKey={['1']}
						bordered={false}
						className="collapse-document-gibobs"
						style={{ marginTop: 24 }}>
						<Panel
							className="site-collapse-custom-panel"
							header={
								<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 600, borderBottom: 0 }}>
									Documentación etiquetada o verificada
								</Text>
							}
							style={{
								color: '#2F4858',
								fontSize: 14,
								fontWeight: 600
							}}
							key={'1'}>
							{documents.length > 0 &&
								documents.map((document) => {
									if (document.completed[owner.ownerType + owner.ownerNumber]) {
										return (
											<>
												<Row gutter={20} style={{ marginBottom: 8 }}>
													<Col lg={17} xs={24}>
														{document.completed[owner.ownerType + owner.ownerNumber].isVerified !==
															null &&
														document.completed[owner.ownerType + owner.ownerNumber].isVerified !==
															false ? (
															<CheckOutlined
																style={{
																	color: '#72D00A',
																	fontSize: '10px',
																	marginRight: 7
																}}
															/>
														) : (
															<img
																src={'/images/tag.png'}
																alt="asdf"
																style={{ width: 16, height: 16 }}></img>
														)}

														<Text
															style={{
																color: '#2F4858',
																fontSize: 12,
																fontWeight: 600,
																lineHeight: '16px'
															}}>
															{document.title}
														</Text>
													</Col>

													<Col lg={7} xs={24} style={{ textAlignLast: 'right' }}>
														<div>
															{renderViewFile(document.name, owner)}

															{profile.type === 'risk' ||
															profile.type === 'admin' ||
															profile.type === 'prerisk' ? (
																<Popconfirm
																	disabled={statusJob === 'finish' ? true : false}
																	title="¿Estás seguro de quitar la validación del documento?"
																	onConfirm={() => {
																		resetFile(
																			document.completed[owner.ownerType + owner.ownerNumber]
																		);
																		props.loadDocumentsData();
																	}}
																	okText="Si"
																	cancelText="No">
																	<img
																		src={'/images/tagDelete.png'}
																		alt="tagDelete"
																		style={{
																			width: 16,
																			height: 16,
																			marginLeft: 12,
																			cursor: 'pointer'
																		}}></img>
																</Popconfirm>
															) : undefined}
														</div>
													</Col>
												</Row>
											</>
										);
									}
								})}
						</Panel>
					</Collapse>

					<Collapse
						expandIconPosition={'right'}
						defaultActiveKey={['1']}
						bordered={false}
						className="collapse-document-gibobs"
						style={{ marginTop: 16, marginBottom: 24 }}>
						<Panel
							className="site-collapse-custom-panel"
							header={
								<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 600 }}>
									Documentación solicitada{' '}
								</Text>
							}
							style={{
								color: '#2F4858',
								fontSize: 14,
								fontWeight: 600
							}}
							key={'1'}>
							{documents.length > 0 &&
								documents.map((document, i) => {
									if (
										document.completed[owner.ownerType + owner.ownerNumber] === false &&
										document.participants[owner.ownerType + owner.ownerNumber] === 'required'
									) {
										return (
											<div key={`${document.id}-${i}`}>
												<div
													style={{
														marginBottom: 10,
														fontWeight: 600,
														color: '#2F4858',
														fontSize: '12px',
														lineHeight: '16px'
													}}>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between'
														}}>
														<div>{document.title}</div>
														<div>!</div>
													</div>
												</div>
											</div>
										);
									}
								})}
							{documents.length > 0 &&
								documents.map((document, i) => {
									if (
										document.completed[owner.ownerType + owner.ownerNumber] === false &&
										document.participants[owner.ownerType + owner.ownerNumber] === 'optional'
									) {
										return (
											<div key={`${document.id}-${i}`}>
												<div
													style={{
														fontWeight: 400,
														fontSize: '12px',
														color: '#2F4858',
														marginBottom: 10,
														lineHeight: '16px'
													}}>
													<div>{document.title}</div>
												</div>
											</div>
										);
									}
								})}
						</Panel>
					</Collapse>

					<div style={{ marginBottom: '20px' }}>
						<Text style={{ color: '#02C3CD', fontSize: 14, fontWeight: 600 }}>
							Listado total de documentación
						</Text>

						{!showFiles && (
							<div className="buttonMasMinus" onClick={() => handleClickShow()}>
								<PlusOutlined
									style={{
										color: '#02C3CD',
										fontSize: 14,
										fontWeight: 600
									}}
								/>
							</div>
						)}
						{showFiles && (
							<div className="buttonMasMinus" onClick={() => handleClickShow()}>
								<MinusOutlined
									style={{
										color: '#02C3CD',
										fontSize: 14,
										fontWeight: 600
									}}
								/>
							</div>
						)}
					</div>

					{showFiles &&
						groups.length > 0 &&
						groups.map((group, i) => {
							if (group.hasDocumentTypes) {
								return (
									<Collapse
										key={`${i}`}
										bordered={false}
										expandIconPosition={'right'}
										activeKey={other}
										className="collapse-list-document-gibobs"
										onChange={(value) => setOthers(value)}
										style={{ marginBottom: 8 }}>
										<Panel
											className="site-collapse-custom-panel"
											header={
												<div
													style={{
														color: '#2F4858',
														fontSize: 14,
														fontWeight: 600
													}}>
													{group.title}
												</div>
											}
											key={i}>
											{documents.length > 0 &&
												documents.map((document) => {
													if (group.name === document.group) {
														return documentRender(document, owner);
													}
												})}
										</Panel>
									</Collapse>
								);
							}
						})}

					{showFiles && (
						<Text
							style={{
								color: '#2F4858',
								fontSize: 10,
								fontWeight: 400,
								marginTop: 8
							}}>
							{' '}
							Añadir nueva etiqueta
						</Text>
					)}
					{showFiles && (
						<div style={{ display: 'flex' }}>
							<Search
								readOnly={statusJob === 'finish' ? true : false}
								disabled={statusJob === 'finish' ? true : false}
								placeholder="Nuevo documento"
								allowClear
								value={selected}
								enterButton={
									<Button
										style={{
											color: '#02C3CD',
											borderColor: '#02c3cd',
											borderRadius: 4,
											background: '#EFFCFC'
										}}>
										Añadir
									</Button>
								}
								onSearch={(value) => {
									addDocumentType('others', value);
								}}
								style={{
									marginTop: 8,
									borderColor: '1px solid #C0DAEE',
									borderRadius: 4
								}}
							/>
						</div>
					)}
				</Card>
			</Col>
		</>
	);
};
