import React, { useEffect, useState } from 'react';
import axios from 'axios';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import {
	Button,
	Col,
	Layout,
	message,
	Row,
	Popover,
	Card,
	Modal,
	Form,
	Input,
	Select,
	Typography
} from 'antd';
import MortgageNoteCreate from '../../../../../Components/ModalsAndAlerts/MortgageNoteCreate';
import { useDispatch, useSelector } from 'react-redux';
import { updateStateMortgages } from '../../../../../store/mortgages/actions';
import moment from 'moment';
import SnoozeModalNew from '../../../../../Components/ModalsAndAlerts/SnoozeModalNew';
import { LoadingOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import {
	CheckOutlined,
	DeleteOutlined,
	CalendarOutlined,
	DoubleRightOutlined,
	PauseOutlined,
	DoubleLeftOutlined
} from '@ant-design/icons';
import _ from 'lodash';
import ModalCreateTasks from '../../../../../Components/ModalsAndAlerts/ModalCreateTasks';
import ModalFinishTask from '../../../../../Components/ModalsAndAlerts/ModalFinishTask';
import ModalReScheduleTask from '../../../../../Components/ModalsAndAlerts/ModalReScheduleTask';
import FilterTask from '../common/filterTask';
import StatusWidgets from './StatusWidgets';
import dayjs from 'dayjs';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { MortgageNoteFromImage } from '../common/MortgageNoteFromImage';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');
dayjs.locale('vi');

const { Text } = Typography;
const { Option } = Select;
const { Content } = Layout;

function MortgageDetailStateComponent(props) {
	const statusJob = useSelector((state) => state.formaTasaci.statusJob);
	const dispatch = useDispatch();
	const mortgageUpda = useSelector((state) => state.mortgages.updateMortgages);
	const showModal = useSelector((state) => state.mortgages.showModal);
	const [showMortgageNoteModal, setShowMortgageNoteModal] = useState(false);
	const [showMortgageNoteFromImageModal, setShowMortgageNoteFromImageModal] = useState(false);
	const [profile, setProfile] = useState({});
	const { mortgageId, mortgageData,isTypeFriend } = props;
	const mortgage = props.mortgage || {};
	const [showModalActionFin, setShowModalActionFin] = useState(false);
	const [showModalReasign, setShowModalReasign] = useState(false);
	const [owner1, setOwner1] = useState({});
	const [fechaFom, setFechaFom] = useState(null);
	const [agent, setAgent] = useState(undefined);
	const [form] = Form.useForm();
	const [showModalCreate, setShowModalCreate] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [taskDetails, setTaskDetails] = useState();
	const [agentsRisk, setAgentsRisk] = useState([]);
	let operationIdModal = undefined;
	const [otherOperations, setOtherOperations] = useState(undefined);
	const [visible, setVisible] = useState(false);
	const [infoProfile, setInfoProfile] = useState([]);
	const [taskOperation, setTaskOperation] = useState([]);
	const [showModalActionDelete, setShowModalActionDelete] = useState(false);
	const [showModalActionAsing, setShowModalActionAsing] = useState(false);
	const [percent1, setPercent1] = useState(
		mortgage && mortgage && mortgage.riskSuccessForecast
			? mortgage && Number(mortgage.riskSuccessForecast)
			: 0
	);
	const [noteArray, setNoteArray] = useState();
	const [refreshNps, setRefreshNps] = useState(false);
	const [formFinish] = Form.useForm();
	const [formDelete] = Form.useForm();
	const [valueChangeAnalyst, setValueChangeAnalyst] = useState(undefined);
	const allTask = useSelector((state) => state.task.setDataTask);
	const [showModalReu, setShowModalReu] = useState(false);
	const [sales, setSales] = useState(undefined);
	const [changeSalesValue, setChangeSalesValue] = useState(undefined);
	const [timeoutReference, setTimeoutReference] = useState(undefined);
	const [imageString, setImageString] = useState('');

	const initDelete2 = {
		date: undefined,
		taskIdDete: undefined,
		operation: undefined,
		type: undefined,
		name: undefined,
		description: undefined,
		assigned: undefined,
		agentId: undefined
	};

	useEffect(() => {
		getSales('@');
	}, []);

	useEffect(() => {
		const profile = authClient.getProfile();
		setProfile(profile);
		getAgent();
		getSalesAgents();
		getInfoProfile();
		getTask();
		getDataNotes();
	}, [ refreshNps, mortgageUpda]);

	useEffect(() => {
		if(mortgageData) {
			setOwner1(mortgageData.owner1);
			setFechaFom(mortgageData.mortgage ? mortgageData.mortgage.closeDateEstimate : undefined);
		}
	}, [mortgageData])

	useEffect(() => {
		setPercent1(mortgage ? Number(mortgage.riskSuccessForecast) : 0);
		if (mortgage.email) {
			getExtraOperations(mortgage.email);
		}
	
	}, [mortgage]);

	const getInfoProfile = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/user/profile/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				response.data.data &&
					response.data.data.profile &&
					setInfoProfile(response.data.data.profile);
			});
	};
	const changeAnalyst = (value) => {
		setValueChangeAnalyst(value);
	};

	const handleChangeAssign = () => {
		axios
			.get(
				`${env.api.url}/v1/hadmin/sales-agents/change-agent/${mortgageId}/${valueChangeAnalyst}`,
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				getAgent();
				getTask();
			});

		setShowModalReasign(!showModalReasign);
	};

	const getExtraOperations = (client) => {
		axios
			.get(`${env.api.url}/v1/blueprints/Operation`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` },
				params: {
					filter: JSON.stringify([
						{
							field: 'clientEmail',
							operator: '=',
							value: client
						},
						{
							field: 'id',
							operator: '!=',
							value: mortgageId
						}
					])
				}
			})
			.then((response) => {
				const order = response.data.data.items;
				if (order.length > 0) {
					setOtherOperations(_.orderBy(order, ['name'], ['asc']));
				}
			});
	};


	const getTask = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/tasks/operation/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				const orderTask = response.data.task.sort(function (a, b) {
					return b.priority - a.priority;
				});
				setTaskOperation(orderTask);
			});
	};

	const getDataNotes = () => {
		axios
			.get(`${env.api.url}/v1/operations/notes/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				if (response.data.data.notes !== null && response.data.data.notes !== undefined) {
					setNoteArray(response.data.data.notes);
				}
			});
	};

	const getSalesAgents = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/sales-agents`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setAgentsRisk(response.data.data);
			});
	};

	const getSales = (value) => {
		if (value !== '') {
			if (timeoutReference) {
				clearTimeout(timeoutReference);
			}

			const requestFilters = [];
			const limit = 10;
			const offset = 0;
			requestFilters.push({
				field: 'email',
				operator: 'like',
				value: `%${value}%`
			});

			const requestParams = {
				limit,
				offset,
				filter: JSON.stringify(requestFilters)
			};

			setTimeoutReference(
				setTimeout(() => {
					axios
						.get(`${env.api.url}/v1/blueprints/Account`, {
							params: requestParams,
							headers: { Authorization: `Bearer ${authClient.getToken()}` }
						})
						.then((response) => {
							const filter = _.filter(response.data.data.items, function (s) {
								return s.type === 'realestate' || s.type === 'web';
							});
							setSales(filter);
						});
					clearTimeout(timeoutReference);
				}, 500)
			);
		}
	};

	const getAgent = () => {
		axios
			.get(`${env.api.url}/v1/mortgages/agents/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setAgent(response.data.data);
			});
	};
	const onFinish = async (values) => {};

	let agentTypeName;
	if (isTypeFriend && isTypeFriend.operationType === 'web') {
		agentTypeName = 'Cliente web';
	} else if (isTypeFriend && isTypeFriend.operationType === 'friend') {
		agentTypeName = 'Cliente web';
	} else if (isTypeFriend && isTypeFriend.operationType === 'realestate') {
		agentTypeName = 'Inmobiliaria';
	} else if (isTypeFriend && isTypeFriend.operationType === 'property_developer') {
		agentTypeName = 'Promotora';
	}

	const chooseType = (type) => {
		if (type === 'call') {
			return 'Llamar';
		} else if (type === 'doc') {
			return 'Pedir documentación';
		} else if (type === 'other') {
			return 'Otro';
		} else if (type === 'comdoc') {
			return 'Compromiso de documentación';
		} else if (type === 'crtanalysis') {
			return 'Certificado a analisis';
		} else if (type === 'tag') {
			return 'Etiquetar';
		} else if (type === 'claim') {
			return 'Reclamar documentación';
		} else if (type === 'claimoffer') {
			return 'Reclamar Oferta';
		} else if (type === 'firstcall') {
			return 'Primera llamada';
		} else if (type === 'followup') {
			return 'Llamada de seguimiento';
		} else if (type === 'customerqualification') {
			return 'Cualificación de cliente';
		} else if (type === 'sendbanks') {
			return 'Enviar a bancos';
		} else if (type === 'choosenoffer') {
			return 'Selección de oferta';
		} else if (type === 'analysis') {
			return 'Nueva operación en analisis';
		} else if (type === 'gibobsappraisal') {
			return 'Tasacion con Gibobs';
		} else {
			return 'Reclamar tasación';
		}
	};

	const handleButtonDelete = (type, taskId) => {
		setShowModalActionDelete(!showModalActionDelete);
	};

	if (showModal) {
		return <SnoozeModalNew mortgageId={mortgageId} />;
	}

	const stages = [
		'basic',
		'full',
		'documents',
		'analysis',
		'banks',
		'oferts',
		'appraisal',
		'formalization'
	];

	const stagesIndex = stages.indexOf(mortgage.stage);

	const stagesList = [
		{
			title: 'Básicos',
			milestone: 'Completar preguntas LITE'
		},
		{
			title: 'Perfil',
			milestone: 'Completar perfil completo'
		},
		{
			title: 'Documentación',
			milestone: 'Tagear todos los documentos obligatorios'
		},
		{
			title: 'Análisis',
			milestone: 'Enviar la operación al menos a un banco'
		},
		{
			title: 'Bancos',
			milestone: 'Disponer de al menos una oferta disponible'
		},
		{
			title: 'Selección de oferta',
			milestone: 'Oferta elegida por el cliente'
		},
		{
			title: 'Tasación',
			milestone: 'Tasación OK y aprobada por el banco'
		},
		{
			title: 'Formalización',
			milestone: 'Hipoteca firmada'
		},
		{
			title: 'Finalización'
		}
	];

	const stagesButtons = [];
	stagesList.forEach((stage, index) => {
		let buttonType;
		let icon = false;

		if (stagesIndex > index) {
			buttonType = 'primary';
		} else if (stagesIndex === index) {
			buttonType = 'dashed';
			icon = <LoadingOutlined />;
		}

		stagesButtons.push(
			<Popover
				key={`stage-buttons-${index}`}
				content={<p>{stage.milestone}</p>}
				title="Hito a cumplir"
				trigger="hover">
				<Button disabled={statusJob === 'finish' ? true : false} type={buttonType} icon={icon}>
					{stage.title}
				</Button>
			</Popover>
		);
	});

	const handleShowModal = () => {
		setShowModalCreate(!showModalCreate);
	};

	const handleclickFin = (type, taskId) => {
		getTaskDetails(taskId).then(() => {
			setShowModalActionFin(!showModalActionFin);
		});
	};

	const handleOkDelete = (type, taskId) => {
		getTaskDetails(taskId).then(() => {
			setShowModalActionDelete(!showModalActionDelete);
		});
	};
	const handleOkAgendar = (type, taskId) => {
		getTaskDetails(taskId).then(() => {
			setShowModalActionAsing(!showModalActionAsing);
		});
	};

	const getTaskDetails = async (taskId) => {
		const response = await axios.get(`${env.api.url}/v1/hadmin/tasks/${taskId}`, {
			headers: { Authorization: `Bearer ${authClient.getToken()}` }
		});

		setTaskDetails(response.data.task);
		initDelete2.taskIdDete = response.data.task.id;
	};

	const actions = (type, taskId, note, date = undefined, hour) => {
		axios
			.post(
				`${env.api.url}/v1/hadmin/tasks/action/${type}/${taskId}`,
				{
					note: note,
					dueDate:
						date !== undefined
							? moment(
									`${date.format('YYYY-MM-DD')} ${hour.format('HH:mm')}`,
									'YYYY-MM-DD HH:mm'
							  ).toDate()
							: undefined
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.errorCode === 'FORBIDDEN') {
					message.error('Comprueba que tu eres el que ha creado la tarea a eliminar');
				} else {
					if (type === 'complete') {
						message.success('Su tarea se ha finalizado exitosamente');
					} else if (type === 'delete') {
						message.success('Su tarea se ha eliminado exitosamente');
					} else if (type === 'postpone') {
						message.success('Su tarea se ha pospuesto exitosamente');
					}
					getTask();
					getDataNotes();
				}
			})
			.catch((error) => {
				console.log(error);
				message.error('Compruebe que la fecha es mayor que hoy');
			});
	};
	const initDelete = {
		note: undefined,
		taskIdDete: undefined,
		hour: moment('21:00', 'HH:mm')
	};

	const handleCancelDelete = (type, taskId) => {
		formFinish.setFieldsValue(initDelete);
		setShowModalActionDelete(!showModalActionDelete);
	};

	const showModalEditFuction = async (taskId) => {
		getTaskDetails(taskId).then(() => {
			setShowModalEdit(!showModalEdit);
		});
	};

	let whatsappPhone = owner1?.phone;
	if (whatsappPhone && whatsappPhone.indexOf('+') === -1) {
		whatsappPhone = `+34${whatsappPhone}`;
		whatsappPhone = whatsappPhone.replace(/ /g, '');
	}
	let whatsappPhoneInmo = mortgage.accountPhone;
	if (whatsappPhoneInmo && whatsappPhoneInmo.indexOf('+34') === -1) {
		whatsappPhoneInmo = `+34${whatsappPhoneInmo}`;
		whatsappPhoneInmo = whatsappPhoneInmo.replace(/ /g, '');
	}
	if (whatsappPhone) {
		whatsappPhone = whatsappPhone.replace('+', '%2B');
	}
	if (whatsappPhoneInmo) {
		whatsappPhoneInmo = whatsappPhoneInmo.replace('+', '%2B');
	}

	const handleChangeReu = () => {
		axios
			.post(
				`${env.api.url}/v1/blueprints/Operation/update`,
				{
					agentEmail: changeSalesValue.email,
					accountId: changeSalesValue.id,
					id: mortgageId
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setShowModalReu(false);
				window.location.reload();
			});
	};

	const changeSales = (value) => {
		const agent = _.find(sales, function (s) {
			return s.id === value;
		});
		setChangeSalesValue(agent);
	};

	const renderDescription = (text) => {
		text = text.replace(/\n/g, '<br />');

		return text;
	};
	

	const newContent = (
		<Content style={{ paddingLeft: 10 }}>
			<StatusWidgets
				mortgage={mortgage}
				owner1={owner1 && owner1}
				dataMortgage={mortgageData && mortgageData}
				setRefreshNps={setRefreshNps}
				refreshNps={refreshNps}
				mortgageId={mortgageId}
				whatsappPhone={whatsappPhone}
				infoProfile={infoProfile}
				otherOperations={otherOperations}
				setVisible={setVisible}
				visible={visible}
				agent={agent}
				getDataNotes={getDataNotes}
				profile={profile}
				percent1={percent1}
				setPercent1={setPercent1}
				mortgageUpda={mortgageUpda}
				fechaFom={fechaFom}
				clientPreRegister={isTypeFriend}
				agentTypeName={agentTypeName}
				setShowModalReasign={setShowModalReasign}
				whatsappPhoneInmo={whatsappPhoneInmo}
				setShowModalReu={setShowModalReu}></StatusWidgets>
			<Row gutter={24}>
				<FilterTask
					notesArray={noteArray}
					setShowMortgageNoteModal={setShowMortgageNoteModal}
					setShowMortgageNoteFromImageModal={setShowMortgageNoteFromImageModal}
				/>

				<Col lg={8} xs={24} style={{ marginTop: '27px' }}>
					<Row gutter={24}>
						<Col lg={12} xs={12}>
							<div>
								<Text
									style={{
										fontSize: '14px',
										fontWeight: '600',
										color: '#2F4858'
									}}>
									Tareas pendientes
								</Text>
							</div>
						</Col>
						<Col lg={12} xs={12} style={{ textAlign: 'right' }}>
							<Button
								onClick={() => handleShowModal(mortgage.id)}
								type="dashed"
								disabled={statusJob === 'finish' ? true : false}
								className="button-secundary-gibobs">
								Añadir tarea
							</Button>
						</Col>
					</Row>
					{taskOperation &&
						taskOperation.map((data) => (
							<Card
								id={data.id}
								style={{
									border: '1px solid #C0DAEE',
									borderRadius: '4px',
									backgroundColor: '#EFFCFC',
									marginTop: '19px'
								}}>
								<div>
									<Row gutter={24}>
										<Col lg={16} xs={20}>
											{data.priority === 30 && (
												<DoubleLeftOutlined
													rotate={90}
													style={{ color: '#FF4D4F', marginRight: 10, fontSize: 16 }}
												/>
											)}
											{data.priority === 20 && (
												<PauseOutlined
													rotate={90}
													style={{ color: '#FAAD14', marginRight: 10, fontSize: 16 }}
												/>
											)}
											{data.priority === 10 && (
												<DoubleRightOutlined
													rotate={90}
													style={{ color: '#52C41A', marginRight: 10, fontSize: 16 }}
												/>
											)}
											<Text
												style={{
													fontSize: '16px',
													fontWeight: '600',
													color: '#02C3CD',
													cursor: 'pointer'
												}}
												onClick={() => showModalEditFuction(data.id)}>
												T{data.frontId}
											</Text>
										</Col>
										<Col lg={8} xs={4} style={{ textAlign: 'right' }}>
											<CheckOutlined
												style={{
													color: '#02C3CD',
													fontSize: '14px',
													marginRight: '9px'
												}}
												onClick={() => handleclickFin('complete', data.id)}
											/>
											<CalendarOutlined
												style={{
													color: '#02C3CD',
													fontSize: '14px',
													marginRight: '9px'
												}}
												onClick={() => handleOkAgendar('postpone', data.id)}
											/>
											<DeleteOutlined
												style={{ color: '#02C3CD', fontSize: '14px' }}
												onClick={() => handleOkDelete('delete', data.id)}
											/>
										</Col>
									</Row>
									<div>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#2F4858'
											}}>
											{chooseType(data.type)} -
										</Text>
										<Text
											style={{
												fontSize: '12px',
												fontWeight: '500',
												color: '#02C3CD',
												marginLeft: '4px'
											}}>
											{data.name}
										</Text>
									</div>
									<div style={{ marginTop: '16px' }}>
										<div
											style={{
												fontSize: '12px',
												fontWeight: 'normal',
												color: '#748EA0'
											}}
											dangerouslySetInnerHTML={{
												__html: renderDescription(data.description)
											}}></div>
									</div>
									<Row gutter={24} style={{ marginTop: '16px' }}>
										<Col lg={24} xs={24}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858'
												}}>
												Responsable:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD',
													marginLeft: '4px'
												}}>
												{data.assigneeName}
											</Text>
										</Col>
										<Col lg={24} xs={24}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858'
												}}>
												Creada por:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD',
													marginLeft: '4px'
												}}>
												{data.reporterName}
											</Text>
										</Col>
										<Col lg={24} xs={24}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#2F4858'
												}}>
												Agendad para el:
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '500',
													color: '#02C3CD',
													marginLeft: '4px'
												}}>
												{moment(data.dueDate).format('L')} - {moment(data.dueDate).format('LT')}
											</Text>
										</Col>
									</Row>
								</div>
							</Card>
						))}
				</Col>
			</Row>

			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{ className: 'button-secundary-gibobs' }}
				okButtonProps={{ className: 'button-primari-gibobs' }}
				title="Eliminar tarea"
				open={showModalActionDelete}
				onOk={() => {
					formDelete
						.validateFields()
						.then((values) => {
							if (values.taskIdDete === undefined) {
								values.taskIdDete = taskDetails.id;
							}
							handleButtonDelete(values);
							actions('delete', values.taskIdDete, values.note);
							formDelete.setFieldsValue(initDelete);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
				okText={'Eliminar'}
				cancelText={'Cancelar'}
				onCancel={handleCancelDelete}
				closable={false}>
				<Form
					form={formDelete}
					layout="horizonatl"
					name="basic"
					onFinish={onFinish}
					initialValues={{
						taskIdDete: taskDetails && taskDetails.id
					}}>
					<Row gutter={24}>
						<Col lg={24}>
							<Form.Item
								label="Nota:"
								name="note"
								rules={[{ required: true, message: 'Introduzca una nota' }]}
							>
								<TextArea style={{ border: '1px solid #C0DAEE' }} />
							</Form.Item>
						</Col>
						<Form.Item
							hidden={true}
							name="taskIdDete"
							style={{ float: 'left', marginRight: 200, width: 150 }}>
							<Input hidden={true}></Input>
						</Form.Item>
					</Row>
				</Form>
			</Modal>

			<Row style={{ marginTop: '10px' }} gutter={10}>
				<Col span={24}>
					<MortgageNoteCreate
						mortgageId={mortgage.id}
						visible={showMortgageNoteModal}
						getDataNotes={() => getDataNotes()}
						onSuccess={() => {
							setShowMortgageNoteModal(false);
							dispatch(updateStateMortgages(!mortgageUpda));
						}}
						onCancel={() => {
							dispatch(updateStateMortgages(!mortgageUpda));
							setShowMortgageNoteModal(false);
						}}
						imageString={imageString}

					/>
					<MortgageNoteFromImage
						mortgageId={mortgage.id}
						visible={showMortgageNoteFromImageModal}
						setShowMortgageNoteFromImageModal={setShowMortgageNoteFromImageModal}
						getDataNotes={() => getDataNotes()}
						onSuccess={() => {
							setShowMortgageNoteFromImageModal(false);
							dispatch(updateStateMortgages(!mortgageUpda));
						}}
						onCancel={() => {
							dispatch(updateStateMortgages(!mortgageUpda));
							setShowMortgageNoteFromImageModal(false);
						}}
						setImageString={setImageString}
						setShowMortgageNoteModal={setShowMortgageNoteModal}
					/>
				</Col>
			</Row>
			{/* Modal para crear tareas */}
			<ModalCreateTasks
				// key={showModalCreate}
				visible={showModalCreate}
				width={800}
				allTask={allTask}
				operationIdModal={operationIdModal}
				profile={profile}
				agentsRisk={agentsRisk}
				getTask={() => {
					getTask();
					getDataNotes();
				}}
				setShowModalEdit={(e) => setShowModalCreate(e)}
				initialValuesStatus={{
					operation: mortgage !== undefined ? mortgage.name : '',
					operationId: mortgage !== undefined ? mortgage.id : '',
					assigned: mortgage !== undefined ? mortgage.risk : '',
					hour: moment('21:00', 'HH:mm'),
					priority: 20,
					radiogroup: 1
				}}
			/>
			{/* Modal para editar tareas */}
			<ModalCreateTasks
				// key={showModalEdit}
				visible={showModalEdit}
				width={800}
				allTask={allTask}
				operationIdModal={operationIdModal}
				profile={profile}
				agentsRisk={agentsRisk}
				getTask={() => {
					getTask();
					getDataNotes();
				}}
				setShowModalEdit={(e) => setShowModalEdit(e)}
				initialValuesStatus={{
					...taskDetails,
					date: dayjs(taskDetails && taskDetails.dueDate),
					radiogroup: 2,
					operation: taskDetails && taskDetails.operationName,
					assigned: taskDetails && taskDetails.assigneeName
				}}
				edit={true}
			/>
			{/* Modal para reagendar tareas */}
			<ModalReScheduleTask
				key={showModalActionAsing}
				visible={showModalActionAsing}
				setShowModalActionAsing={(e) => setShowModalActionAsing(e)}
				initialValuesStatus={{
					...taskDetails,
					taskIdDete: taskDetails && taskDetails.id,
					hour: dayjs('21:00', 'HH:mm'),
					radiogroup: taskDetails && taskDetails.notified && taskDetails.notified === true ? 2 : 1
				}}
				getTask={() => {
					getTask();
					getDataNotes();
				}}
			/>
			{/* Modal para finalizar tareas */}
			<ModalFinishTask
				key={showModalActionFin}
				visible={showModalActionFin}
				setShowModalActionFin={(e) => setShowModalActionFin(e)}
				initialValuesStatus={{ taskIdDete: taskDetails && taskDetails.id }}
				getTask={() => {
					getTask();
					getDataNotes();
				}}
			/>
			<Modal
				className="gb-modal-style-common ModalReassign"
				title="Reasignar operación"
				open={showModalReasign}
				onCancel={() => {
					setShowModalReasign(false);
				}}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				destroyOnClose={true}
				width={400}
				onOk={() => handleChangeAssign()}
				okText={'Confirmar'}
				cancelText={'Cancelar'}>
				<div style={{ marginTop: -24 }}>
					<Text
						style={{
							fontSize: 12,
							fontWeight: 400,
							color: '2F4858'
						}}>
						Elige en el desplegable a qué persona quieres reasignar la operación
					</Text>
				</div>
				<div style={{ display: 'flex', marginTop: 16, width: '100%' }}>
					<Form form={form} layout="vertical" name="basic" style={{ width: '100%' }}>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									label={
										<Text
											style={{
												fontSize: 10,
												fontWeight: 400,
												color: '2F4858'
											}}>
											Analista
										</Text>
									}
									name="agent"
									style={{ width: '100%', fontSize: 10, fontWeight: 400, color: '2F4858' }}>
									{' '}
									<Select
										showSearch
										style={{ width: '100%' }}
										filterOption={(input, option) =>
											option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										defaultValue={mortgageData && mortgageData.risk}
										onChange={(value) => {
											changeAnalyst(value);
										}}>
										{agentsRisk.map((m, id) => (
											<Option key={id} value={m.id}>
												{m.name}{' '}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>

			<Modal
				className="gb-modal-style-common ModalReassign"
				title="Reubicar operación"
				open={showModalReu}
				onCancel={() => {
					setShowModalReu(false);
				}}
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { height: 32, width: 96 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
				destroyOnClose={true}
				width={400}
				onOk={() => handleChangeReu()}
				okText={'Confirmar'}
				cancelText={'Cancelar'}>
				<div style={{ marginTop: -10 }}>
					<Text
						style={{
							fontSize: 12,
							fontWeight: 400,
							color: '2F4858'
						}}>
						Elige en el desplegable a qué email quieres reubicar la operación
					</Text>
				</div>
				<div style={{ display: 'flex', marginTop: 16, width: '100%' }}>
					<Form form={form} layout="vertical" name="basic" style={{ width: '100%' }}>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									label={
										<Text
											style={{
												fontSize: 10,
												fontWeight: 400,
												color: '2F4858'
											}}>
											Agente Inmobiliario
										</Text>
									}
									name="agent"
									style={{ width: '100%', fontSize: 10, fontWeight: 400, color: '2F4858' }}>
									{' '}
									<Select
										showSearch
										style={{ width: '100%' }}
										filterOption={(input, option) =>
											option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										onChange={(value) => changeSales(value)}
										defaultValue={mortgage && mortgage.accountEmail}
										onSearch={(value) => {
											getSales(value);
										}}>
										{sales &&
											sales.map((m, id) => (
												<Option key={id} value={m.id}>
													{m.email}{' '}
												</Option>
											))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>
		</Content>
	);

	return newContent;
}

export default MortgageDetailStateComponent;
